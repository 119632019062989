import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  EnumStatusGDProposta,
  EnumStatusGDPropostaNumericMap,
} from "../../../../../enums/EnumStatusGDProposta.enum";
import { useAuth } from "../../../../../hooks/useAuth";
import {
  IFormInformacoesContato,
  IFormInformacoesEndereco,
  IFormInformacoesInformacoesContratoPF,
  IFormInformacoesInformacoesContratoPJ,
  IFormInformacoesOutrasInformacoes,
  IFormPreferencia,
} from "../../../../../models/Contratar";
import { IFullGDProposta } from "../../../../../models/GDProposta";
import * as services from "../../../../../services/api/PropostaService";
import * as D from "../../../../../styles/appComponents";
import * as C from "../style";
import InformacoesContratoPJContratar from "./components/cnpj/InformacoesContratoPJ";
import InformacoesProposta, {
  IFormInformacoesProposta,
} from "./components/cnpj/informacoesProposta";
import InformacoesResponsavelLegal, {
  IInformacoesResponsavelLegal,
} from "./components/cnpj/informacoesResponsavelLegal";
import EnderecoContratar from "./components/cpf/Endereco";
import InformacoesContratoPFContratar from "./components/cpf/InformacoesContratoPF";
import DadosContato from "./components/DadosContato";
import InformacoesTitularContratar, {
  IInformacoesTitularContratar,
} from "./components/InformacoesTitular";
import OutrasInformacoesContratar from "./components/OutrasInformacoes";
import PreferenciaContratar from "./components/Preferencia";
import * as service from "../../../../../services/api/GDContratoClienteService";
import { AxiosError } from "axios";
import {
  ICreateGDContratoClientePF,
  ICreateGDContratoClientePJ,
} from "../../../../../models/GDContrato";

const Contratar = () => {
  const { user } = useAuth();
  const navigate: NavigateFunction = useNavigate();
  const { idProposta, tipo } = useParams();

  const [data, setData] = useState<IFullGDProposta | undefined>(undefined);

  //#region - Informações do titular

  const [informacoesTitularContratar, setInformacoesTitularContratar] =
    useState<IInformacoesTitularContratar | undefined>(undefined);

  const onChangeInformacoesTitularContratar = (
    data: IInformacoesTitularContratar
  ): void => {
    setInformacoesTitularContratar(data);
  };

  //#endregion

  //#region - PF

  //#region - Endereço PF

  const [enderecoContratar, setEnderecoContratar] = useState<
    IFormInformacoesEndereco | undefined
  >(undefined);

  const onChangeEnderecoContratar = (data: IFormInformacoesEndereco): void => {
    setEnderecoContratar(data);
  };

  //#endregion

  //#region - Informacoes do contrato PF

  const [informacoesContratoContratarPF, setInformacoesContratoContratarPF] =
    useState<IFormInformacoesInformacoesContratoPF | undefined>(undefined);

  const onChangeInformacoesContratoContratarPF = (
    data: IFormInformacoesInformacoesContratoPF
  ): void => {
    setInformacoesContratoContratarPF(data);
  };

  //#endregion

  //#endregion - PF

  //#region - PJ

  //#region - Informações contrato PJ
  const [informacoesContratoContratarPJ, setInformacoesContratoContratarPJ] =
    useState<IFormInformacoesInformacoesContratoPJ | undefined>(undefined);

  const onChangeInformacoesContratoContratarPJ = (
    data: IFormInformacoesInformacoesContratoPJ
  ): void => {
    setInformacoesContratoContratarPJ(data);
  };
  //#endregion

  //#region - Informações responsável legal PJ
  const [informacoesResponsavelLegal, setInformacoesResponsavelLegal] =
    useState<IInformacoesResponsavelLegal | undefined>(undefined);

  const onChangeInformacoesResponsavelLegal = (
    data: IInformacoesResponsavelLegal
  ): void => {
    setInformacoesResponsavelLegal(data);
  };
  //#endregion

  //#region - Informações proposta PJ
  const [informacoesProposta, setInformacoesProposta] = useState<
    IFormInformacoesProposta | undefined
  >(undefined);

  const onChangeInformacoesProposta = (
    data: IFormInformacoesProposta
  ): void => {
    setInformacoesProposta(data);
  };
  //#endregion

  //#endregion

  //#region - Outras informações
  const [outrasInformacoesContratar, setOutrasInformacoesContratar] = useState<
    IFormInformacoesOutrasInformacoes | undefined
  >(undefined);

  const onChangeOutrasInformacoesContratar = (
    data: IFormInformacoesOutrasInformacoes
  ): void => {
    setOutrasInformacoesContratar(data);
  };

  //#endregion

  //#region - Preferências
  const [formPreferencia, setFormPreferencia] = useState<
    IFormPreferencia | undefined
  >(undefined);

  const onChangeDataIFormPreferencia = (data: IFormPreferencia): void => {
    setFormPreferencia(data);
  };

  //#endregion

  //#region - DadosContato
  const [formDadosContato, setFormDadosContato] = useState<
    IFormInformacoesContato | undefined
  >(undefined);

  const onChangeDataIFormDadosContato = (
    data: IFormInformacoesContato
  ): void => {
    setFormDadosContato(data);
  };

  //#endregion

  const getFullProposta = async (id: string): Promise<void> => {
    try {
      const [response] = await Promise.all([services.GetFullPropostaById(id)]);

      if (
        response.data.status !==
        EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.aprovada]
      ) {
        navigate(`/Parceiro/Propostas`);
      }

      setData(response.data);
    } catch (e: unknown) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Ocorreu um erro após tentarmos achar a proposta",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  const handleValidForm = (form?: unknown): boolean => {
    if (!form) return true;

    const formValues: unknown[] = Object.values(form);
    const hasValue: boolean = formValues.some(
      (value) => value !== null && value !== undefined && value !== ""
    );
    const allFilled: boolean = formValues.every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    return hasValue ? allFilled : true;
  };

  const setCooperativa = async (): Promise<void> => {
    const validFormChecks: boolean[] = [
      handleValidForm(informacoesTitularContratar),
      handleValidForm(enderecoContratar),
      // handleValidForm(informacoesContratoContratar),
      handleValidForm(outrasInformacoesContratar),
      handleValidForm(informacoesResponsavelLegal),
      handleValidForm(informacoesProposta),
    ];

    // if (!validFormChecks.every(check => check)) {
    //   Swal.fire({
    //     position: "top-end",
    //     toast: true,
    //     icon: "warning",
    //     title: "Existem campos obrigatórios que não foram preenchidos",
    //     showConfirmButton: false,
    //     showCloseButton: true,
    //     timer: 3000,
    //   });
    //   return;
    // }

    // const contramo = convertToIContrato(
    //   informacoesTitularContratar,
    //   enderecoContratar,
    //   informacoesContratoContratar,
    //   outrasInformacoesContratar,
    //   informacoesResponsavelLegal,
    //   informacoesProposta,
    // );
    //
    // const response: any = await services.Create(contramo);
    //
    // if (!response) {
    //   Swal.fire({
    //     position: "top-end",
    //     toast: true,
    //     icon: "error",
    //     title: "Erro ao cadastrar cooperativa",
    //     showConfirmButton: false,
    //     showCloseButton: true,
    //     timer: 3000,
    //   });
    //   return;
    // }

    Swal.fire({
      position: "top-end",
      toast: true,
      icon: "success",
      title: "Cooperativa cadastrada com sucesso!",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 3000,
    });
    navigate(-1);
  };

  useEffect((): void => {
    if (!["Fisica", "Juridica"].includes(tipo as string)) {
      navigate(-1);
    }

    getFullProposta(idProposta as string);
  }, [tipo]);

  const GeraContrato = async () => {
    if (tipo === "Fisica") {
      var dataPF: ICreateGDContratoClientePF = {
        nome: informacoesTitularContratar?.dataPF.nome ?? null,
        cpf: informacoesTitularContratar?.dataPF.cpf ?? null,
        enumEstadoCivil:
          Number(informacoesTitularContratar?.dataPF.estado_civil) ?? null,
        nacionalidade:
          informacoesTitularContratar?.dataPF.nacionalidade ?? null,
        profissao: informacoesTitularContratar?.dataPF.profissao ?? null,
        cep: enderecoContratar?.cep ?? null,
        uf: enderecoContratar?.uf ?? null,
        cidade: enderecoContratar?.cidade ?? null,
        bairro: enderecoContratar?.bairro ?? null,
        logradouro: enderecoContratar?.logradouro ?? null,
        numero: enderecoContratar?.numero ?? null,
        complemento: enderecoContratar?.complemento ?? null,
        instalacao: informacoesContratoContratarPF?.numeroInstalacao ?? null,
        numeroCliente: informacoesContratoContratarPF?.numeroCliente ?? null,
        dataVencimento:
          new Date(informacoesContratoContratarPF?.dataVencimento!) ?? null,
        conexao: Number(informacoesContratoContratarPF?.conexao) ?? null,
        tarifaDistribuidora:
          Number(informacoesContratoContratarPF?.tarifaDistribuidora) ?? null,
        descontoTarifaDistribuidora:
          Number(informacoesContratoContratarPF?.descontoTarifaDistribuidora) ??
          null,
        taxaDisponibilidade:
          Number(informacoesContratoContratarPF?.taxaDisponibilidade) ?? null,
        mediaDozeMeses:
          Number(informacoesContratoContratarPF?.media12meses) ?? null,
        franquia: Number(informacoesContratoContratarPF?.franquia) ?? null,
        valorAssinatura:
          Number(informacoesContratoContratarPF?.valorAssinatura) ?? null,
        tarifaUsina:
          Number(informacoesContratoContratarPF?.tarifaUsina) ?? null,
        potencia: Number(informacoesContratoContratarPF?.potencia) ?? null,
        valorMaximoInjecao:
          Number(informacoesContratoContratarPF?.valorMaximoInjecao) ?? null,
        lote: Number(informacoesContratoContratarPF?.tarifaUsina) ?? null,
        economiaMensal:
          Number(informacoesContratoContratarPF?.economiaMensal) ?? null,
        economiaAnual:
          Number(informacoesContratoContratarPF?.economiaAnual) ?? null,
        usinaId: outrasInformacoesContratar?.usina ?? null,
        operadoraId: outrasInformacoesContratar?.operadora ?? null,
        signatario: outrasInformacoesContratar?.signatario ?? null,
        comissaoCaptador:
          Number(outrasInformacoesContratar?.comissaoCaptador) ?? null,
        gatewayPagamento:
          Number(outrasInformacoesContratar?.gatewayPagamento) ?? null,
        limiteInjecao:
          Number(outrasInformacoesContratar?.limiteInjecao) ?? null,
        preferenciaRecebimento:
          Number(outrasInformacoesContratar?.prefRecebido) ?? null,
        apoioParceiro: outrasInformacoesContratar?.apoioParceiro ?? null,
        dataAssinatura:
          new Date(outrasInformacoesContratar?.dataAssinatura!) ?? null,
        whatsapp: formPreferencia?.whatsapp ?? null,
        recebeTresDias: formPreferencia?.tresdias ?? null,
        recebeSeteDias: formPreferencia?.setedias ?? null,
        recebeGeracao: formPreferencia?.geracao ?? null,
        recebeVencimento: formPreferencia?.vencimento ?? null,
        emails: formPreferencia?.emails ?? null,
        preferenciaDiaRecebimento:
          formPreferencia?.preferenciadediasemrecebimentofatura ?? null,
        possuiGeracaoEnergiaPropria:
          formPreferencia?.possuigeracaoenergiapropria ?? null,
        obdeceFranquia: formPreferencia?.obedecefranquia ?? null,
        preferenciaTitularidade:
          formPreferencia?.preferenciatitularidadefatura ?? null,
        status: Number(outrasInformacoesContratar?.status),
        propostaID: idProposta!,
        eataGeracaoContrato: null,
        energiaAdicionalkWh: null,
        energiaAdicionalReais: null,
      };

      var convertedDataPF = await convertEmptyStringsToNullAsync(dataPF);

      await service
        .CreateGDContratoPessoaFisica(convertedDataPF)
        .then(async () => {
          navigate("/Parceiro/Contratos");
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Criado com sucesso!",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        })
        .catch((e: AxiosError) => {
          var errorMessage: string = e.response
            ? String(e.response?.data)
            : "Houve um erro ao gerar o contrato.";

          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "warning",
            title: errorMessage,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        });
    } else {
      var dataPJ: ICreateGDContratoClientePJ = {
        razaoSocial: informacoesTitularContratar?.dataPJ.razaoSocial ?? null,
        cnpj: informacoesTitularContratar?.dataPJ.cnpj ?? null,
        instalacao:
          informacoesTitularContratar?.dataPJ.numeroInstalacao ?? null,
        cep: informacoesTitularContratar?.dataPJ.cep ?? null,
        uf: informacoesTitularContratar?.dataPJ.uf ?? null,
        cidade: informacoesTitularContratar?.dataPJ.cidade ?? null,
        bairro: informacoesTitularContratar?.dataPJ.bairro ?? null,
        logradouro: informacoesTitularContratar?.dataPJ.logradouro ?? null,
        numero: informacoesTitularContratar?.dataPJ.numero ?? null,
        complemento: informacoesTitularContratar?.dataPJ.complemento ?? null,
        numeroCliente: informacoesContratoContratarPJ?.numeroCliente ?? null,
        dataVencimento:
          new Date(informacoesContratoContratarPJ?.dataVencimento!) ?? null,
        conexao: Number(informacoesContratoContratarPJ?.conexao) ?? null,
        tarifaDistribuidora:
          Number(informacoesContratoContratarPJ?.tarifaDistribuidora) ?? null,
        descontoTarifaDistribuidora:
          Number(informacoesContratoContratarPJ?.descontoTarifaDistribuidora) ??
          null,
        taxaDisponibilidade:
          Number(informacoesContratoContratarPJ?.taxaDisponibilidade) ?? null,
        mediaDozeMeses:
          Number(informacoesContratoContratarPJ?.media12meses) ?? null,
        franquia: Number(informacoesContratoContratarPJ?.franquia) ?? null,
        valorAssinatura:
          Number(informacoesContratoContratarPJ?.valorAssinatura) ?? null,
        tarifaUsina:
          Number(informacoesContratoContratarPJ?.tarifaUsina) ?? null,
        potencia: Number(informacoesContratoContratarPJ?.potencia) ?? null,
        valorMaximoInjecao:
          Number(informacoesContratoContratarPJ?.valorMaximoInjecao) ?? null,
        lote: Number(informacoesContratoContratarPJ?.lote) ?? null,
        economiaMensal:
          Number(informacoesContratoContratarPJ?.economiaMensal) ?? null,
        economiaAnual:
          Number(informacoesContratoContratarPJ?.economiaAnual) ?? null,
        responsaveisLegais: [
          {
            nome: informacoesResponsavelLegal?.nome ?? null,
            email: informacoesResponsavelLegal?.email ?? null,
            celular: informacoesResponsavelLegal?.celular ?? null,
            cpf: informacoesResponsavelLegal?.cpf ?? null,
            enumEstadoCivil:
              Number(informacoesResponsavelLegal?.estado_civil) ?? null,
            nacionalidade: informacoesResponsavelLegal?.nacionalidade ?? null,
            profissao: informacoesResponsavelLegal?.profissao ?? null,
            cep: null,
            uf: null,
            bairro: null,
            cidade: null,
            complemento: null,
            logradouro: null,
            numero: null,
          },
        ],
        usinaId: outrasInformacoesContratar?.usina ?? null,
        operadoraId: outrasInformacoesContratar?.operadora ?? null,
        signatario: outrasInformacoesContratar?.signatario ?? null,
        comissaoCaptador:
          Number(outrasInformacoesContratar?.comissaoCaptador) ?? null,
        gatewayPagamento:
          Number(outrasInformacoesContratar?.gatewayPagamento) ?? null,
        limiteInjecao:
          Number(outrasInformacoesContratar?.limiteInjecao) ?? null,
        preferenciaRecebimento:
          Number(outrasInformacoesContratar?.prefRecebido) ?? null,
        apoioParceiro: outrasInformacoesContratar?.apoioParceiro ?? null,
        dataAssinatura:
          new Date(outrasInformacoesContratar?.dataAssinatura!) ?? null,
        whatsapp: formPreferencia?.whatsapp ?? null,
        recebeTresDias: formPreferencia?.tresdias ?? null,
        recebeSeteDias: formPreferencia?.setedias ?? null,
        recebeGeracao: formPreferencia?.geracao ?? null,
        recebeVencimento: formPreferencia?.vencimento ?? null,
        emails: formPreferencia?.emails ?? null,
        preferenciaDiaRecebimento:
          formPreferencia?.preferenciadediasemrecebimentofatura ?? null,
        possuiGeracaoEnergiaPropria:
          formPreferencia?.possuigeracaoenergiapropria ?? null,
        obdeceFranquia: formPreferencia?.obedecefranquia ?? null,
        preferenciaTitularidade:
          formPreferencia?.preferenciatitularidadefatura ?? null,
        status: Number(outrasInformacoesContratar?.status),
        propostaID: idProposta!,
        eataGeracaoContrato: null,
        energiaAdicionalkWh: null,
        energiaAdicionalReais: null,
      };

      var convertedDataPJ = await convertEmptyStringsToNullAsync(dataPJ);

      await service
        .CreateGDContratoPessoaJuridica(convertedDataPJ)
        .then(async () => {
          navigate("/Parceiro/Contratos");
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Criado com sucesso!",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        })
        .catch((e: AxiosError) => {
          var errorMessage: string = e.response
            ? String(e.response?.data)
            : "Houve um erro ao gerar o contrato.";

          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "warning",
            title: errorMessage,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        });
    }
  };

  const convertEmptyStringsToNullAsync = async <T extends Record<string, any>>(
    obj: T
  ): Promise<T> => {
    const updatedObj = { ...obj };

    await Promise.all(
      (Object.keys(updatedObj) as (keyof T)[]).map(async (key) => {
        const value = updatedObj[key];
        if (typeof value === "string" && value === "") {
          updatedObj[key] = null as T[keyof T]; // Garante que `null` seja atribuído de forma segura.
        }
      })
    );

    return updatedObj;
  };

  return (
    <C.Container>
      <InformacoesTitularContratar
        type={tipo as string}
        data={data}
        onChange={onChangeInformacoesTitularContratar}
      />
      {tipo === "Fisica" ? (
        <>
          <EnderecoContratar
            type={tipo as string}
            data={data}
            onChange={onChangeEnderecoContratar}
          />
          <InformacoesContratoPFContratar
            type={tipo as string}
            data={data}
            onChange={onChangeInformacoesContratoContratarPF}
          />
        </>
      ) : (
        <>
          <InformacoesContratoPJContratar
            type={tipo as string}
            data={data}
            onChange={onChangeInformacoesContratoContratarPJ}
          />
          <InformacoesResponsavelLegal
            type={tipo as string}
            data={data}
            onChange={onChangeInformacoesResponsavelLegal}
          />
        </>
      )}
      <OutrasInformacoesContratar
        type={tipo as string}
        data={data}
        onChange={onChangeOutrasInformacoesContratar}
      />
      {/* <PreferenciaContratar
        type={tipo as string}
        onChange={onChangeDataIFormPreferencia}
      />
      <DadosContato
        type={tipo as string}
        onChange={onChangeDataIFormDadosContato}
      /> */}
      <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        <D.ContainedButton $color="lightpink" onClick={GeraContrato}>
          Gerar contrato
        </D.ContainedButton>
      </D.FWStack>
    </C.Container>
  );
};

export default Contratar;
