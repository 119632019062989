import { ColorType } from "../types/ColorType";

export enum EnumStatusLead {
  leads = "Leads",
  aguardandoEnvioConta = "Aguardando envio de conta",
  contaAnexada = "Conta anexada",
  propostaGerada = "Proposta Gerada",
  cancelada = "Cancelada",
}

export const EnumStatusLeadNumericMap = {
  [EnumStatusLead.leads]: 0,
  [EnumStatusLead.aguardandoEnvioConta]: 1,
  [EnumStatusLead.contaAnexada]: 2,
  [EnumStatusLead.propostaGerada]: 3,
  [EnumStatusLead.cancelada]: 4,
};

export const EnumStatusLeadColorMap: Record<number, ColorType> = {
  0: "blue",
  1: "yellow",
  2: "green",
  3: "purple",
  4: "red",
};
