import { InputAdornment, TextField } from "@mui/material";
import { ColorType } from "../../types/ColorType";
import * as C from "./style";
import * as D from "../../styles/appComponents";
import { ChangeEvent, useRef, useState } from "react";

interface ColorInputProps {
  fullwidth?: boolean;
  label: string;
  required?: boolean;
  readonly?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const ColorInput: React.FC<ColorInputProps> = ({
  value,
  label,
  fullwidth = true,
  required = false,
  readonly = false,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!readonly) {
      const selectedColor = event.target.value;
      onChange(selectedColor);
    }
  };

  const handleClick = () => {
    if (!readonly) {
      inputRef.current?.click();
    }
  };

  return (
    <>
      <TextField
        fullWidth={fullwidth}
        label={label}
        value={value}
        required={required}
        onChange={handleColorChange}
        inputProps={{ readOnly: readonly }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <C.ColorPicker $color={value} onClick={handleClick} />
              <C.VisuallyHiddenInput
                type="color"
                ref={inputRef}
                value={value}
                onChange={handleColorChange}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default ColorInput;
