import RegisterCard from "../../../../../../../components/RegisterCard";
import React, {FC, useEffect, useReducer, useState} from "react";
import * as G from "../../style";
import {Checkbox, Chip, MenuItem, Stack, TextField} from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import {ptBR} from "@mui/x-data-grid/locales";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {GridColDef} from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import {IFormBeneficiosComissao} from "../BeneficiosComissao";
import * as C from "../Situacao/style";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";

enum DocumentosEnum {
  CPF = "CPF",
  RG = "RG",
  PIS = "PIS",
  NUMERO = "NUMERO",
  SERIE = "SERIE",
  UF = "UF",
}

export interface IDocumentos {
  cpf: string;
  rg: string;
  pis: string;
  numero: string;
  serie: string;
  uf: string;
}

type PropsDocumentos = {
  onSave: (data: IDocumentos) => void;
  onUpdate: (data: IDocumentos) => void;
  onChange: (data: IDocumentos) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  data?: object | null;
};

const styleError = {
  "& .MuiOutlinedInput-root": {
    color: "#000",
    fontFamily: "Arial",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "2px",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "#FF0000"
  },
};

const Documentos:FC<PropsDocumentos> = ({onSave,onUpdate,onChange,buttonSave,data,fieldErros}) => {
  const sendOnSave = (): void => onSave(state);
  const sendOnUpdate = (): void => onUpdate(state);
  const sendOnChange = (update: IDocumentos): void => onChange(update);

  const setDataForm = (state: IDocumentos, action: {
    type: DocumentosEnum;
    payload: string;
  }): IDocumentos => {
    const change = {...state, [action.type.toLowerCase()]: action.payload};
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    cpf: "",
    rg: "",
    pis: "",
    numero: "",
    serie: "",
    uf: "",
  });

  const handleInputChange = (field: DocumentosEnum) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: field, payload: event.target.value});
  };

  const columns2: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    {field: "desc", headerName: "Decrição", flex: 1},
    {
      field: "data",
      headerName: "Data Upload",
      flex: 1,
    },
    {
      field: "anexo",
      headerName: "Anexo",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{fontSize: "10px"}}
            startIcon={<DownloadIcon/>}
            $color="blue"
          >
            Baixar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{fontSize: "10px"}}
            startIcon={<DeleteIcon/>}
            $color="red"
          >
            Deletar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
  ];

  const rows2 = [
    {
      id: 1,
      responsavel: "Thaynara",
      desc: "RG",
      data: "01/01/2024",
    },
    {
      id: 2,
      responsavel: "Thaynara",
      desc: "CPF",
      data: "01/01/2024",
    },
    {
      id: 3,
      responsavel: "Thaynara",
      desc: "Carteira de trabalho",
      data: "01/01/2024",
    },
  ];

  const verifyFieldError = (field:string) => {
    return fieldErros?.some((x) => x.ref === field.toLocaleLowerCase())
  };

  return (
    <RegisterCard title="Documentos">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={verifyFieldError(DocumentosEnum.CPF)? styleError: {}}
          disabled={buttonSave}
          type="number"
          label="CPF" 
          fullWidth 
          onChange={handleInputChange(DocumentosEnum.CPF)}
        />
        <TextField
          sx={verifyFieldError(DocumentosEnum.RG)? styleError: {}} 
          disabled={buttonSave} 
          label="RG" 
          fullWidth 
          onChange={handleInputChange(DocumentosEnum.RG)}
        />
        <TextField
          sx={verifyFieldError(DocumentosEnum.PIS)? styleError: {}} 
          disabled={buttonSave}
          type="number"
          label="PIS" 
          fullWidth 
          onChange={handleInputChange(DocumentosEnum.PIS)}
        />
        <TextField
          sx={verifyFieldError(DocumentosEnum.NUMERO)? styleError: {}} 
          disabled={buttonSave}
          type="number"
          label="Número" 
          fullWidth 
          onChange={handleInputChange(DocumentosEnum.NUMERO)}
        />
        <TextField
          sx={verifyFieldError(DocumentosEnum.SERIE)? styleError: {}} 
          disabled={buttonSave} 
          label="Série" 
          fullWidth 
          onChange={handleInputChange(DocumentosEnum.SERIE)}
        />
        <TextField 
          disabled={buttonSave} 
          label="UF" 
          sx={verifyFieldError(DocumentosEnum.UF)? {...styleError, width:"50%"}: {width: "50%"}} 
          onChange={handleInputChange(DocumentosEnum.UF)}
        />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={8}>
        <G.MWDiv>
          <G.ArchiveArea>
            <G.Title>
              <FileUploadIcon/>
              <h2>Upload de arquivos</h2>
            </G.Title>
            <G.FWStack>
              <TextField
                sx={{backgroundColor: "white"}}
                label="Descrição do documento"
                multiline
                rows={4}
              />
            </G.FWStack>
            <G.FWStack direction={"row"} justifyContent={"space-between"}>
              <D.ContainedButton $color="blue" startIcon={<FileUploadIcon/>}>
                Anexar documento
              </D.ContainedButton>
              <D.ContainedButton>Anexar</D.ContainedButton>
            </G.FWStack>
          </G.ArchiveArea>
        </G.MWDiv>
        <G.MWDiv>
          <G.LightBWArea>
            <D.DefaultTable
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows2}
              columns={columns2}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          </G.LightBWArea>
        </G.MWDiv>
      </G.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
          <C.FWStack direction={"column"} spacing={2} justifyContent={"flex-start"}>
            <C.FWStack direction={"row"} spacing={2}>
              <D.ContainedButton $color="lightpink">Gerar contrato de trabalho</D.ContainedButton>
              <D.ContainedButton $color="lightpink">Gerar a ficha de registro</D.ContainedButton>
              <D.ContainedButton $color="lightpink">Gerar termo de responsabilidade</D.ContainedButton>
              <D.ContainedButton $color="lightpink">Gerar termo de recebimento de cartão</D.ContainedButton>
            </C.FWStack>
          </C.FWStack>

          <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
    </RegisterCard>
  );
};

export default Documentos;
