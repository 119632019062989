import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { FC, useReducer, useState } from "react";
import * as C from "./style";
import * as G from "../../style";
import { Checkbox, Stack, TextField } from "@mui/material";
import ImageInput from "../../../../../../../components/ImageInput";
import * as D from "../../../../../../../styles/appComponents";
import { EnumSituacaoColaborador } from "../../../../../../../enums/EnumSituacaoColaborador.enum";
import { IFormSituacao } from "../../../../../../../models/Colaborador/cadastros/Situacao";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";
import { isEnumMember } from "typescript";

enum FormSituacao {
  EMPRESA = "EMPRESA",
  NUMEROREGISTRO = "NUMEROREGISTRO",
  SITUACAO = "SITUACAO",
  FOTO = "FOTO",
  CODIGOCONDICAO = "CODIGOCONDICAO",
  CONDICAO = "CONDICAO",
  DATAAFASTAMENTO = "DATAAFASTAMENTO",
}

type PropsSituacao = {
  onSave: (data: IFormSituacao) => void;
  onUpdate: () => void;
  onChange: (data: IFormSituacao) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  data?: object | null;
};

const styleError = {
  "& .MuiOutlinedInput-root": {
    color: "#000",
    fontFamily: "Arial",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "2px",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "#FF0000"
  },
}

const Situacao:FC<PropsSituacao> = ({onSave,onUpdate,onChange,buttonSave,fieldErros}) => {
  const sendOnSave = (): void => onSave(state);
  const sendOnUpdate = (): void => onUpdate();
  const sendOnChange = (update: IFormSituacao): void => onChange(update);

  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const setDataForm = (state: IFormSituacao, action: {
    type: FormSituacao;
    payload: string | File | Date | number;
  }): IFormSituacao => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    empresa: "",
    numeroregistro: undefined,
    situacao: 0,
    foto: null,
    codigocondicao: undefined,
    condicao: "",
    dataafastamento: null,
  });

  const handleInputChange = (field: FormSituacao) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: field, payload: event.target.value });
  };

  const handleImageChange = (newImage: File | null) => {
    setSelectedImage(newImage);
    if (newImage !== null) {
      dispatch({ type: FormSituacao.FOTO, payload: newImage });
    }
  };

  const verifyFieldError = (field:string) => {
    return fieldErros?.some((x) => x.ref === field.toLocaleLowerCase())
  }

  return (
    <RegisterCard title="Situação">
      <C.Container>
        <C.Left>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={verifyFieldError(FormSituacao.EMPRESA)? styleError: {}} 
              disabled={buttonSave} 
              label="Empresa" 
              fullWidth 
              onChange={handleInputChange(FormSituacao.EMPRESA)} 
            />
            <TextField 
              disabled={buttonSave} 
              type="number" 
              label="Número de registro" 
              sx={verifyFieldError(FormSituacao.NUMEROREGISTRO)? {...styleError, width: "40%"}:{ width: "40%" }} 
              onChange={handleInputChange(FormSituacao.NUMEROREGISTRO)} 
            />
          </G.FWStack>
          <G.FWStack direction={"row"} style={{ alignItems: "end" }} spacing={2}>
            <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
              <G.Title>Situação</G.Title>
              {Object.values(EnumSituacaoColaborador).map((value, index) => (
                <G.CheckboxWrapper key={value}>
                  <Checkbox
                    sx={verifyFieldError(FormSituacao.SITUACAO)?{...styleError, margin: 0}:{ margin: 0 }}
                    disabled={buttonSave}
                    checked={state?.situacao === index}
                    value={value}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({type: FormSituacao.SITUACAO, payload: index});
                    }}
                  />
                  <p>{value}</p>
                </G.CheckboxWrapper>
              ))}
            </Stack>
            <TextField
              sx={verifyFieldError(FormSituacao.CODIGOCONDICAO)? styleError: {}}
              disabled={buttonSave} 
              type="number" 
              label="Código da condição" 
              fullWidth 
              onChange={handleInputChange(FormSituacao.CODIGOCONDICAO)} 
            />
            <TextField
              sx={verifyFieldError(FormSituacao.CONDICAO)? styleError: {}} 
              disabled={buttonSave} 
              label="Condição" 
              fullWidth 
              onChange={handleInputChange(FormSituacao.CONDICAO)} 
            />
            <D.DateTextfield
              sx={verifyFieldError(FormSituacao.DATAAFASTAMENTO)? styleError: {}} 
              disabled={buttonSave} 
              type="date" 
              label="Data afastamento" 
              fullWidth 
              onChange={handleInputChange(FormSituacao.DATAAFASTAMENTO)} 
            />
          </G.FWStack>
        </C.Left>
        <C.Right>
          <ImageInput onImageChange={handleImageChange} />
        </C.Right>
      </C.Container>

      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
    </RegisterCard>
  );
};

export default Situacao;
