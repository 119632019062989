import { TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import { IResponsavelUsina } from "../../../../../models/Gerador/Cadastro/ResponsavelUsina";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { UsinaTipoPessoaFisica, UsinaTipoPessoaJuridica } from "../../../../../models/Gerador/Usina";
import { FC, useEffect, useState } from "react";
import { SkeletonSectionForm } from "../../../../../components/SkeletonLoads/SectionForm";

enum ResponsavelUsinaEnum{
    NOMERESPONSAVELUSINA="NOMERESPONSAVELUSINA",
    EMAILRESPONSAVELUSINA="EMAILRESPONSAVELUSINA",
    CELULARRESPONSAVELUSINA="CELULARRESPONSAVELUSINA",
    CPFRESPONSAVELUSINA="CPFRESPONSAVELUSINA",
    CEPRESPONSAVELUSINA="CEPRESPONSAVELUSINA",
    UFRESPONSAVELUSINA="UFRESPONSAVELUSINA",
    CIDADERESPONSAVELUSINA="CIDADERESPONSAVELUSINA",
    BAIRRORESPONSAVELUSINA="BAIRRORESPONSAVELUSINA",
    NUMERORESPONSAVELUSINA="NUMERORESPONSAVELUSINA",
    COMPLEMENTORESPONSAVELUSINA="COMPLEMENTORESPONSAVELUSINA",
    LOGRADOURORESPONSAVELUSINA="LOGRADOURORESPONSAVELUSINA",
}

export interface PropsResponsavelUsina {
    onSave: (data: IResponsavelUsina) => void;
    onChange: (data: IResponsavelUsina) => void;
    onUpdate: (data: IResponsavelUsina) => void;
    buttonSave: boolean;
    fieldErros: ErrorMessage[] | null;
    geradorId: string | null | undefined;
    usina: UsinaTipoPessoaFisica | undefined;
    load: { status:boolean, section:string }
}

export const ResponsavelUsina:FC<PropsResponsavelUsina> = (
    {
        onSave,
        onChange,
        onUpdate,
        buttonSave,
        fieldErros,
        geradorId,
        usina,
        load
    }
) => {

    const formResponsavelUsinaModel:IResponsavelUsina = {
        nomeresponsavelusina: null,
        emailresponsavelusina: null,
        celularresponsavelusina: null,
        cpfresponsavelusina: null,
        cepresponsavelusina: null,
        ufresponsavelusina: null,
        cidaderesponsavelusina: null,
        bairroresponsavelusina: null,
        numeroresponsavelusina: null,
        complementoresponsavelusina: null,
        logradouroresponsavelusina: null
    }
    const [responsavelUsina,setResponsavelUsina] = useState<IResponsavelUsina>(formResponsavelUsinaModel)

    useEffect(() => {
        if(geradorId){
            const formResponsavelUsinaModel:IResponsavelUsina = {
                nomeresponsavelusina: geradorId? usina?.nomeResponsavelUsina: null,
                emailresponsavelusina: geradorId? usina?.emailResponsavelUsina: null,
                celularresponsavelusina: geradorId? usina?.celularResponsavelUsina: null,
                cpfresponsavelusina: geradorId? usina?.cpfResponsavelUsina: null,
                cepresponsavelusina: geradorId? usina?.cepResponsavelUsina: null,
                ufresponsavelusina: geradorId? usina?.ufResponsavelUsina: null,
                cidaderesponsavelusina: geradorId? usina?.cidadeResponsavelUsina: null,
                bairroresponsavelusina: geradorId? usina?.bairroResponsavelUsina: null,
                numeroresponsavelusina: geradorId? usina?.numeroResponsavelUsina: null,
                complementoresponsavelusina: geradorId? usina?.complementoResponsavelUsina: null,
                logradouroresponsavelusina: geradorId? usina?.logradouroResponsavelUsina: null
            }
            setResponsavelUsina(formResponsavelUsinaModel)
        }

    }, [geradorId,usina])

    const sendOnSave = () => onSave(responsavelUsina);
    const sendOnUpdate = () => onUpdate(responsavelUsina);
    const sendOnChange = (update:IResponsavelUsina) => onChange(update);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ): void => {
        const name = event.target.name.toLocaleLowerCase();
        const value = event.target.value;
    
        setResponsavelUsina((state: IResponsavelUsina) => {
          sendOnChange({ ...state, [name]: value });
          return { ...state, [name]: value };
        });
    };

    return(
        <RegisterCard title="Responsável pela Usina">
            {
                load.status && load.section === "responsavelUsina"?
                <SkeletonSectionForm />
                :
                <>
                <C.FWStack direction={"row"} spacing={2}>
                    <TextField
                        name={ResponsavelUsinaEnum.NOMERESPONSAVELUSINA}
                        value={responsavelUsina?.nomeresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Nome"
                        required
                        fullWidth
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.EMAILRESPONSAVELUSINA}
                        value={responsavelUsina?.emailresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        sx={{ width:"50%" }}
                        onChange={handleInputChange}
                        label="Email"
                        type="email"
                        required
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.CELULARRESPONSAVELUSINA}
                        value={responsavelUsina?.celularresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        sx={{ width:"50%" }}
                        type="number"
                        onChange={handleInputChange}
                        label="Celular"
                        required
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.CPFRESPONSAVELUSINA}
                        value={responsavelUsina?.cpfresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        sx={{ width:"50%" }}
                        onChange={handleInputChange}
                        label="CPF"
                        type="number"
                        required
                        fullWidth
                    />
                </C.FWStack>
                <C.FWStack direction={"row"} spacing={2}>
                    <TextField
                        name={ResponsavelUsinaEnum.CEPRESPONSAVELUSINA}
                        value={responsavelUsina?.cepresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        sx={{ width:"50%" }}
                        type="number"
                        onChange={handleInputChange}
                        label="CEP"
                        required
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.UFRESPONSAVELUSINA}
                        value={responsavelUsina?.ufresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        sx={{ width:"30%" }}
                        onChange={handleInputChange}
                        label="UF"
                        required
                        fullWidth
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.CIDADERESPONSAVELUSINA}
                        value={responsavelUsina?.cidaderesponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Cidade"
                        required
                        fullWidth
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.BAIRRORESPONSAVELUSINA}
                        value={responsavelUsina?.bairroresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Bairro"
                        required
                        fullWidth
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.NUMERORESPONSAVELUSINA}
                        value={responsavelUsina?.numeroresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        sx={{ width:"30%" }}
                        onChange={handleInputChange}
                        label="Número"
                        type="number"
                        required
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.COMPLEMENTORESPONSAVELUSINA}
                        value={responsavelUsina?.complementoresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        sx={{ width:"50%" }}
                        onChange={handleInputChange}
                        label="Complemento"
                        required
                    />
                </C.FWStack>
                <C.FWStack direction={"row"} spacing={2}>
                <TextField
                    name={ResponsavelUsinaEnum.LOGRADOURORESPONSAVELUSINA}
                    value={responsavelUsina?.logradouroresponsavelusina ?? ""}
                    inputProps={{ readOnly: buttonSave }}
                    sx={{ width:"30%" }}
                    onChange={handleInputChange}
                    label="Logradouro"
                    required
                />
                </C.FWStack>
                <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <D.ContainedButton
                    disabled={!buttonSave}
                    onClick={sendOnUpdate}
                    $color="yellow"
                >
                    Editar
                </D.ContainedButton>
                <D.ContainedButton 
                    disabled={buttonSave} 
                    onClick={sendOnSave}
                >
                    Salvar
                </D.ContainedButton>
                </C.FWStack>
                </>
            }
        </RegisterCard>
    );
};