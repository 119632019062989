import RegisterCard from "../../../../../../../components/RegisterCard";
import React, {FC, useEffect, useReducer, useState} from "react";
import * as G from "../../style";
import { Checkbox, Stack, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { EnumModalidadePlano } from "../../../../../../../enums/EnumModalidadePlano.enum";
import { EnumTipoComissao } from "../../../../../../../enums/EnumTipoComissao.enum";
import * as C from "../Situacao/style";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { formatValues } from "../../../../../../../utils/moneyFormatter";
import { useAuth } from "../../../../../../../hooks/useAuth";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";

enum beneficiosComissao {
  BOOLPLANOSAUDE = "BOOLPLANOSAUDE",
  BOOLPLANOODONTOLOGICO = "BOOLPLANOODONTOLOGICO",
  ENUMMODALIDADEPLANOSAUDE = "ENUMMODALIDADEPLANOSAUDE",
  ENUMMODALIDADEPLANOODONTOLOGICO = "ENUMMODALIDADEPLANOODONTOLOGICO",
  BOOLPONTOELETRONICO = "BOOLPONTOELETRONICO",
  BOOLRECEBECOMISSAO = "BOOLRECEBECOMISSAO",
  ENUMTIPOCOMISSAO = "ENUMTIPOCOMISSAO",
  PERCENTUALCOMISSAO = "PERCENTUALCOMISSAO",
  VALORFIXOCOMISSAO = "VALORFIXOCOMISSAO",
  BOOLRECEBEVR = "BOOLRECEBEVR",
  VALORDIAVR = "VALORDIAVR",
  BOOLRECEBEVA = "BOOLRECEBEVA",
  VALORDIAVA = "VALORDIAVA",
  BOOLRECEBECOMBUSTIVEL = "BOOLRECEBECOMBUSTIVEL",
  VALORDIACOMBUSTIVEL = "VALORDIACOMBUSTIVEL",
  BOOLRECEBEVT = "BOOLRECEBEVT",
  ENUMTIPOVT = "ENUMTIPOVT",
  VALORDIAVT = "VALORDIAVT",
  ADDNEWFORM = "ADDNEWFORM",
  REMOVEFORM = "REMOVEFORM",
  LINHASDEONIBUS = "LINHASDEONIBUS"
}

export interface IFormBeneficiosComissao {
  boolplanosaude: boolean,
  boolplanoodontologico: boolean,
  enummodalidadeplanosaude: number,
  enummodalidadeplanoodontologico: number,
  boolpontoeletronico: boolean,
  boolrecebecomissao: boolean,
  enumtipocomissao: number,
  percentualcomissao: string,
  valorfixocomissao: string,
  boolrecebevr: boolean,
  valordiavr: string,
  boolrecebeva: boolean,
  valordiava: string,
  boolrecebecombustivel: boolean,
  valordiacombustivel: string,
  boolrecebevt: boolean,
  enumtipovt: number,
  valordiavt: number
}

export interface IFormInputLinhasDeOnibus{
  valordiaBhBusvt: string;
  valordiaOtimovt: string;
  linhaBhBusIda: string;
  linhaBhBusVolta: string;
  linhaOtimoIda: string;
  linhaOtimoVolta: string;
}

type PropsBeneficiosComissao = {
  onSave: (data: IFormBeneficiosComissao) => void;
  onUpdate: (data: IFormBeneficiosComissao) => void;
  onChange: (data: IFormBeneficiosComissao) => void;
  getData: (data: IFormInputLinhasDeOnibus[]) => void;
  getValorTotalVt: (data:number) => void;
  fieldErros: ErrorMessage[] | null;
  buttonSave: boolean;
  data?: object | null;
};

const styleError = {
  "& .MuiOutlinedInput-root": {
    color: "#000",
    fontFamily: "Arial",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "2px",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "#FF0000"
  },
};


const BeneficiosComissao:FC<PropsBeneficiosComissao> = (
  {
    onSave,
    onUpdate,
    onChange,
    getData,
    getValorTotalVt,
    fieldErros,
    buttonSave,
    data
  }
) => {
  const {user} = useAuth()

  const formInputLinhasDeOnibus:IFormInputLinhasDeOnibus = {
    valordiaBhBusvt: "",
    valordiaOtimovt: "",
    linhaBhBusIda: "",
    linhaBhBusVolta: "",
    linhaOtimoIda: "",
    linhaOtimoVolta: ""
  }

  const [linhasDeOnibus, setLinhasDeOnibus] = useState<IFormInputLinhasDeOnibus[]>([formInputLinhasDeOnibus])

  const totalDiaVt = linhasDeOnibus.reduce((total:number, linha:any) => {
    const valordiaBhBusvt = parseFloat(linha?.valordiaBhBusvt?.replace(/,/g, '.'))
    const valordiaOtimovt = parseFloat(linha?.valordiaOtimovt?.replace(/,/g, '.'))

    return total + ((valordiaBhBusvt || 0) + (valordiaOtimovt || 0))

  }, 0)
    
  const sendOnSave = (): void => onSave(state);
  const sendOnUpdate = (): void => onUpdate(state);
  const sendOnChange = (update: IFormBeneficiosComissao): void => onChange(update);

  const [planoSaude, setPlanoSaude] = useState(false);
  const [planoOdontologico, setPlanoOdontologico] = useState(false);
  const [vr, setVr] = useState(false);
  const [va, setVa] = useState(false);
  const [vc, setVc] = useState(false);
  const [batePonto, setBatePonto] = useState("");
  const [recebeComissao, setRecebeComissao] = useState("");
  const [selectedValueModPlanoSaude, setSelectedValueModPlanoSaude] = useState("");
  const [selectedValueModPlanoOdonto, setSelectedValueModPlanoOdonto] = useState("");
  const [selectedValueComissao, setSelectedValueComissao] = useState("");
  const [selectedValueRecebeVT, setSelectedValueRecebeVT] = useState(false);
  const [selectedValueCartao, setSelectedValueCartao] = useState("");

  useEffect(() => {
    if(selectedValueRecebeVT){
      getData(linhasDeOnibus)
      getValorTotalVt(totalDiaVt)

    }else{
      getData([])
      getValorTotalVt(0)
    }
      
  }, [selectedValueRecebeVT])

  const setDataForm = (state: IFormBeneficiosComissao, action: {
    type: beneficiosComissao;
    payload: string | boolean | number;
  }): IFormBeneficiosComissao => {
    const change = {...state, [action.type.toLowerCase()]: action.payload};
    sendOnChange(change);
    return change;
  };

  const beneficiosComissaoFormModel = {
    boolplanosaude: false,
    boolplanoodontologico: false,
    enummodalidadeplanosaude: 0,
    enummodalidadeplanoodontologico: 0,
    boolpontoeletronico: true,
    boolrecebecomissao: true,
    enumtipocomissao: 0,
    percentualcomissao: "",
    valorfixocomissao: "",
    boolrecebevr: false,
    valordiavr: "",
    boolrecebeva: false,
    valordiava: "",
    boolrecebecombustivel: false,
    valordiacombustivel: "",
    boolrecebevt: false,
    enumtipovt: 0,
    valordiavt: 0
  }

  const [state, dispatch] = useReducer(setDataForm, beneficiosComissaoFormModel);

  const handleInputChange = (field: beneficiosComissao) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: field, payload: event.target.value});
  };

  const handleSetPlanoSaude = (value: boolean): void => {
    setPlanoSaude(!planoSaude);
    dispatch({type: beneficiosComissao.BOOLPLANOSAUDE, payload: value});
  }

  const handleSetPlanoOdontologico = (value: boolean): void => {
    setPlanoOdontologico(!planoSaude);
    dispatch({type: beneficiosComissao.BOOLPLANOODONTOLOGICO, payload: value});
  }

  const handleSetVr = (value: boolean): void => {
    setVr(value);
    dispatch({type: beneficiosComissao.BOOLRECEBEVR, payload: value});
  }

  const handleSetVa = (value: boolean): void => {
    setVa(value);
    dispatch({type: beneficiosComissao.BOOLRECEBEVA, payload: value});
  }

  const handleSetVc = (value: boolean): void => {
    setVc(value);
    dispatch({type: beneficiosComissao.BOOLRECEBECOMBUSTIVEL, payload: value});
  }

  const handleChangeSelectComissao = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueComissao(event.target.value);
  };

  const handleChangeSelectBatePonto = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const toBoolean = event.target.value === "sim";

    dispatch({type: beneficiosComissao.BOOLPONTOELETRONICO, payload: toBoolean});
    setBatePonto(event.target.value);
  };

  const handleChangeSelectRecebeComissao = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const toBoolean = event.target.value === "sim";

    dispatch({type: beneficiosComissao.BOOLRECEBECOMISSAO, payload: toBoolean});
    setRecebeComissao(event.target.value);
  };

  const handleChangeSelectModPlanoSaude = (
    event: React.ChangeEvent<HTMLInputElement>, index: number
  ) => {
    dispatch({type: beneficiosComissao.ENUMMODALIDADEPLANOSAUDE, payload: index});
    setSelectedValueModPlanoSaude(event.target.value);
  };

  const handleChangeSelectModPlanoOdonto = (
    event: React.ChangeEvent<HTMLInputElement>, index: number
  ) => {
    dispatch({type: beneficiosComissao.ENUMMODALIDADEPLANOODONTOLOGICO, payload: index});
    setSelectedValueModPlanoOdonto(event.target.value);
  };

  const handleChangeSelectRecebeCartao = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueCartao(event.target.value);
  };

  const addNewForm = () => {
    setLinhasDeOnibus((x) => [...x, formInputLinhasDeOnibus])
  }

  const removeForm = (index:number) => {
    setLinhasDeOnibus((x) => x.filter((_, i:number) => i !== index))
  }

  const handleChangeLinhaDeOnibus = (event:React.ChangeEvent<HTMLInputElement>, index:number) => {
    const name = event.target.name;
    const value = event.target.value;

    const newForm = [...linhasDeOnibus];

    newForm[index] = { ...newForm[index], [name]: value };

    setLinhasDeOnibus(newForm);
  }

  const handleChangeSelectRecebeVT = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({type: beneficiosComissao.BOOLRECEBEVT, payload: event.target.value === "sim"});
    setSelectedValueRecebeVT(event.target.value === "sim");
  };

  const verifyFieldError = (field:string) => {
    return fieldErros?.some((x) => x.ref === field.toLocaleLowerCase())
  };

  return (
    <RegisterCard title="Benefícios/comissão">
      <G.FWStack direction={"row"} spacing={8}>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Planos</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={planoSaude}
              onChange={() => {
                handleSetPlanoSaude(!planoSaude)
              }}
            />
            <p>Plano de saúde</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={planoOdontologico}
              onChange={() => handleSetPlanoOdontologico(!planoOdontologico)}
            />
            <p>Plano odontológico</p>
          </G.CheckboxWrapper>
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Modalidade plano de saúde</G.Title>
          {Object.values(EnumModalidadePlano).map((value, index) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                sx={verifyFieldError(beneficiosComissao.ENUMMODALIDADEPLANOSAUDE)? styleError: {}}
                disabled={!planoSaude || buttonSave}
                checked={selectedValueModPlanoSaude === value}
                value={value}
                onChange={(e) => handleChangeSelectModPlanoSaude(e, index)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Modalidade plano odontológico</G.Title>
          {Object.values(EnumModalidadePlano).map((value, index) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                sx={verifyFieldError(beneficiosComissao.ENUMMODALIDADEPLANOODONTOLOGICO)? styleError: {}}
                disabled={!planoOdontologico || buttonSave}
                checked={selectedValueModPlanoOdonto === value}
                value={value}
                onChange={(e) => handleChangeSelectModPlanoOdonto(e, index)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Bate ponto elêtronico</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={batePonto === "sim"}
              value={"sim"}
              onChange={(e) => handleChangeSelectBatePonto(e)}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={batePonto === "nao"}
              value={"nao"}
              onChange={(e) => handleChangeSelectBatePonto(e)}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Recebe comissão</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={recebeComissao === "sim"}
              value={"sim"}
              onChange={(e) => handleChangeSelectRecebeComissao(e)}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={recebeComissao === "nao"}
              value={"nao"}
              onChange={(e) => handleChangeSelectRecebeComissao(e)}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </G.FWStack>
      </G.FWStack>
      <G.FWStack direction={"row"} style={{alignItems: "center"}} spacing={2}>
        <Stack direction={"column"} spacing={2}>
          <G.Title>Comissão</G.Title>
          {Object.values(EnumTipoComissao).map((value) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={buttonSave}
                checked={selectedValueComissao === value}
                value={value}
                onChange={(e) => handleChangeSelectComissao(e)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <TextField
          sx={verifyFieldError(beneficiosComissao.PERCENTUALCOMISSAO)? styleError: {}}
          fullWidth
          label="Porcentual de comissão"
          onChange={handleInputChange(beneficiosComissao.PERCENTUALCOMISSAO)}
          disabled={selectedValueComissao.toLowerCase() !== "porcentagem" || buttonSave}
        />
        <TextField
          sx={verifyFieldError(beneficiosComissao.VALORFIXOCOMISSAO)? styleError: {}}
          fullWidth
          label="Valor fixo de comissão"
          onChange={handleInputChange(beneficiosComissao.VALORFIXOCOMISSAO)}
          disabled={selectedValueComissao.toLowerCase() !== "fixa" || buttonSave}
        />
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Recebe</G.Title>
          <G.CheckboxWrapper>
            <Checkbox disabled={buttonSave} checked={vr} onChange={() => handleSetVr(!vr)} />
            <p>VR</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox disabled={buttonSave} checked={va} onChange={() => handleSetVa(!va)} />
            <p>VA</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox disabled={buttonSave} checked={vc} onChange={() => handleSetVc(!vc)} />
            <p>Combustível</p>
          </G.CheckboxWrapper>
        </Stack>

        <TextField
          sx={verifyFieldError(beneficiosComissao.VALORDIAVR)? styleError: {}}
          onChange={handleInputChange(beneficiosComissao.VALORDIAVR)}
          fullWidth label="Valor dia VR" disabled={!vr || buttonSave} />
        <TextField
          sx={verifyFieldError(beneficiosComissao.VALORDIAVA)? styleError: {}}
          onChange={handleInputChange(beneficiosComissao.VALORDIAVA)}
          fullWidth label="Valor dia VA" disabled={!va || buttonSave} />
        <TextField
          sx={verifyFieldError(beneficiosComissao.VALORDIACOMBUSTIVEL)? styleError: {}}
          onChange={handleInputChange(beneficiosComissao.VALORDIACOMBUSTIVEL)}
          fullWidth label="Valor dia combustível" disabled={!vc || buttonSave} />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={25}>
        <Stack direction={"column"} spacing={4} alignItems={"flex-start"}>
          <G.Title>Recebe VT</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={selectedValueRecebeVT}
              value={"sim"}
              onChange={(e) => handleChangeSelectRecebeVT(e)}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={!selectedValueRecebeVT}
              value={"nao"}
              onChange={(e) => handleChangeSelectRecebeVT(e)}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Cartão</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={selectedValueCartao === "sim"}
              value={"sim"}
              onChange={(e) => handleChangeSelectRecebeCartao(e)}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              disabled={buttonSave}
              checked={selectedValueCartao === "nao"}
              value={"nao"}
              onChange={(e) => handleChangeSelectRecebeCartao(e)}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </Stack>
        <G.FWStack direction={"column"} spacing={4}>
          {linhasDeOnibus.map((linha, index:number) => (
            <>
              <G.FWStack style={{ flexDirection:"row", gap:20 }}>
                <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
                  <TextField
                    fullWidth
                    value={linha?.linhaBhBusIda}
                    label="Linhas BHBUS ida"
                    name="linhaBhBusIda"
                    disabled={!selectedValueRecebeVT || buttonSave}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeLinhaDeOnibus(event, index)}
                  />
                  <TextField
                    fullWidth
                    value={linha?.linhaBhBusVolta}
                    name="linhaBhBusVolta"
                    label="Linhas BHBUS volta"
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeLinhaDeOnibus(event, index)}
                    disabled={!selectedValueRecebeVT || buttonSave}
                  />
                  <TextField
                    fullWidth
                    value={linha?.valordiaBhBusvt}
                    name="valordiaBhBusvt"
                    label="Valor dia BHBUS"
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeLinhaDeOnibus(event, index)}
                    disabled={!selectedValueRecebeVT || buttonSave}
                  />
                </G.FWStack>
                <G.FWStack direction={"row"}>
                  <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
                    <TextField
                      fullWidth
                      value={linha?.linhaOtimoIda}
                      name="linhaOtimoIda"
                      label="Linhas ÓTIMO ida"
                      onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeLinhaDeOnibus(event, index)}
                      disabled={!selectedValueRecebeVT || buttonSave}
                    />
                    <TextField
                      fullWidth
                      value={linha?.linhaOtimoVolta}
                      name="linhaOtimoVolta"
                      label="Linhas ÓTIMO volta"
                      onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeLinhaDeOnibus(event, index)}
                      disabled={!selectedValueRecebeVT || buttonSave}
                    />
                    <TextField
                      fullWidth
                      value={linha?.valordiaOtimovt}
                      name="valordiaOtimovt"
                      label="Valor dia ÓTIMO"
                      onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeLinhaDeOnibus(event, index)}
                      disabled={!selectedValueRecebeVT || buttonSave}
                    />
                  </G.FWStack>
                </G.FWStack>
              </G.FWStack>
            </>
          ))}
          <G.FWStack style={{ display:"flex", flexDirection:"column", gap:10 }}>
            <TextField
                fullWidth
                value={formatValues(totalDiaVt) }
                disabled
              />
            <D.ContainedButton
              disabled={buttonSave}
              startIcon={<AddCircleIcon />}
              style={{marginTop: 'auto'}}
              onClick={addNewForm}>Adicionar linhas
            </D.ContainedButton>
          </G.FWStack>
        </G.FWStack>
      </G.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
    </RegisterCard>
  );
};

export default BeneficiosComissao;
