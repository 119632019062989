import { MenuItem, TextField } from "@mui/material";
import { useEffect, useReducer } from "react";
import RegisterCard from "../../../../../../../components/RegisterCard";
import {
  EnumEstadoCivil,
  EnumEstadoCivilNumericMap,
} from "../../../../../../../enums/EnumEstadoCivil.enum";
import {
  IFormInformacoesTitularPJ,
  IFormInformacoesTitularPF,
} from "../../../../../../../models/Contratar";
import * as D from "../../../../../../../styles/appComponents";
import { IFullGDProposta } from "../../../../../../../models/GDProposta";
import { useObjectState } from "../../../../../../../hooks/useObjectState";
import { IFullGDContratoCliente } from "../../../../../../../models/GDContrato";

export type IInformacoesTitularContratar = {
  dataPF: IFormInformacoesTitularPF;
  dataPJ: IFormInformacoesTitularPJ;
};

type PropsInformacoesTitularContratar = {
  type: string;
  data?: IFullGDProposta;
  dataContrato?: IFullGDContratoCliente;
  onChange: (data: IInformacoesTitularContratar) => void;
};

const InformacoesTitularContratar = (
  props: PropsInformacoesTitularContratar
) => {
  const { type, data, dataContrato } = props;

  const {
    state: statePF,
    setObject: setObjectPF,
    updateObject: updateObjectPF,
  } = useObjectState<IFormInformacoesTitularPF>({
    nome: "",
    cpf: "",
    estado_civil: "",
    nacionalidade: "",
    profissao: "",
  });

  const {
    state: statePJ,
    setObject: setObjectPJ,
    updateObject: updateObjectPJ,
  } = useObjectState<IFormInformacoesTitularPJ>({
    bairro: "",
    cep: "",
    cidade: "",
    complemento: "",
    cnpj: "",
    logradouro: "",
    numero: "",
    uf: "",
    numeroInstalacao: "",
    razaoSocial: "",
  });

  useEffect(() => {
    props.onChange({
      dataPF: statePF,
      dataPJ: statePJ,
    });
  }, [statePF, statePJ]);

  /*--------------------------------------------*/

  useEffect((): void => {
    if (dataContrato) {
      setObjectPF({
        cpf: dataContrato.cpf ?? "",
        estado_civil: dataContrato.enumEstadoCivil?.toString() ?? "",
        nacionalidade: dataContrato.nacionalidade ?? "",
        nome: dataContrato.nome ?? "",
        profissao: dataContrato.profissao ?? "",
      });

      setObjectPJ({
        bairro: dataContrato.bairro ?? "",
        cep: dataContrato.cep ?? "",
        cidade: dataContrato.cidade ?? "",
        cnpj: dataContrato.cnpj ?? "",
        complemento: dataContrato.complemento ?? "",
        logradouro: dataContrato.logradouro ?? "",
        numero: dataContrato.numero ?? "",
        numeroInstalacao: dataContrato.instalacao ?? "",
        razaoSocial: dataContrato.razaoSocial ?? "",
        uf: dataContrato.uf ?? "",
      });
    }
  }, [dataContrato]);

  useEffect((): void => {
    if (data) {
      setObjectPF({
        cpf: data.cpf ?? "",
        estado_civil: "",
        nacionalidade: "Brasileiro (a)",
        nome: data.nome ?? "",
        profissao: "",
      });

      setObjectPJ({
        bairro: "",
        cep: "",
        cidade: "",
        cnpj: "",
        complemento: "",
        logradouro: "",
        numero: "",
        numeroInstalacao: data.numeroInstalacao ?? "",
        razaoSocial: data.nome ?? "",
        uf: "",
      });
    }
  }, [data]);

  return (
    <RegisterCard title="Informações do titular">
      {type === "Fisica" ? (
        <>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Nome"
              required
              fullWidth
              value={statePF.nome}
              onChange={(e) => updateObjectPF({ nome: e.target.value })}
            />
            <TextField
              label="CPF"
              required
              fullWidth
              value={statePF.cpf}
              onChange={(e) => updateObjectPF({ cpf: e.target.value })}
            />
            <TextField
              label="Estado Civil"
              required
              fullWidth
              select
              value={statePF.estado_civil}
              onChange={(e) => updateObjectPF({ estado_civil: e.target.value })}
            >
              {Object.values(EnumEstadoCivil).map((value) => (
                <MenuItem key={value} value={EnumEstadoCivilNumericMap[value]}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Nacionalidade"
              required
              fullWidth
              value={statePF.nacionalidade}
              onChange={(e) =>
                updateObjectPF({ nacionalidade: e.target.value })
              }
            />
            <TextField
              label="Profissão"
              required
              fullWidth
              value={statePF.profissao}
              onChange={(e) => updateObjectPF({ profissao: e.target.value })}
            />
          </D.FWStack>
        </>
      ) : (
        <>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Razão social"
              required
              fullWidth
              value={statePJ.razaoSocial}
              onChange={(e) => updateObjectPJ({ razaoSocial: e.target.value })}
            />
            <TextField
              label="CNPJ"
              required
              fullWidth
              value={statePJ.cnpj}
              onChange={(e) => updateObjectPJ({ cnpj: e.target.value })}
            />

            <TextField
              label="Número instalação"
              required
              fullWidth
              value={statePJ.numeroInstalacao}
              onChange={(e) =>
                updateObjectPJ({ numeroInstalacao: e.target.value })
              }
            />
          </D.FWStack>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="CEP"
              required
              fullWidth
              value={statePJ.cep}
              onChange={(e) => updateObjectPJ({ cep: e.target.value })}
            />
            <TextField
              label="UF"
              required
              value={statePJ.uf}
              sx={{ width: "50%" }}
              onChange={(e) => updateObjectPJ({ uf: e.target.value })}
            />
            <TextField
              label="Cidade"
              required
              fullWidth
              value={statePJ.cidade}
              onChange={(e) => updateObjectPJ({ cidade: e.target.value })}
            />
            <TextField
              label="Bairro"
              required
              fullWidth
              value={statePJ.bairro}
              onChange={(e) => updateObjectPJ({ bairro: e.target.value })}
            />
            <TextField
              label="Logradouro"
              required
              fullWidth
              value={statePJ.logradouro}
              onChange={(e) => updateObjectPJ({ logradouro: e.target.value })}
            />
          </D.FWStack>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Número"
              required
              sx={{ width: "50%" }}
              value={statePJ.numero}
              onChange={(e) => updateObjectPJ({ numero: e.target.value })}
            />
            <TextField
              label="Complemento"
              required
              fullWidth
              value={statePJ.complemento}
              onChange={(e) => updateObjectPJ({ complemento: e.target.value })}
            />
            <D.FWStack></D.FWStack>
            <D.FWStack></D.FWStack>
            <D.FWStack></D.FWStack>
          </D.FWStack>
        </>
      )}
      {/* {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null} */}
    </RegisterCard>
  );
};

export default InformacoesTitularContratar;
