import React, { FC, useReducer, useState } from "react";
import RegisterCard from "../../../../../../components/RegisterCard";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { Stack, TextField } from "@mui/material";
import * as C from "../style";
import * as D from "../../../../../../styles/appComponents";

enum FormResponsavelLegal{
    NOME="NOME",
    EMAIL="EMAIL",
    CELULAR="CELULAR",
    CPF="CPF",
    CEP="CEP",
    UF="UF",
    CIDADE="CIDADE",
    BAIRRO="BAIRRO",
    COMPLEMENTO="COMPLEMENTO",
    NUMERO="NUMERO",
    LOGRADOURO="LOGRADOURO",
    ADDNEWFORM="ADDNEWFORM",
    REMOVEFORM="REMOVEForm"
}

export interface IFormResponsavelLegal{
    nome:string;
    email:string;
    celular:string;
    cpf:string;
    cep:string;
    uf:string;
    cidade:string;
    bairro:string;
    numero:string;
    complemento:string;
    logradouro:string;
}

type PropsReponsavelLegal = {
    onSave: (data:IFormResponsavelLegal[]) => void;
    onUpdate: (data:IFormResponsavelLegal[]) => void;
    onChange: (data:IFormResponsavelLegal[]) => void;
    data?: object | null;
    children: React.ReactNode;
}

export const ReponsavelLegal:FC<PropsReponsavelLegal> = ({onSave, onUpdate, onChange, children}) => {
    const sendOnSave = (): void => onSave(state);
    const sendOnUpdate = (): void => onUpdate(state);
    const sendOnChange = (update: IFormResponsavelLegal[]): void => onChange(update);

    const dataFormModel = {
        nome: "",
        email: "",
        celular: "",
        cpf: "",
        cep: "",
        uf: "",
        cidade: "",
        bairro: "",
        numero:"",
        complemento: "",
        logradouro: ""
    }

    const setDataForm = (state: IFormResponsavelLegal[], action: {
        type: FormResponsavelLegal;
        payload: string;
        index: number;
      }): IFormResponsavelLegal[] => {

        if(action.type === FormResponsavelLegal.ADDNEWFORM) return [...state, dataFormModel ]

        if(action.type === FormResponsavelLegal.REMOVEFORM)
            return state.filter((_, index) => index !== action.index)
            
        
        const formDataResponsavel = [...state];
        formDataResponsavel[action.index] = { ...formDataResponsavel[action.index], [action.type.toLowerCase()]: action.payload };

        const change = formDataResponsavel;
        sendOnChange(change);

        return change;
      };

    const [state, dispatch] = useReducer(setDataForm, [dataFormModel]);

    const addNewForm = () => {
        dispatch({ type: FormResponsavelLegal.ADDNEWFORM, payload:"", index:0 })
    }

    const removeForm = (index:number) => {
        dispatch({ type:FormResponsavelLegal.REMOVEFORM, payload:"",index })
    }

    const handleInputChange = (field: FormResponsavelLegal, index:number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: field, payload: event.target.value, index });
    };

    return(
        <RegisterCard title="Informações do responsável legal">
            {state.map((responsavel:IFormResponsavelLegal, index:number) => (
            <Stack key={index} spacing={2}>
                <Stack
                direction={"row"}
                spacing={2}
                marginTop={index > 0 ? "30px" : "none"}
                >
                <TextField
                    label="Nome do responsável legal"
                    fullWidth
                    value={responsavel.nome}
                    onChange={handleInputChange(FormResponsavelLegal.NOME, index)}
                />
                <TextField
                    label="E-mail"
                    fullWidth
                    value={responsavel.email}
                    onChange={handleInputChange(FormResponsavelLegal.EMAIL, index)}
                />
                <TextField
                    label="Celular"
                    fullWidth
                    value={responsavel.celular}
                    onChange={handleInputChange(FormResponsavelLegal.CELULAR, index)}
                />
                <TextField
                    label="CPF"
                    fullWidth
                    value={responsavel.cpf}
                    onChange={handleInputChange(FormResponsavelLegal.CPF, index)}
                />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                <TextField
                    label="CEP"
                    fullWidth
                    value={responsavel.cep}
                    onChange={handleInputChange(FormResponsavelLegal.CEP, index)}
                />
                <TextField
                    label="UF"
                    sx={{ width: "40%" }}
                    value={responsavel.uf}
                    onChange={handleInputChange(FormResponsavelLegal.UF, index)}
                />
                <TextField
                    label="Cidade"
                    fullWidth
                    value={responsavel.cidade}
                    onChange={handleInputChange(FormResponsavelLegal.CIDADE, index)}
                />
                <TextField
                    label="Bairro"
                    fullWidth
                    value={responsavel.bairro}
                    onChange={handleInputChange(FormResponsavelLegal.BAIRRO, index)}
                />
                <TextField
                    label="Número"
                    sx={{ width: "40%" }}
                    value={responsavel.numero}
                    onChange={handleInputChange(FormResponsavelLegal.NUMERO, index)}
                />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                <TextField
                    label="Complemento"
                    fullWidth
                    value={responsavel.complemento}
                    onChange={handleInputChange(FormResponsavelLegal.COMPLEMENTO, index)}
                />
                <TextField
                    label="Logradouro"
                    fullWidth
                    value={responsavel.logradouro}
                    onChange={handleInputChange(FormResponsavelLegal.LOGRADOURO, index)}
                />
                </Stack>
                {state.length > 1 && (
                <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"flex-end"}
                    marginTop={2}
                >
                    <D.ContainedButton
                    startIcon={<RemoveCircleOutlineIcon />}
                    onClick={() => removeForm(index)}
                    variant="contained"
                    $color="blue"
                    >
                    Remover responsável legal
                    </D.ContainedButton>
                </Stack>
                )}
            </Stack>
            ))}
            <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <D.ContainedButton
                startIcon={<AddIcon />}
                onClick={addNewForm}
                $color="lightpink"
            >
                Adicionar outro responsável legal
            </D.ContainedButton>
            </C.FWStack>
            {children}
        </RegisterCard>
    )
}