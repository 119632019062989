import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { FC, useReducer } from "react";
import * as G from "../../style";
import { Chip, MenuItem, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { EnumSexo } from "../../../../../../../enums/EnumSexo.enum";
import { EnumEstadoCivil } from "../../../../../../../enums/EnumEstadoCivil.enum";
import { EnumGrauInstrucao } from "../../../../../../../enums/EnumGrauInstrucao.enum";
import { EnumTipoChavePix } from "../../../../../../../enums/EnumTipoChavePix.enum";
import * as C from "../Situacao/style";
import { EnumEstado } from "../../../../../../../enums/EnumEstado.enum";
import { IFormDadosPessoais } from "../../../../../../../models/Colaborador/cadastros/DadosPessoais";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";

enum FormDadosPessoais {
  NOME = "NOME",
  DATANASCIMENTO = "DATANASCIMENTO",
  SEXO = "SEXO",
  ESTADOCIVIL = "ESTADOCIVIL",
  GRAUINSTRUCAO = "GRAUINSTRUCAO",
  MUNICIPIONASCIMENTO = "MUNICIPIONASCIMENTO",
  NACIONALIDADE = "NACIONALIDADE",
  DATAADMISSAO = "DATAADMISSAO",
  CEP = "CEP",
  UF = "UF",
  CIDADE = "CIDADE",
  BAIRRO = "BAIRRO",
  LOGRADOURO = "LOGRADOURO",
  NUMERO = "NUMERO",
  COMPLEMENTO = "COMPLEMENTO",
  CELULARPARACONTATO = "CELULARPARACONTATO",
  EMAILPARACONTATO = "EMAILPARACONTATO",
  BANCO = "BANCO",
  AGENCIA = "AGENCIA",
  CONTA = "CONTA",
  CPFFAVORECIDO = "CPFFAVORECIDO",
  FAVORECIDO = "FAVORECIDO",
  TIPOCHAVEPIX = "TIPOCHAVEPIX",
  CHAVEPIX = "CHAVEPIX",
}

type PropsDadosPessoais = {
  onSave: (data: IFormDadosPessoais) => void;
  onUpdate: () => void;
  onChange: (data: IFormDadosPessoais) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  cities: string[];
  data?: IFormDadosPessoais | null;
};

const styleError = {
  "& .MuiOutlinedInput-root": {
    color: "#000",
    fontFamily: "Arial",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "2px",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "#FF0000"
  },
}

const DadosPessoais:FC<PropsDadosPessoais> = ({onSave,onUpdate,onChange,buttonSave,cities,fieldErros}) => {
  const sendOnSave = (): void => onSave(state);
  const sendOnUpdate = (): void => onUpdate();
  const sendOnChange = (update: IFormDadosPessoais): void => onChange(update);

  const setDataForm = (state: IFormDadosPessoais, action: {
    type: FormDadosPessoais;
    payload: string | number;
  }): IFormDadosPessoais => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    nome: "",
    datanascimento: undefined,
    sexo: 0,
    estadocivil: 0,
    grauinstrucao: 0,
    municipionascimento: "",
    nacionalidade: "",
    dataadmissao: undefined,
    cep: "",
    uf: "",
    cidade: "",
    bairro: "",
    logradouro: "",
    numero: "",
    complemento: "",
    celularparacontato: "",
    emailparacontato: "",
    banco: "",
    agencia: "",
    conta: "",
    cpffavorecido: "",
    favorecido: "",
    tipochavepix: 0,
    chavepix: "",
  });

  const handleInputChange = (field: FormDadosPessoais) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: field, payload: event.target.value });
  };

  const handleChipClick = (chipId: number) => () => {
    dispatch({ type: FormDadosPessoais.TIPOCHAVEPIX, payload: chipId });
  };

  const verifyFieldError = (field:string) => {
    return fieldErros?.some((x) => x.ref === field.toLocaleLowerCase())
  }

  return (
    <RegisterCard title="Dados pessoais">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={
            verifyFieldError(FormDadosPessoais.NOME)?
            styleError:
            {}
          }
          disabled={buttonSave} 
          label="Nome" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.NOME)} 
        />
        <D.DateTextfield disabled={buttonSave} type="date" label="Data de nascimento" fullWidth onChange={handleInputChange(FormDadosPessoais.DATANASCIMENTO)} />
        <TextField
          sx={verifyFieldError(FormDadosPessoais.SEXO)? styleError: {}}
          disabled={buttonSave} 
          select 
          label="Sexo" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.SEXO)}
        >
          {Object.values(EnumSexo).map((value, i) => (
            <MenuItem key={i} value={i}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={verifyFieldError(FormDadosPessoais.ESTADOCIVIL)? styleError: {}} 
          disabled={buttonSave} 
          select 
          label="Estado civil" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.ESTADOCIVIL)}
        >
          {Object.values(EnumEstadoCivil).map((value, i) => (
            <MenuItem key={i} value={i}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={verifyFieldError(FormDadosPessoais.GRAUINSTRUCAO)? styleError: {}} 
          disabled={buttonSave} 
          select 
          label="Grau de instrução" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.GRAUINSTRUCAO)}
        >
          {Object.values(EnumGrauInstrucao).map((value, i) => (
            <MenuItem key={i} value={i}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={verifyFieldError(FormDadosPessoais.MUNICIPIONASCIMENTO)? styleError: {}}  
          disabled={buttonSave} 
          label="Município de nascimento" 
          fullWidth onChange={handleInputChange(FormDadosPessoais.MUNICIPIONASCIMENTO)}
        />
        <TextField
          sx={verifyFieldError(FormDadosPessoais.NACIONALIDADE)? styleError: {}}  
          disabled={buttonSave} 
          label="Nacionalidade" 
          fullWidth onChange={handleInputChange(FormDadosPessoais.NACIONALIDADE)}
         />
        <D.DateTextfield
          sx={verifyFieldError(FormDadosPessoais.DATAADMISSAO)? styleError: {}} 
          type="date"
          disabled={buttonSave} 
          label="Data de admissão" 
          fullWidth onChange={handleInputChange(FormDadosPessoais.DATAADMISSAO)}
        />
        <TextField
          sx={verifyFieldError(FormDadosPessoais.CEP)? styleError: {}} 
          disabled={buttonSave}
          type="number"
          label="CEP" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.CEP)}
        />
        <TextField
          disabled={buttonSave}
          select
          label="UF" 
          sx={verifyFieldError(FormDadosPessoais.CEP)? {...styleError, width:"35%"}:{ width: "35%" }} 
          onChange={handleInputChange(FormDadosPessoais.UF)} 
        >
          {Object.values(EnumEstado).map((value, i) => (
            <MenuItem key={i} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={verifyFieldError(FormDadosPessoais.CIDADE)? styleError: {}}  
          disabled={buttonSave}
          select 
          label="Cidade" fullWidth 
          onChange={handleInputChange(FormDadosPessoais.CIDADE)}
        >
          {(cities || []).map((city:string, i:number) => (
            <MenuItem key={i} value={city}>
              {city}
            </MenuItem>
          ))}
        </TextField>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={verifyFieldError(FormDadosPessoais.BAIRRO)? styleError: {}}  
          disabled={buttonSave} 
          label="Bairro" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.BAIRRO)}
        />
        <TextField
          sx={verifyFieldError(FormDadosPessoais.LOGRADOURO)? styleError: {}}  
          disabled={buttonSave} 
          label="Logradouro" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.LOGRADOURO)} 
        />
        <TextField 
          disabled={buttonSave}
          type="number" 
          label="Número" 
          sx={verifyFieldError(FormDadosPessoais.LOGRADOURO)?{ ...styleError, width:"30%" }:{ width: "30%" }} 
          onChange={handleInputChange(FormDadosPessoais.NUMERO)}
        />
        <TextField
          sx={verifyFieldError(FormDadosPessoais.COMPLEMENTO)? styleError: {}}  
          disabled={buttonSave} 
          label="Complemento" 
          fullWidth 
          onChange={handleInputChange(FormDadosPessoais.COMPLEMENTO)} 
        />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={16}>
        <G.MWDiv>
          <G.Subtitle>Dados bancários*</G.Subtitle>
          <G.Text>Insira seus dados bancários</G.Text>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={verifyFieldError(FormDadosPessoais.BANCO)? styleError: {}}  
              disabled={buttonSave} 
              label="Banco" 
              required 
              fullWidth onChange={handleInputChange(FormDadosPessoais.BANCO)}
            />
            <TextField
              sx={verifyFieldError(FormDadosPessoais.AGENCIA)? styleError: {}}  
              disabled={buttonSave} 
              label="Agência"
              type="number"
              required 
              fullWidth 
              onChange={handleInputChange(FormDadosPessoais.AGENCIA)} 
            />
          </G.FWStack>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={verifyFieldError(FormDadosPessoais.CONTA)? styleError: {}}  
              disabled={buttonSave} 
              label="Conta"
              type="number"
              required 
              fullWidth 
              onChange={handleInputChange(FormDadosPessoais.CONTA)}
            />
            <TextField
              sx={verifyFieldError(FormDadosPessoais.CPFFAVORECIDO)? styleError: {}}  
              disabled={buttonSave} 
              label="CPF do favorecido"
              type="number"
              required 
              fullWidth 
              onChange={handleInputChange(FormDadosPessoais.CPFFAVORECIDO)}
            />
          </G.FWStack>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={verifyFieldError(FormDadosPessoais.FAVORECIDO)? styleError: {}}  
              label="Favorecido"
              disabled={buttonSave}
              required 
              fullWidth 
              onChange={handleInputChange(FormDadosPessoais.FAVORECIDO)} 
            />
            <G.FWDiv></G.FWDiv>
          </G.FWStack>
        </G.MWDiv>
        <G.MWDiv>
          <G.Subtitle>Pix*</G.Subtitle>
          <G.Text>Qual dado você quer usar?</G.Text>
          <G.FWStack direction="row" spacing={2}>
            {Object.values(EnumTipoChavePix).map((value, index) => (
              <Chip
                sx={verifyFieldError(FormDadosPessoais.TIPOCHAVEPIX)? styleError: {}} 
                key={index}
                disabled={buttonSave}
                label={value}
                onClick={handleChipClick(index)}
                color={state?.tipochavepix === index ? "primary" : "default"}
              />
            ))}
          </G.FWStack>
          <G.Text>Insira sua chave pix</G.Text>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={verifyFieldError(FormDadosPessoais.CHAVEPIX)? styleError: {}} 
              label="Chave PIX"
              disabled={buttonSave}
              required 
              fullWidth 
              onChange={handleInputChange(FormDadosPessoais.CHAVEPIX)}
             />
          </G.FWStack>
        </G.MWDiv>
      </G.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
        <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};

export default DadosPessoais;
