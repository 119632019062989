import BeneficiosComissao, {IFormBeneficiosComissao, IFormInputLinhasDeOnibus} from "./components/BeneficiosComissao";
import CRM from "./components/CRM";
import DadosAcesso from "./components/DadosAcesso";
import DadosPessoais from "./components/DadosPessoais";
import Documentos, {IDocumentos} from "./components/Documentos";
import Ferias from "./components/Ferias";
import InformacoesMedicas, {IInformacoesMedicas} from "./components/InformacoesMedicas";
import SalarioFuncao, {IFormSalarioFuncao} from "./components/SalarioFuncao";
import {useEffect, useState} from "react";
import { Button } from "@mui/material";
import { formatValueStringInFloat, formatValues } from "../../../../../utils/moneyFormatter";
import * as service from "../../../../../services/api/ColaboradorService";
import { toastMessage } from "../../../../../utils/toastMessage";
import { dataCreateColaborador } from "../../../../../utils/returnNewData";
import { useAuth } from "../../../../../hooks/useAuth";
import { IBeneficioComissaoCreate } from "../../../../../models/Colaborador/cadastros/BeneficioComissao";
import { SituacaoValidate } from "./components/validation/SituacaoValidate";
import { DadosPessoaisValidate } from "./components/validation/DadosPessoaisValidate";
import ibgeService from "../../../../../services/api/IbgeService";
import { SalarioFuncaoValidate } from "./components/validation/SalarioFuncaoValidate";
import { IFormSituacao } from "../../../../../models/Colaborador/cadastros/Situacao";
import Situacao from "./components/Situacao";
import { IFormDadosPessoais } from "../../../../../models/Colaborador/cadastros/DadosPessoais";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { BeneficioComissaoValidate } from "./components/validation/BeneficioComissaoValidate";
import { DocumentoValidate } from "./components/validation/DocumentoValidate";
import { iDadosAcesso } from "../../../../../models/Colaborador/cadastros/DadosAcesso";
import { DadosAcessoValidate } from "./components/validation/DadosAcessoValidate";

const CadastroColaborador = () => {
  const [situacao, setSituacao] = useState<IFormSituacao | undefined>(undefined);
  const [dadosPessoais, setDadosPessoais] = useState<IFormDadosPessoais | undefined>(undefined);
  const [salarioFuncao, setSalarioFuncao] = useState<IFormSalarioFuncao | undefined>(undefined);
  const [beneficiosComissao, setBeneficiosComissao] = useState<IBeneficioComissaoCreate | undefined>(undefined);
  const [documentos, setDocumentos] = useState<IDocumentos | undefined>(undefined);
  const [ dadosAcesso, setDadosAcesso ] = useState<iDadosAcesso | undefined>(undefined)
  const [informacoesMedicas, setInformacoesMedicas] = useState<IInformacoesMedicas | undefined>(undefined);
  const [linhaOnibus, setLinhaOnibus] = useState<IFormInputLinhasDeOnibus[]>([])
  const [ valorDiaVT, setValorDiaVT ] = useState<number | undefined>(0)

  const [ buttonSaveSituacao, setButtonSaveSituacao ] = useState<boolean>(false)
  const [ buttonSaveDadosPessoais, setButtonSaveDadosPessoais ] = useState<boolean>(false)
  const [ buttonSaveSalarioFuncao, setButtonSaveSalarioFuncao ] = useState<boolean>(false)
  const [ buttonSaveBeneficioComissao, setButtonSaveBeneficioComissao ] = useState<boolean>(false)
  const [ buttonSaveDocumentos, setButtonSaveDocumentos ] = useState<boolean>(false)
  const [ buttonSaveDadosAcesso, setButtonSaveDadosAcesso ] = useState<boolean>(false)

  const [ salarioFuncaoFieldErros, setSalarioFuncaoFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ beneficioComissaoFieldErros, setBeneficioComissaoFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ dadosPessaoisFieldErros, setDadosPessoaisFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ situacaoFieldErros, setSituacaoFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ documentosFieldErros, setDocumentosFieldErros ] = useState<ErrorMessage[] | null>([])
  const [ dadosAcessoFieldsErros, setDadosAcessoFieldsErros ] = useState<ErrorMessage[] | null>([])
  
  const [ cities, setCities ] = useState<string[]>([])
  const {user} = useAuth()

  useEffect(() => {
    const loadCities = async () => {
      try {
        if(dadosPessoais?.uf){
          const { data } = await ibgeService.getCities(dadosPessoais?.uf)
          setCities((data || []).map((x:any) => x.nome))
        }

      } catch (error) {
        console.error(`Erro ao listar cidades: ${error}`)
      }
    }
    loadCities()

  }, [dadosPessoais?.uf])

  const onChangeSituacao = (data: IFormSituacao): void => {
    setSituacao(data);

  };

  const onSaveSituacao = (data: IFormSituacao) => {
    const resultValidation = new SituacaoValidate();
    const errors = resultValidation.errorMessage(data)

    if(errors){
      setSituacaoFieldErros(errors)
      errors.forEach((error) => {
        toastMessage("warning",error.message)
      })

    }else{
      setSituacaoFieldErros([])
      setButtonSaveSituacao(true)
    }

  }

  const onUpdateSituacao = () => {
    setButtonSaveSituacao(false)
  }

  const onSaveDadosPessoais = (data: IFormDadosPessoais): void => {

    const resutlValidation = new DadosPessoaisValidate();
    const erroMessages = resutlValidation.errorMessage(data)

    if(erroMessages){
      setDadosPessoaisFieldErros(erroMessages)

      erroMessages.forEach((error) => {
        toastMessage("warning",error.message)
      })

    }else {
      setButtonSaveDadosPessoais(true)
      setDadosPessoaisFieldErros([])
    }

    
  };

  const onChangeDadosPessoais = (data: IFormDadosPessoais):void => {
    setDadosPessoais(data);
  }

  const onUpdateDadosPessoais = () => {
    setButtonSaveDadosPessoais(false)
  }

  const onSaveSalarioFuncao = (data: IFormSalarioFuncao): void => {

      const resultValidate = new SalarioFuncaoValidate();
      const errorMessages = resultValidate.errorMessage(data)

      if(errorMessages){
        setSalarioFuncaoFieldErros(errorMessages)
        errorMessages.forEach((error) => {
          toastMessage("warning", error.message)
        });

      }else {
        setSalarioFuncaoFieldErros([])
        setButtonSaveSalarioFuncao(true);
      };
      
  };

  const onChangeSalarioFuncao = (data: IFormSalarioFuncao): void => {
    setSalarioFuncao(data);
  };

  const onUpdateSalarioFuncao = (): void => {
    setButtonSaveSalarioFuncao(false)
  };

  const getData = (data:IFormInputLinhasDeOnibus[]) => {
    setLinhaOnibus(data)
  }

  const getValorTotalVt = (data:number) => {
    setValorDiaVT(data)
  }

  const onSaveBeneficiosComissao = (data: IFormBeneficiosComissao): void => {

    const validationResult = new BeneficioComissaoValidate();
    const errorMessages = validationResult.errorMessage(data)

    if(errorMessages){
      setBeneficioComissaoFieldErros(errorMessages)

      errorMessages.forEach((error) => {
        toastMessage("warning",error.message)
      })

    }else{
      setBeneficioComissaoFieldErros([])
      setButtonSaveBeneficioComissao(true)
    }
  };

  const onChangeBeneficioComissao = (data: IFormBeneficiosComissao): void => {
    const newData:IBeneficioComissaoCreate = { 
      ...data, 
      percentualcomissao: formatValueStringInFloat(data?.percentualcomissao),
      valorfixocomissao: formatValueStringInFloat(data?.valorfixocomissao),
      valordiavr: formatValueStringInFloat(data?.valordiavr),
      valordiava: formatValueStringInFloat(data?.valordiava),
      valordiacombustivel: formatValueStringInFloat(data?.valordiacombustivel)
    }

    setBeneficiosComissao(newData)
  }

  const onUpdateBeneficioComissao = () => {
    setButtonSaveBeneficioComissao(false)
  }

  const onSaveDocumentos = (data: IDocumentos): void => {
    const validationResult = new DocumentoValidate()
    const errorMessages = validationResult.errorMessage(data)

    if(errorMessages){
      setDocumentosFieldErros(errorMessages)

      errorMessages.forEach((error) => {
        toastMessage("warning", error.message)
      })

    }else{
      setButtonSaveDocumentos(true)
      setDocumentosFieldErros([])
    }

  };

  const onChangeDocumentos = (data: IDocumentos): void => {
    setDocumentos(data);
  };

  const onUpdateDocumentos = () => [
    setButtonSaveDocumentos(false)
  ]

  const onSaveInformacoesMedicas = (data: IInformacoesMedicas): void => {
    setInformacoesMedicas(data);
  };

  const onSaveDadosAcesso = (data: iDadosAcesso) => {
    const resultValidation = new DadosAcessoValidate();
    const errorMessages = resultValidation.errorMessage(data)

    if(errorMessages){
      setDadosAcessoFieldsErros(errorMessages)

      errorMessages.forEach((error) => {
        toastMessage("warning", error.message)
      })

    }else{
      setDadosAcessoFieldsErros([])
      setButtonSaveDadosAcesso(true)
    }

  }

  const onChangeDadosAcesso = (data: iDadosAcesso) => {
    setDadosAcesso(data)
  }

  const onUpdateDadosAcesso = () => {
    setButtonSaveDadosAcesso(false)
  }

  const data:any = {
    ...situacao,
    ...dadosPessoais,
    ...salarioFuncao,
    ...beneficiosComissao,
    ...documentos,
    ...informacoesMedicas,
    ...dadosAcesso,
    valorDiaVT,
    linhaOnibus,
    user
  };

  const statusSections = [
    buttonSaveSituacao,
    buttonSaveDadosPessoais,
    buttonSaveSalarioFuncao,
    buttonSaveBeneficioComissao,
    buttonSaveDadosAcesso
  ]

  const newData = dataCreateColaborador(data);

  const registrarColaborador = async () => {

    try {

      if(statusSections.some(status => !status))
        return toastMessage("warning","Alguns dados não foram salvos")

      await service.CreateColaborador(newData);
      toastMessage("success", "colaborador criado com sucesso!")
      
    } catch (e:any) {
      var errorMessage: string = e?.response
      ? String(e?.response?.data)
      : "Houve um erro ao pegar os backoffices.";

      toastMessage("warning", errorMessage)
    }
  }

  return (
    <>
      <Situacao
        onChange={onChangeSituacao}
        onSave={onSaveSituacao}
        onUpdate={onUpdateSituacao}
        buttonSave={buttonSaveSituacao}
        fieldErros={situacaoFieldErros}/>
      <DadosPessoais
        onChange={onChangeDadosPessoais}
        onSave={onSaveDadosPessoais}
        onUpdate={onUpdateDadosPessoais}
        buttonSave={buttonSaveDadosPessoais}
        fieldErros={dadosPessaoisFieldErros}
        cities={cities}/>
      <SalarioFuncao
        onChange={onChangeSalarioFuncao}
        onSave={onSaveSalarioFuncao}
        onUpdate={onUpdateSalarioFuncao}
        buttonSave={buttonSaveSalarioFuncao}
        fieldErros={salarioFuncaoFieldErros}
      />
      <BeneficiosComissao
        onChange={onChangeBeneficioComissao}
        onSave={onSaveBeneficiosComissao}
        onUpdate={onUpdateBeneficioComissao}
        getData={getData}
        getValorTotalVt={getValorTotalVt}
        fieldErros={beneficioComissaoFieldErros}
        buttonSave={buttonSaveBeneficioComissao}
      />
      <Documentos
        onChange={onChangeDocumentos}
        onSave={onSaveDocumentos}
        onUpdate={onUpdateDocumentos}
        buttonSave={buttonSaveDocumentos}
        fieldErros={documentosFieldErros}
      />
      <InformacoesMedicas
        onChange={onSaveInformacoesMedicas}
        onSave={onSaveInformacoesMedicas}
        onUpdate={onSaveInformacoesMedicas}
      />
      <Ferias />
      <CRM />
      <DadosAcesso
        onChange={onChangeDadosAcesso}
        onSave={onSaveDadosAcesso}
        onUpdate={onUpdateDadosAcesso}
        buttonSave={buttonSaveDadosAcesso}
        fieldErros={dadosAcessoFieldsErros}
        scrollRef={null} 
        onReset={() => {}}
      />
      <Button onClick={registrarColaborador} >Cadastrar</Button>
    </>
  );
};

export default CadastroColaborador;
