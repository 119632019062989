import { MenuItem, Stack, TextField } from "@mui/material"
import RegisterCard from "../../../../../components/RegisterCard"
import * as C from "./style";
import * as D from "../../../../../styles/appComponents";
import TotalCardOrdem from "../../../../../components/TotalCardOrdem";
import { useSearchParams } from "react-router-dom";
import { MonthYearCalendar } from "../Components/MonthYearCalendar";
import dayjs, { Dayjs } from 'dayjs';
import { useState } from "react";

const meses = [
    "Janeiro",
    "Fervereiro"
]

const anos = [
    "2021",
    "2022"
]

const usinas = [
    {
        id:0,
        name: "test"
    }
]

export const CadastroNovaOrdem = () => {
    const [ searchParams ]= useSearchParams()
    const [dateSelected, setDateSelected] = useState<Dayjs | null>(dayjs())
    const [monthYear, setMonthYear] = useState({ month:"", year:"" })
    const geradorId = searchParams.get("geradorId")

    const handleDateChange = (date:Dayjs | null) => {
      setMonthYear((state:any) => {
        return{ ...state, month: (date?.month()? date?.month() + 1: date?.month()), year: date?.year() }
      })
      setDateSelected(date)
    }

    return(
        <>
          <Stack>
            <Stack sx={{ flexDirection:"row", gap:2 }}>
                <C.StatusWrapper>
                  <TotalCardOrdem
                    colorText="green"
                    colorTitle="yellow"
                    color="yellow"
                    title="Valor total"
                    text="R$ 4.0000,00"
                    evolutionNumber="+8%"
                    evolution={true}
                  />
                </C.StatusWrapper>
                <C.StatusWrapper>
                  <TotalCardOrdem
                    colorText="blue"
                    colorTitle="blue"
                    color="green"
                    title="Energia total injetada"
                    text="200.500,00 KWH"
                    evolutionNumber="-8%"
                    evolution={false}
                  />
                </C.StatusWrapper>
            </Stack>
          </Stack>
          <RegisterCard title={geradorId? "Editar ordem": "Inserir ordem"}>
            <C.FWStack direction={"row"} spacing={2}>
              <MonthYearCalendar 
                handleDateChange={handleDateChange}
                dateSelected={dateSelected} 
              />
              <TextField sx={{ maxWidth:"30%" }} select label="Usina" required fullWidth>
              { usinas.map((usina, i) => (
                <MenuItem key={i} value={usina.id}>
                    {usina.name}
                </MenuItem>
              )) }
              </TextField>
              <TextField 
                sx={{ maxWidth:"15%" }} 
                label="Energia Distribuidora" 
                required 
                fullWidth 
              />
              <TextField 
                sx={{ maxWidth:"15%" }} 
                label="Energia Gerador" 
                required 
              />
              <TextField 
                sx={{ maxWidth:"15%" }} 
                label="Diferença" 
                required 
              />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
                <TextField 
                    sx={{ maxWidth:"10%" }} 
                    label="Saldo"
                    type="number" 
                    required 
                    fullWidth 
                />
                <TextField 
                    sx={{ maxWidth:"20%" }} 
                    label="Tarifa base" 
                    required 
                    fullWidth 
                />
                <TextField 
                    sx={{ maxWidth:"20%" }} 
                    label="Data limite de quitação" 
                    required 
                    fullWidth 
                />
                <TextField 
                    sx={{ maxWidth:"20%" }} 
                    label="Tarifa distribuidora" 
                    required 
                    fullWidth 
                />
                <TextField 
                    sx={{ maxWidth:"20%" }} 
                    label="Desconto tarifa" 
                    required 
                    fullWidth 
                />
                <TextField 
                    sx={{ maxWidth:"10%" }} 
                    label="Valor base"
                    type="number" 
                    required 
                    fullWidth 
                />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
                <TextField 
                    sx={{ maxWidth:"15%" }} 
                    label="Valor crédito"
                    type="number" 
                    required 
                    fullWidth 
                />
                <TextField 
                    sx={{ maxWidth:"15%" }} 
                    label="Tipo conexão" 
                    required 
                    fullWidth 
                />
                <TextField 
                    sx={{ maxWidth:"15%" }} 
                    label="Tarifa fio B" 
                    required 
                    fullWidth 
                />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2} justifyContent={geradorId? "space-between": "flex-end"}>
                {
                    geradorId &&
                    <>
                    <D.ContainedButton $color="green">Limpar visualização</D.ContainedButton>
                    <D.ContainedButton $color="blue">Abrir ordem</D.ContainedButton>
                    <D.ContainedButton $color="purple">Fechar ordem</D.ContainedButton>
                    <D.ContainedButton $color="red">Excluir</D.ContainedButton>
                    </>
                }
                <D.ContainedButton>Salvar</D.ContainedButton>
            </C.FWStack>
          </RegisterCard>
        </>
    )
}