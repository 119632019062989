import { ColorType } from "../types/ColorType";

export enum EnumStatusContratoCliente {
  aguardandoAssinatura = "Aguardando assinatura",
  processoConexao = "Processo de conexão",
  conectadoAOperadora = "Conectado a operadora",
  inadimplente = "Inadimplente",
  desconexaoSolicitada = "Deconexão solicitada",
}

export const StatusGDContratoNumberMap: {
  [key: number]: EnumStatusContratoCliente;
} = {
  0: EnumStatusContratoCliente.aguardandoAssinatura,
  1: EnumStatusContratoCliente.processoConexao,
  2: EnumStatusContratoCliente.conectadoAOperadora,
  3: EnumStatusContratoCliente.inadimplente,
  4: EnumStatusContratoCliente.desconexaoSolicitada,
};

export const EnumStatusGDContratoNumericMap = {
  [EnumStatusContratoCliente.aguardandoAssinatura]: 0,
  [EnumStatusContratoCliente.processoConexao]: 1,
  [EnumStatusContratoCliente.conectadoAOperadora]: 2,
  [EnumStatusContratoCliente.inadimplente]: 3,
  [EnumStatusContratoCliente.desconexaoSolicitada]: 4,
};

export const StatusContratoColorMap: Record<number, ColorType> = {
  0: "yellow",
  1: "blue",
  2: "green",
  3: "red",
  4: "purple",
};
