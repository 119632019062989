import { Modal } from "@mui/material";
import * as C from "./style"
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";

export interface ModalQuestionFormTypeProps{
    openModal:boolean;
    handleCloseModal: () => void;
    redirectTypeForm: (type:"pessoaFisica"|"pessoaJuridica") => void;
}

export const ModalQuestionFormType:FC<ModalQuestionFormTypeProps> = (
    { 
        openModal, 
        handleCloseModal,
        redirectTypeForm
    }
) => {
    return(
        <Modal open={openModal}>
            <C.ModalContainer>
                <C.ModalArea>
                    <C.ModalHeader>
                        <C.CloseButton aria-label="close" onClick={handleCloseModal}>
                            <CloseIcon />
                        </C.CloseButton>
                    </C.ModalHeader>
                    <C.FWStack alignItems="center">
                        <h2>Novo cadastro de usina é para?</h2>
                    </C.FWStack>
                    <C.FWStack direction="row" justifyContent="center" alignItems="center" gap={2}>
                        <C.DefaultGridButton onClick={() => redirectTypeForm("pessoaFisica")} $color="pink">Pessoa Física</C.DefaultGridButton>
                        <C.ContainedButton onClick={() => redirectTypeForm("pessoaJuridica")} $color="pink">Pessoa Jurídica</C.ContainedButton>
                    </C.FWStack>
                </C.ModalArea>
            </C.ModalContainer>
        </Modal>
    );
}