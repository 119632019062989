import styled from "styled-components";
import { EnumStatusContratoGerador } from "../../enums/Gerador/EnumStatusContratoGerador.enum";

export const StatusStyle = styled.div<{ status:any }>`
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin: auto;
  background-color: ${({ status }) => {
    switch (status) {
      case EnumStatusContratoGerador.contratoAssinado:
        return "blue";
      case EnumStatusContratoGerador.aguardandoAssinatura:
        return "yellow";
      case EnumStatusContratoGerador.conectado:
        return "green";
      case EnumStatusContratoGerador.aguardandoAssinaturaDistrato:
        return "red";
      case EnumStatusContratoGerador.emProcessoTrocaTitularidade:
        return "purple";
      default:
        return "transparent";
    }
  }};
`;