import * as C from "./style";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { ColorType } from "../../types/ColorType";

interface StatusCardProps {
  colorText: ColorType;
  colorTitle: ColorType;
  color: ColorType;
  title: string;
  text: string;
  evolutionNumber: string;
  evolution: boolean;
}

const TotalCardOrdem: React.FC<StatusCardProps> = ({
  colorText,
  colorTitle,
  color,
  title,
  text,
}) => {
  return (
    <C.Card>
      <C.Top>
        <C.TopTextArea>
          <C.Title colorTitle={colorTitle}>{title}</C.Title>
          <C.Text colorText={colorText}>{text}</C.Text>
        </C.TopTextArea>
      </C.Top>
    </C.Card>
  );
};

export default TotalCardOrdem;
