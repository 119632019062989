import { IBackofficeListItem, ICreateColaborador } from "../../models/Colaborador/Colaborador";
import http from "../common/http-common";

export const GetBackoffices = () => {
  return http.get<IBackofficeListItem[]>(`/Colaborador/Backoffice`);
};

export const CreateColaborador = (createColaborador:ICreateColaborador) => {
  return http.post<ICreateColaborador>("/Colaborador", createColaborador)
}
