import { IResponsavelLegal } from "../../../../models/Gerador/Cadastro/ResponsavelLegal";
import { ICreateResponsavelLegal } from "../../../../models/ResponsavelLegal";
import { AbstractValidation, Validate } from "../../../../utils/AbstractValidation";

export class ResponsavelLegalValidate extends AbstractValidation<ICreateResponsavelLegal>{
    getValidate(): Validate<ICreateResponsavelLegal>[] {
        return[
            {
                field:"nome",
                validate: (value, model:ICreateResponsavelLegal, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O nome do Responsavel Legal deve ser informado","nome")
                }
            },
            {
                field:"email",
                validate: (value, model:ICreateResponsavelLegal, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Email deve ser informado","email")
                }
            },
        ];
    }
}