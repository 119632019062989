import { AbstractValidation, Validate } from "../../../../../../../utils/AbstractValidation";
import { IFormBeneficiosComissao } from "../BeneficiosComissao";

export class BeneficioComissaoValidate extends AbstractValidation<IFormBeneficiosComissao>{
    getValidate(): Validate<IFormBeneficiosComissao>[] {
        return [
            {
                field: "enummodalidadeplanosaude",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(data?.boolplanosaude && !value) addError("A Modalidade plano de saúde deve ser informada","enummodalidadeplanosaude")
                }
            },
            {
                field: "enummodalidadeplanoodontologico",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(data?.boolplanoodontologico && !value) addError("A Modalidade plano de saúde deve ser informada","enummodalidadeplanoodontologico")
                }
            },
            {
                field: "valordiavr",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(data?.boolrecebevr && !value) addError("O valor do dia de vale refeição deve ser informada","valordiavr")
                }
            },
            {
                field: "valordiava",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(data?.boolrecebeva && !value) addError("O valor do dia de vale alimentação deve ser informada","valordiava")
                }
            },
            {
                field: "valordiacombustivel",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(data?.boolrecebecombustivel && !value) addError("O valor do dia de vale combustível deve ser informada","valordiacombustivel")
                }
            }
        ]
    }
}