import * as C from "../../../Cliente/Cadastro/style";
import {
  Box,
  Button,
  Chip,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as D from "../../../../../../styles/appComponents";
import React, { useReducer, useState } from "react";
import { IFormInformacoesContrato } from "./InformacoesContrato";
import { EnumTipoChavePix } from "../../../../../../enums/EnumTipoChavePix.enum";
import { ReportsSelect } from "../../../Leads/style";

enum FormDadosBancarios {
  BANCO = "BANCO",
  AGENCIA = "AGENCIA",
  CONTA = "CONTA",
  CNPJFAVORECIDO = "CNPJFAVORECIDO",
  FAVORECIDO = "FAVORECIDO",
  CHAVEPIX = "CHAVEPIX",
  TIPOCHAVEPIX = "TIPOCHAVEPIX",
}

export interface IFormDadosBancarios {
  banco: string;
  agencia: string;
  conta: string;
  cnpjfavorecido: string;
  favorecido: string;
  chavepix: string;
  tipochavepix: number | null;
}

type PropsDadosBancarios = {
  onSave: (data: IFormDadosBancarios) => void;
  onUpdate: (data: IFormDadosBancarios) => void;
  onChange: (data: IFormDadosBancarios) => void;
  data?: object | null;
};

export const DadosBancarios = (props: PropsDadosBancarios) => {
  const [selectedTipoChavePix, setSelectedTipoChavePix] = useState<
    number | null
  >(null);

  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IFormDadosBancarios): void =>
    props.onChange(update);

  const setDataForm = (
    state: IFormDadosBancarios,
    action: {
      type: FormDadosBancarios;
      payload: string | number;
    }
  ): IFormDadosBancarios => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    banco: "",
    agencia: "",
    conta: "",
    cnpjfavorecido: "",
    favorecido: "",
    chavepix: "",
    tipochavepix: null,
  });

  const handleInputChange =
    (field: FormDadosBancarios) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: field, payload: event.target.value });
    };

  const handleChipClick = (index: number) => () => {
    dispatch({
      type: FormDadosBancarios.TIPOCHAVEPIX,
      payload: index as number,
    });
    setSelectedTipoChavePix(index);
  };

  return (
    <RegisterCard title="Dados bancários">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "40px",
          justifyContent: "space-evenly",
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "#fff",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box>
            <h3>Dados bancários *</h3>
            <p>Insira seus dados bancários</p>
          </Box>
          <C.FWStack direction={"row"} spacing={2} sx={{ marginBottom: 2 }}>
            <ReportsSelect
              id="demo-simple-select"
              labelId="demo-simple-select-label"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {/* Opções do select */}
            </ReportsSelect>
            <TextField
              label="Agência"
              required
              fullWidth
              onChange={handleInputChange(FormDadosBancarios.AGENCIA)}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2} sx={{ marginBottom: 2 }}>
            <TextField
              label="Conta"
              required
              fullWidth
              value={state.conta}
              onChange={handleInputChange(FormDadosBancarios.CONTA)}
            />
            <TextField
              label="CNPJ do favorecido"
              required
              fullWidth
              onChange={handleInputChange(FormDadosBancarios.CNPJFAVORECIDO)}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Favorecido"
              required
              style={{ width: "48%" }}
              onChange={handleInputChange(FormDadosBancarios.FAVORECIDO)}
            />
          </C.FWStack>
        </Box>

        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box>
            <h3>Pix *</h3>
            <p>Qual dado você quer usar?</p>
          </Box>
          <Box
            sx={{
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <C.FWStack direction="row" spacing={2}>
              {Object.values(EnumTipoChavePix).map((label, index) => (
                <Chip
                  key={label}
                  label={label}
                  onClick={handleChipClick(index)}
                  color={selectedTipoChavePix === index ? "primary" : "default"}
                />
              ))}
            </C.FWStack>
          </Box>
          <TextField
            label="Insira sua chave Pix"
            required
            fullWidth
            onChange={handleInputChange(FormDadosBancarios.CHAVEPIX)}
          />
        </Box>
      </Box>

      {props.data !== null && props.data !== undefined ? (
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>
            Editar
          </D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
      ) : null}
    </RegisterCard>
  );
};
