import styled from "styled-components";
import { ColorType } from "../../types/ColorType";

export const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  box-shadow: 6px 6px 6px #0000000f;
  border-radius: 10px;
  padding: 10px 20px;

  @media (max-width: 1024px) {
    flex-basis: 48%;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const TopTextArea = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Bar = styled.div<{ color: ColorType }>`
  width: 2px;
  height: 100%;
  border-radius: 20px;
  background-color: ${({ color }) => {
    switch (color) {
      case "blue":
        return "blue";
      case "yellow":
        return "yellow";
      case "green":
        return "green";
      case "orange":
        return "#DF7A43";
      case "red":
        return "red";
      case "purple":
        return "purple";
      default:
        return "transparent";
    }
  }};
`;

export const Title = styled.p<{
  colorTitle: ColorType;
}>`
  color: ${({ colorTitle }) => {
    switch (colorTitle) {
      case "green":
        return "#00FF00;";
      case "blue":
        return "#0000ff";
      default:
        return "#5c5c5c;";
    }
  }}
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

export const Text = styled.p<{
  colorText: ColorType;
}>`
  color: ${({ colorText }) => {
    switch (colorText) {
      case "green":
        return "#00FF00;";
      case "blue":
        return "#0000ff";
      default:
        return "#5c5c5c;";
    }
  }};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30%;
`;

export const BottomText = styled.p<{ $evolution: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  color: ${(props) => (props.$evolution ? "#107C41" : "#D60D0D")};
  font-size: 12px;
  font-weight: 300;

  svg {
    width: 12px;
    height: 12px;
  }
`;
