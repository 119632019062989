import RegisterCard from "../../../../../../../components/RegisterCard";
import React, {useEffect, useReducer, useState} from "react";
import * as G from "../../style";
import {
  Checkbox,
  Chip,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { ptBR } from "@mui/x-data-grid/locales";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridColDef } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import {IDocumentos} from "../Documentos";

enum informacoesMedicas {
}

export interface IInformacoesMedicas {
}


type PropsInformacoesMedicas = {
  onSave: (data: IInformacoesMedicas) => void;
  onUpdate: (data: IInformacoesMedicas) => void;
  onChange: (data: IInformacoesMedicas) => void;
  data?: object | null;
};

const InformacoesMedicas = (props: PropsInformacoesMedicas) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IInformacoesMedicas): void => props.onChange(update);

  const setDataForm = (state: IInformacoesMedicas, action: {
    type: informacoesMedicas;
    payload: string;
  }): IInformacoesMedicas => {
    const change = {...state, [action.type]: action.payload};
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
  });

  const handleInputChange = (field: informacoesMedicas) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: field, payload: event.target.value});
  };

  const columns: GridColDef[] = [
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1,
    },
    {
      field: "exame",
      headerName: "Exame",
      flex: 1,
    },
    { field: "data", headerName: "Data assinatura", flex: 1 },
  ];

  const rows = [
    {
      id: 1,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 2,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 3,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 4,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 5,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
    {
      id: 6,
      tipo: "Pré-admissional",
      exame: "Avaliação clinica",
      data: "05/11/2013",
    },
  ];

  const columns2: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    { field: "desc", headerName: "Decrição", flex: 1 },
    {
      field: "data",
      headerName: "Data Upload",
      flex: 1,
    },
    {
      field: "anexo",
      headerName: "Anexo",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DownloadIcon />}
            $color="blue"
          >
            Baixar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DeleteIcon />}
            $color="red"
          >
            Deletar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
  ];

  const rows2 = [
    {
      id: 1,
      responsavel: "Thaynara",
      desc: "RG",
      data: "01/01/2024",
    },
    {
      id: 2,
      responsavel: "Thaynara",
      desc: "CPF",
      data: "01/01/2024",
    },
    {
      id: 3,
      responsavel: "Thaynara",
      desc: "Carteira de trabalho",
      data: "01/01/2024",
    },
  ];

  return (
    <RegisterCard title="Documentos">
      <G.FWStack direction={"row"} spacing={2}>
        <RegisterCard title="Consultas/exames">
          <D.DefaultTable
            disableColumnResize
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            hideFooter
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
          />
        </RegisterCard>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={8}>
        <G.MWDiv>
          <G.ArchiveArea>
            <G.Title>
              <FileUploadIcon />
              <h2>Upload de arquivos</h2>
            </G.Title>
            <G.FWStack>
              <TextField
                sx={{ backgroundColor: "white" }}
                label="Descrição do documento"
                multiline
                rows={4}
              />
            </G.FWStack>
            <G.FWStack direction={"row"} justifyContent={"space-between"}>
              <D.ContainedButton $color="blue" startIcon={<FileUploadIcon />}>
                Anexar documento
              </D.ContainedButton>
              <D.ContainedButton>Anexar</D.ContainedButton>
            </G.FWStack>
          </G.ArchiveArea>
        </G.MWDiv>
        <G.MWDiv>
          <G.LightBWArea>
            <D.DefaultTable
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows2}
              columns={columns2}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          </G.LightBWArea>
        </G.MWDiv>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton>Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default InformacoesMedicas;
