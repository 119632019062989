import { MenuItem, TextField } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import RegisterCard from "../../../../../../../components/RegisterCard";
import {
  EnumTipoOperadora,
  EnumTipoOperadoraNumericMap,
} from "../../../../../../../enums/EnumTipoOperadora.enum";
import { IBackofficeListItem } from "../../../../../../../models/Colaborador/Colaborador";
import { IFormInformacoesOutrasInformacoes } from "../../../../../../../models/Contratar";
import * as colaboradorServices from "../../../../../../../services/api/ColaboradorService";
import * as captadorServices from "../../../../../../../services/api/CaptadorService";
import * as operadoraService from "../../../../../../../services/api/OperadoraService";
import * as geradorService from "../../../../../../../services/api/GeradorService";
import * as D from "../../../../../../../styles/appComponents";
import { IFullGDProposta } from "../../../../../../../models/GDProposta";
import { useObjectState } from "../../../../../../../hooks/useObjectState";
import { ICaptadorListItem } from "../../../../../../../models/Captador";
import {
  EnumPreferenciaRecebimento,
  EnumPreferenciaRecebimentoNumericMap,
} from "../../../../../../../enums/EnumPreferenciaRecebimento.enum";
import { IFullGDContratoCliente } from "../../../../../../../models/GDContrato";
import { IOperadoraListItem } from "../../../../../../../models/Operadora";
import moment from "moment";
import {
  EnumGatewayDePagamento,
  EnumGatewayDePagamentoNumericMap,
} from "../../../../../../../enums/EnumGatewayDePagamento.enum";
import { IUsinaListItem } from "../../../../../../../models/Gerador/Usina";
import {
  EnumStatusContratoCliente,
  EnumStatusGDContratoNumericMap,
  StatusContratoColorMap,
} from "../../../../../../../enums/EnumStatusContratoCliente.enum";

type PropsOutrasInformacoesContratar = {
  type: string;
  data?: IFullGDProposta;
  dataContrato?: IFullGDContratoCliente;
  onChange: (data: IFormInformacoesOutrasInformacoes) => void;
};

const OutrasInformacoesContratar = (props: PropsOutrasInformacoesContratar) => {
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [operadoraList, setOperadoraList] = useState<IOperadoraListItem[]>([]);
  const [usinaList, setUsinaList] = useState<IUsinaListItem[]>([]);

  const { data, dataContrato } = props;

  const { state, setObject, updateObject, resetObject } =
    useObjectState<IFormInformacoesOutrasInformacoes>({
      usina: "",
      operadora: "",
      signatario: "",
      comissaoCaptador: "",
      gatewayPagamento: "",
      limiteInjecao: "",
      prefRecebido: "",
      parceiro: "",
      apoioParceiro: "",
      backofficeId: "",
      dataAssinatura: "",
      status: "",
    });

  const getBackoffices = async (): Promise<void> => {
    try {
      const response: AxiosResponse<IBackofficeListItem[]> =
        await colaboradorServices.GetBackoffices();
      setBackoffices(response.data);
    } catch (e) {
      var errorMessage: string = "Houve um erro ao pegar os backoffices.";

      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: errorMessage,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  const getCaptadores = async (): Promise<void> => {
    try {
      const response: AxiosResponse<IBackofficeListItem[]> =
        await captadorServices.GetCaptadores();
      setCaptadores(response.data);
    } catch (e) {
      var errorMessage: string = "Houve um erro ao pegar os backoffices.";

      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: errorMessage,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  const getOperadoras = async () => {
    await operadoraService
      .GetOperadoras()
      .then((response) => {
        setOperadoraList(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getUsinas = async () => {
    await geradorService
      .GetUsinasListItem()
      .then((response) => {
        setUsinaList(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getListsData = async () => {
    await getBackoffices();
    await getCaptadores();
    await getOperadoras();
    await getUsinas();
  };

  useEffect((): void => {
    if (data) {
      getListsData().then((_) => {
        setObject({
          ...state,
          backofficeId: data.backofficeId ?? "",
          parceiro: data.captadorId ?? "",
          operadora: data.operadoraId ?? "",
          signatario: data.email ?? "",
          prefRecebido:
            EnumPreferenciaRecebimentoNumericMap[
              EnumPreferenciaRecebimento.Boleto
            ].toString() ?? "",
        });
      });
    }
  }, [data]);

  useEffect((): void => {
    if (dataContrato) {
      getListsData().then((_) => {
        setObject({
          usina: dataContrato.usinaId ?? "",
          operadora: dataContrato.operadoraId ?? "",
          signatario: dataContrato.signatario?.emailAddress ?? "",
          comissaoCaptador: dataContrato.comissaoCaptador?.toString() ?? "",
          gatewayPagamento: dataContrato.gatewayPagamento?.toString() ?? "",
          limiteInjecao: dataContrato.limiteInjecao?.toString() ?? "",
          prefRecebido: dataContrato.preferenciaRecebimento?.toString() ?? "",
          parceiro: dataContrato.captadorId ?? "",
          apoioParceiro: dataContrato.apoioParceiro ?? "",
          backofficeId: dataContrato.backofficeId ?? "",
          dataAssinatura: dataContrato.dataAssinatura
            ? moment(new Date(dataContrato?.dataAssinatura!)).format(
                "YYYY-MM-DD"
              )
            : "",
          updateSiginatario: {
            id: dataContrato.signatario?.id,
            emailAddress: dataContrato.signatario?.emailAddress ?? "",
            recebeConta: true,
            signatario: true,
          },
          status: dataContrato.status?.toString() ?? "",
        });
      });
    }
  }, [dataContrato]);

  useEffect(() => {
    props.onChange(state);
  }, [state]);

  useEffect(() => {
    updateObject({
      updateSiginatario: {
        ...state.updateSiginatario!,
        emailAddress: state.signatario,
      },
    });
  }, [state.signatario]);

  useEffect(() => {
    if (
      Number(state.gatewayPagamento) !=
      EnumGatewayDePagamentoNumericMap[
        EnumGatewayDePagamento.clienteComLimiteDeInjecao
      ]
    ) {
      updateObject({ limiteInjecao: "" });
    }
  }, [state.gatewayPagamento]);

  return (
    <RegisterCard title="Outras informações">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Usina"
          select
          required
          fullWidth
          value={state.usina}
          onChange={(e) => updateObject({ usina: e.target.value })}
        >
          {Object.values(usinaList).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          fullWidth
          label="Status do contrato"
          value={state.status}
          onChange={(e) => updateObject({ status: e.target.value })}
        >
          {Object.keys(EnumStatusContratoCliente).map((key, index) => (
            <MenuItem
              key={key}
              value={
                EnumStatusGDContratoNumericMap[
                  EnumStatusContratoCliente[
                    key as keyof typeof EnumStatusContratoCliente
                  ]
                ]
              }
            >
              <D.FWStack direction={"row"} spacing={2} alignItems={"center"}>
                <D.Circle
                  color={
                    StatusContratoColorMap[
                      EnumStatusGDContratoNumericMap[
                        EnumStatusContratoCliente[
                          key as keyof typeof EnumStatusContratoCliente
                        ]
                      ]
                    ]
                  }
                ></D.Circle>
                <span>
                  {
                    EnumStatusContratoCliente[
                      key as keyof typeof EnumStatusContratoCliente
                    ]
                  }
                </span>
              </D.FWStack>
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Operadora"
          select
          required
          fullWidth
          value={state.operadora}
          onChange={(e) => updateObject({ operadora: e.target.value })}
        >
          {Object.values(operadoraList).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Signatário"
          required
          fullWidth
          value={state.signatario}
          onChange={(e) => updateObject({ signatario: e.target.value })}
        />
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Comissão captador"
          required
          fullWidth
          value={state.comissaoCaptador}
          onChange={(e) => updateObject({ comissaoCaptador: e.target.value })}
        />
        <TextField
          label="Gateway pagamento"
          required
          fullWidth
          select
          value={state.gatewayPagamento}
          onChange={(e) => updateObject({ gatewayPagamento: e.target.value })}
        >
          {Object.values(EnumGatewayDePagamento).map((value, index) => (
            <MenuItem
              key={value}
              value={EnumGatewayDePagamentoNumericMap[value]}
            >
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Valor máximo de injeção"
          required
          fullWidth
          value={state.limiteInjecao}
          onChange={(e) => updateObject({ limiteInjecao: e.target.value })}
          inputProps={{ readOnly: state.gatewayPagamento != "2" }}
          disabled={
            Number(state.gatewayPagamento) !=
            EnumGatewayDePagamentoNumericMap[
              EnumGatewayDePagamento.clienteComLimiteDeInjecao
            ]
          }
        />
        <TextField
          label="Pref recebimento"
          select
          required
          fullWidth
          value={state.prefRecebido}
          inputProps={{ readOnly: true }}
        >
          {Object.values(EnumPreferenciaRecebimento).map((value, index) => (
            <MenuItem
              key={value}
              value={EnumPreferenciaRecebimentoNumericMap[value]}
            >
              {value}
            </MenuItem>
          ))}
        </TextField>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Parceiro"
          select
          required
          fullWidth
          inputProps={{ readOnly: true }}
          value={state.parceiro}
        >
          {Object.values(captadores).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Apoio parceiro"
          required
          fullWidth
          value={state.apoioParceiro}
          onChange={(e) => updateObject({ apoioParceiro: e.target.value })}
        />
        <TextField
          label="Backoffice"
          select
          required
          fullWidth
          inputProps={{ readOnly: true }}
          value={state.backofficeId}
          onChange={(e) => updateObject({ backofficeId: e.target.value })}
        >
          {Object.values(backoffices).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        {dataContrato && (
          <D.DateTextfield
            type="date"
            label="Data de assinatura"
            fullWidth
            value={state.dataAssinatura}
            onChange={(e) => updateObject({ dataAssinatura: e.target.value })}
          />
        )}
      </D.FWStack>
      {dataContrato !== null && (
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Energia adicional (kWh)"
            required
            value={state.energiaAdicionalKWH}
            fullWidth
            onChange={(e) =>
              updateObject({ energiaAdicionalKWH: e.target.value })
            }
          />
          <TextField
            label="Energia adicional (R$)"
            required
            fullWidth
            value={state.energiaAdicionalReais}
            onChange={(e) =>
              updateObject({ energiaAdicionalReais: e.target.value })
            }
          />
          <D.FWStack></D.FWStack>
          <D.FWStack></D.FWStack>
        </D.FWStack>
      )}
      {/* {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null} */}
    </RegisterCard>
  );
};

export default OutrasInformacoesContratar;
