import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  Modal,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StatusCard from "../../../../components/StatusCard";
import * as D from "../../../../styles/appComponents";
import * as C from "../../Colaborador/Gerador/style";
import TotalCardOrdem from "../../../../components/TotalCardOrdem";
import Datatable from "../../../../components/Datatable";

const OrdemPagamento: React.FC = () => {
  const [page, setPage] = useState(1);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModalSeeMore = () => {
    setOpenModalSeeMore(true);
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
        field: "referente",
        headerName: "Referente",
        flex: 1,
        align: "center",
        renderCell: (params) => (
          <D.GridField>
            <Typography
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              {params.value}
            </Typography>
          </D.GridField>
        ),
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "sigla", headerName: "sigla", flex: 1 },
    { field: "kwh", headerName: "Kwh injetado", flex: 1 },
    { field: "tarifa", headerName: "Desc tarifa", flex: 1 },
    { field: "valor", headerName: "Valor", flex: 1 },
    { field: "saldo", headerName: "Saldo", flex: 1 },
    { 
        field: "conta", 
        headerName: "Conta", 
        flex: 1,
        renderCell: (params) => (
            <D.GridField 
                onClick={() => navigate(`/Cadastro/Ordem-pagamento-conta/${params?.row?.id}`)}
            >
                conta
            </D.GridField>
        )
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => navigate(`/Cadastro/Gerador/Ordem-pagamento?geradorId=${params?.row?.id}`)}>
            Editar
          </D.DefaultGridButton>
        </D.GridField>
      )
    },
  ];

  const rows = [
    {
      id: 9594,
      instalacao: "3000382444",
      referente: "Abril/24",
      gerador: "José Luiz Almeida",
      sigla: "JLA",
      kwh: "1.000,00",
      tarifa: "65,00%",
      valor: "R$ 900,00",
      saldo: "R$ 0,00",
      conta: "conta",
      status: 0,
      outras: "Editar",
    }

  ];

  const columnsModal: GridColDef[] = [
    { field: "id", headerName: "ID" },
    { field: "responsavel", headerName: "Responsavel", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data" },
  ];

  const rowsModal = [
    {
      id: 1,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 2,
      responsavel: "Rebecca de Assis",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 3,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 4,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 1,
      data: "05/11/2013",
    },
    {
      id: 5,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 1,
      data: "05/11/2013",
    },
  ];

  const columnsModalDocument: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    { field: "data", headerName: "Data Upload", flex: 1 },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDownloadButton
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            Baixar
          </C.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
  ];

  const rowsModalDocument = [
    {
      id: 1,
      descricao: "Conta Cemig",
      data: "05/11/2013",
      anexo: 0,
    },
    {
      id: 2,
      descricao: "Documento Pessoal",
      data: "05/11/2013",
      anexo: 0,
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard
                color="yellow"
                text="Aguardando assinatura"
                number={254}
              />
              <TotalCardOrdem
                colorText="green"
                colorTitle="yellow"
                color="yellow"
                title="Valor total"
                text="R$ 4.0000,00"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="green" text="Quitada" number={56} />
              <TotalCardOrdem
                colorText="blue"
                colorTitle="blue"
                color="green"
                title="Energia total injetada"
                text="200.500,00 KWH"
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}

        <D.DataArea $align="right">
          <Datatable
            hasButton={true}
            rows={rows}
            columns={columns}
            pageNumber={1}
            pageSize={3}
            totalPages={10}
            onChangePage={() => {}}
            onChangePageSize={() => {}}
            onSearch={() => {}}
            handleButton={() => navigate(`/Cadastro/Ordem-pagamento`)}
            titleButton={"Inserir nova ordem"}
          />
          <C.LineBreak />
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%" }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  Gerador - 9591
                  <C.ModalTextIcon>
                    <D.Circle color={"green"} />
                    <p>Conectado</p>
                  </C.ModalTextIcon>
                </h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Desconto da tarifa:</label>
                  <p>65,00</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Data da conexão:</label>
                  <p>00/00/0000</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Termino do contrato:</label>
                  <p>03/11/2023</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Celular:</label>
                  <p>(31) 974280912</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>CEP:</label>
                  <p>00000-000</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>UF:</label>
                  <p>UF</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Cidade:</label>
                  <p>Cidade</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Bairro:</label>
                  <p>exemplo do exemplo</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Número:</label>
                  <p>000000</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Logradouro:</label>
                  <p>Exemplo do exemplo</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Complemento:</label>
                  <p>Exemplo do exemplo</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Download anexo proposta</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModalDocument}
                columns={columnsModalDocument}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: rowsModalDocument.length,
                    },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default OrdemPagamento;
