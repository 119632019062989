import { isEnumDeclaration, isEnumMember } from "typescript";

export interface Validate<T>{
    field:keyof T;
    validate: (value:any, model:T, addError:(message:string, ref:string) => void) => void;
}

export interface ErrorMessage{
    ref:string;
    message:string;
}

export abstract class AbstractValidation<T>{
    constructor(){}

    handleValidForm(form: T): boolean {
        if (!form) return true;
    
        const formValues: unknown[] = Object.values(form);

        const fieldsMandatory:boolean = formValues
        .some(value => value !== "" && value !== undefined && value !== null && value !== 0);
        
        const hasValue: boolean = formValues
        .some(value => (typeof value !== null && typeof value !== undefined && typeof value !== "boolean"));
    
        return hasValue && fieldsMandatory;
    }

    errorMessage(model:T): ErrorMessage[] | null | undefined {
        const erros:ErrorMessage[] | null = []
        const validate = this.getValidate()

        const validForm =  this.handleValidForm(model)

        if(validForm){
            validate.forEach((item) => {
                item.validate(model[item.field], model, (message:string, ref:string) => {
                    erros.push({ref, message})
                })
            })
    
            return erros.length === 0? null: erros
        }
    }

    abstract getValidate():Validate<T>[];
}