import { Autocomplete, TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as D from "../../../../../../styles/appComponents";
import { useEffect, useState } from "react";
import * as leadService from "../../../../../../services/api/LeadService";
import * as cooperativaService from "../../../../../../services/api/CooperativaService";
import { IFullLead } from "../../../../../../models/Lead";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import { IDadosPessoaisProposta } from "../../../../../../models/GDProposta";
import { ICooperativaListItem } from "../../../../../../models/Cooperativa";

interface IDadosPessoaisGerarPropostaProps {
  leadId: string;
  onSendData: (data: IDadosPessoaisProposta) => void;
}

const DadosPessoaisGerarProposta: React.FC<
  IDadosPessoaisGerarPropostaProps
> = ({ leadId, onSendData }) => {
  const [nome, setNome] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [celular, setCelular] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [leadData, setLeadData] = useState<IFullLead>();

  const [cooperativaList, setCooperativaList] = useState<
    ICooperativaListItem[]
  >([]);

  const [cooperativa, setCooperativa] = useState<string>("");

  useEffect(() => {
    const data: IDadosPessoaisProposta = {
      nome: nome,
      cpf: cpf,
      celular: celular,
      email: email,
      cooperativaId: cooperativa,
    };

    onSendData(data);
  }, [nome, cpf, celular, email, cooperativa]);

  useEffect(() => {
    leadService
      .GetLeadById(leadId)
      .then((response) => {
        setLeadData(response.data);
        setNome(response.data.nome);
        setCelular(response.data.celular);
        setEmail(response.data.email ?? "");
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });

    cooperativaService
      .GetCooperativas()
      .then((response) => {
        setCooperativaList(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }, []);

  return (
    <RegisterCard title="Dados Pessoais">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Nome"
          required
          fullWidth
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
        <TextField
          label="CPF"
          fullWidth
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
        <TextField
          label="Celular"
          required
          fullWidth
          value={celular}
          onChange={(e) => setCelular(e.target.value)}
        />
        <TextField
          label="Email"
          required
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          value={leadData ? leadData.nomeCaptador : ""}
          label="Captador"
          required
          fullWidth
        />
        <Autocomplete
          options={cooperativaList}
          fullWidth
          noOptionsText="Nenhuma cooperativa encontrada"
          getOptionLabel={(option) => option.nome}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.nome}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Cooperativa" required />
          )}
          defaultValue={null}
          onChange={(event, newValue) => {
            setCooperativa(newValue ? newValue.id : "");
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <TextField
          value={leadData ? leadData.nomeBackoffice : ""}
          label="Backoffice"
          required
          fullWidth
        />
      </D.FWStack>
    </RegisterCard>
  );
};

export default DadosPessoaisGerarProposta;
