import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GridColDef } from "@mui/x-data-grid";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import Datatable from "../../../components/Datatable";
import StatusCard from "../../../components/StatusCard";
import * as A from "../../../components/StatusStyle/Style";
import TotalCard from "../../../components/TotalCard";
import { IBackofficeListItem } from "../../../models/Colaborador/Colaborador";
import { IFormCreateGeradorPessoaJuridica } from "../../../models/Gerador/Cadastro/Gerador";
import { GeradorResponse } from "../../../models/Gerador/GeradorResponse";
import { StatusUsina } from "../../../models/Gerador/StatusContrato";
import { UsinaTipoPessoaJuridica } from "../../../models/Gerador/Usina";
import { ICreateLog, IFullLog, ILog, IUpdateLog } from "../../../models/Log";
import { GetBackoffices } from "../../../services/api/ColaboradorService";
import {
  GerarRelatorio,
  GetAll,
  GetOnePessaoFisica,
  GetOnePessaoJuridica,
  GetStatusUsina,
} from "../../../services/api/GeradorService";
import { CreateLog, GetLogAll, GetLogById, GetLogsByItemId, UpdateLog } from "../../../services/api/LogService";
import * as D from "../../../styles/appComponents";
import { toastMessage } from "../../../utils/toastMessage";
import * as C from "../Colaborador/Gerador/style";
import { ModalInfoGerador } from "./Components/ModalInfoGerador";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import { ModalQuestionFormType } from "./Components/ModalQuestionFormType";
import { EnumTipoPessoa } from "../../../enums/Gerador/EnumTipoPessoa.enum";
import { formatValueFloatInString } from "../../../utils/moneyFormatter";
import { Checkbox } from "@mui/material";

const Gerador: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isNotSendClickSign, setIsNotSendClickSign] = useState<boolean>(false)
  const [is, setSearch] = useState<string>("")
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [geradorId, setGeradorId] = useState<string | null>("");
  const [tipoPessoa, setTipopessoa] = useState<string>("");
  const [statusUsina, setStatusUsina] = useState<StatusUsina | undefined>(
    undefined
  );
  const [load, setLoad] = useState<boolean>(false)
  const [usinas, setUsinas] = useState<GeradorResponse[]>([]);
  const [usina, setUsina] = useState<UsinaTipoPessoaJuridica | undefined>(undefined);
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [openModalModalQuestionFormType, setOpenModalModalQuestionFormType] = useState<boolean>(false)
  const [logs, setLogs] = useState<ILog[]>([])
  const navigate = useNavigate();

  const [ searchParams, setSearchParams ] = useSearchParams();
  const update = searchParams.get("update")

  const handleCloseModalQuestionFormType = () => {
    setOpenModalModalQuestionFormType(false);
  };

  const redirectTypeForm = (type:string) => {
    navigate(`/Cadastro/Gerador?tipoFormulario=${type}`);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  const createNewLog = async (data:ICreateLog): Promise<void> => {
    searchParams.delete('update')
    navigate({
      pathname: `/Colaborador/Gerador`,
      search: `?update=${true}`
    })

    if (data.assunto === "") {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Assunto não pode ser vazio.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: null,
      gdPropostaId: null,
      gdContratoId:null,
      usinaId: geradorId || "",
      assunto: data?.assunto,
      descricao: data?.descricao,
      anexo: data?.anexo,
    };

    try {

      await CreateLog(historicoData)
      searchParams.delete('update')
      setSearchParams(searchParams)
      toastMessage("success","Log criado com sucesso!")

      
    } catch (error) {
      searchParams.delete('update')
      setSearchParams(searchParams)
      toastMessage("error","Erro ao criar o log")
    }
  };

  const loadDashBoard = async () => {
    try {
      const { data } = await GetStatusUsina();
      await setStatusUsina(data);
      await setLoading(false);
    } catch (error) {
      toastMessage("error", "Erro ao listar o dashBoard");
    }
  };

  const editLog = async (id:string, data:ICreateLog): Promise<void> => {
    searchParams.delete('update')
    navigate({
      pathname: `/Colaborador/Gerador`,
      search: `?update=${true}`
    })

    var newData: IUpdateLog = {
      id: id!,
      assunto: data.assunto,
      descricao: data.descricao,
      anexo: data.anexo || null,
    };

    if (newData?.assunto == "") {
      toastMessage("warning","Assunto/descrição não podem ser vazios.");
      return;
    }

    try {
      await UpdateLog(newData)
      toastMessage("success","Log editado com sucesso!")
      searchParams.delete('update');
      setSearchParams(searchParams);
      
    } catch (error) {
      toastMessage("error","Erro ao editar os dados")
      searchParams.delete('update');
      setSearchParams(searchParams);
    }
  }

  useEffect(() => {
    if(geradorId)
      loadLogs(geradorId)

  }, [update])

  useEffect(() => {
    const loadDashBoard = async () => {
      try {
        const {data} = await GetStatusUsina();
        setStatusUsina(data);

      } catch (error) {
        toastMessage("error", "Erro ao listar o dashBoard")
      }
    };
    loadDashBoard();

    const loadBackOffices = async () => {
      try {
        const response:AxiosResponse<IBackofficeListItem[]> = await GetBackoffices();
        setBackoffices(response.data)
        
      } catch (error) {
        toastMessage("error","Erro ao listar backoffices")
      }
    }
    loadBackOffices();
  }, []);

  useEffect(() => {
    loadUsinas();
  }, [page, pageSize, isNotSendClickSign]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const loadUsina = async () => {
      try {
        if (geradorId) {
          setLoad(true);
          const { data } = tipoPessoa === "pessoaJuridica"? 
          await GetOnePessaoJuridica(geradorId): 
          await GetOnePessaoFisica(geradorId);

          setLoad(false);
          setUsina(data);
          setOpenModal(true);
        }
      } catch (error) {
        toastMessage("error", "Erro ao listar uma usina");
        setLoad(false);
      }
    };
    loadUsina();
  }, [geradorId, tipoPessoa]);

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      maxWidth: 20,
      align: "center",
      renderCell: (params: any) => (
        <D.GridField
          onClick={() =>
            navigate(`/edicao/Gerador/${params?.row?.id}?tipoFormulario=${params?.row?.tipoPessoa}`)
          }
        >
          {params.value}
        </D.GridField>
      ),
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "gerador",
      headerName: "Gerador",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "sigla",
      headerName: "Sigla",
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "kwhM",
      headerName: "Kwh medio",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "kwhA",
      headerName: "Kwh alvo",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "data",
      headerName: "Data assinatura",
      flex: 1,
      renderCell: (params: any) => (
        <D.GridField>{params.value?.substr(0, 10) ?? ""}</D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      width: 70,
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          <A.StatusStyle status={params?.row?.status}></A.StatusStyle>
          {
          !params?.row?.contratoPreenchido? 
          "*": 
          <span style={{ width:"10px" }}></span>
          }
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpenModal(params?.row?.id,params?.row?.tipoPessoa)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = (usinas || []).map((usina) => {
    return {
      id: usina?.id,
      tipoPessoa: EnumTipoPessoa[usina.tipoPessoa],
      idReferencial: usina?.idReferencial,
      instalacao: usina?.instalacao,
      gerador: usina?.gerador,
      sigla: usina?.sigla,
      kwhM: usina?.kwhMedio ? `${formatValueFloatInString(parseFloat(usina?.kwhMedio))} kwh` : `${0} kwh`,
      kwhA: usina?.kwhAlvo ? `${formatValueFloatInString(parseFloat(usina?.kwhAlvo))} kwh` : `${0} kwh`,
      data: usina?.dataAssinatura ?? "Contrato sem assinatura",
      status: usina.status,
      contratoPreenchido: usina?.contratoPreenchido,
      outras: "ver mais",
    };
  });

  const loadLogs = async (id?:string) => {
    try {
      if(id){
        const { data } = await GetLogsByItemId(id)
        setLogs(data)
      }

    } catch (error) {
      toastMessage("error","Erro ao listar logs")
    }
  }

  const handleOpenModal = (id: string, tipoPessoa:EnumTipoPessoa) => {
    searchParams.delete('update')
    setSearchParams(searchParams)

    setGeradorId(id)
    setTipopessoa(tipoPessoa.toString())
    setOpenModal(true);
    loadLogs(id)
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangeIsIsign = (event:any) => {
    setIsNotSendClickSign(event?.target?.checked)
  }

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const loadUsinas = async (queryString?: string | null) => {
    try {
      const { data } = await GetAll(pageSize, page, queryString, isNotSendClickSign);
      setTotalPages(data?.totalPages);
      setUsinas(data?.data);
      await loadDashBoard();

    } catch (error) {
      toastMessage("error", "Erro ao listar as usinas");
      
    }
  };

  const handleSearch = async (value: string): Promise<void> => {
    setSearch(value)
    await loadUsinas(value);
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
    setGeradorId(null);
  };

  const gerarRelatorio = async (): Promise<void> => {
    try {
      const { data }: any = await GerarRelatorio();

      const blob = data;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `gerador_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (error) {
      toastMessage("error", "Erro ao gerar relatório");
    }
  };

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <ModalInfoGerador
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            usina={usina}
            createNewLog={createNewLog}
            logs={logs}
            editLog={editLog}
            load={load}
          />
          <ModalQuestionFormType 
            openModal={openModalModalQuestionFormType} 
            handleCloseModal={handleCloseModalQuestionFormType}
            redirectTypeForm={redirectTypeForm}
          />
          <C.Title onClick={() => handleStatusOpen()}>
            Status
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>

          {(!isMobile || (statusOpen && isMobile)) && (
            <C.StatusArea>
              <C.StatusWrapper>
                <StatusCard
                  color="yellow"
                  text="Aguardando assinatura"
                  number={statusUsina?.aguardandoAssinatura || 0}
                />
                <TotalCard
                  color="yellow"
                  title="Valor total de kwh"
                  text={`${formatValueFloatInString(statusUsina?.aguardandoAssinaturaKwh)} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="blue"
                  text="Contrato assinado"
                  number={statusUsina?.contratoAssinado || 0}
                />
                <TotalCard
                  color="blue"
                  title="Valor total de kwh"
                  text={`${formatValueFloatInString(statusUsina?.contratoAssinadoValorTotalKwh)} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="purple"
                  text="Troca de titularidade"
                  number={statusUsina?.emTrocaDeTitularidade || 0}
                />
                <TotalCard
                  color="purple"
                  title="Valor total de kwh"
                  text={`${formatValueFloatInString(statusUsina?.emTrocaDeTitularidadeValorTotalKwh)} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="green"
                  text="Conectado"
                  number={statusUsina?.conectado || 0}
                />
                <TotalCard
                  color="green"
                  title="Valor total de kwh"
                  text={`${formatValueFloatInString(statusUsina?.conectadoValorTotalKwh)} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="red"
                  text="Aguradando assinatura distrato"
                  number={statusUsina?.aguardadndoAssinaturaDistrato || 0}
                />
                <TotalCard
                  color="red"
                  title="Valor total de kwh"
                  text={`${formatValueFloatInString(statusUsina?.aguardandoAssinaturaDistratoValorTotalKwh)} kwh`}
                  evolutionNumber="+8%"
                  evolution={true}
                />
              </C.StatusWrapper>
            </C.StatusArea>
          )}
 
          <D.CheckboxWrapper>
            <Checkbox
                onChange={handleChangeIsIsign}
              />
              <p>* Contrato não enviado para Click Sign</p>
          </D.CheckboxWrapper>


          <D.DataArea $align="right">
            <Datatable
              handleButton={() => setOpenModalModalQuestionFormType(true)}
              hasButton={true}
              titleButton="Novo Gerador"
              columns={columns}
              rows={rows}
              pageNumber={page}
              pageSize={pageSize}
              onChangePage={(e: number) => handleChangePage(e)}
              onChangePageSize={(e: number) => handleChangePageSize(e)}
              totalPages={totalPages}
              onSearch={handleSearch}
            />
            <C.LineBreak />
            <C.ExportButton
              variant="contained"
              onClick={gerarRelatorio}
              startIcon={
                <img
                  style={{ width: "20px", color: "white", fill: "white" }}
                  src={ExcelIcon}
                  alt="excelIcon"
                />
              }
            >
              Gerar relatório
            </C.ExportButton>
          </D.DataArea>
        </C.Container>
      )}
    </>
  );
};

export default Gerador;
