import { Chip } from "@mui/material";
import * as C from "./style";
import { ColorType } from "../../types/ColorType";
import * as D from "../../styles/appComponents";

interface StatusCardProps {
  color: ColorType;
  text: string;
  number: number | undefined;
}

const StatusCard: React.FC<StatusCardProps> = ({ color, text, number }) => {
  return (
    <C.Card>
      <C.Left>
        <D.Circle color={color}></D.Circle>
        <C.Text>{text}</C.Text>
      </C.Left>
      <Chip label={number} />
    </C.Card>
  );
};

export default StatusCard;
