import * as C from "./style";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { ColorType } from "../../types/ColorType";

interface StatusCardProps {
  color: ColorType;
  title: string;
  text: string;
  evolutionNumber: string;
  evolution: boolean;
  style?: object;
}

const TotalCard: React.FC<StatusCardProps> = ({
  color,
  title,
  text,
  evolutionNumber,
  evolution,
  style,
}) => {
  return (
    <C.Card style={style}>
      <C.Top>
        <C.Bar color={color}></C.Bar>
        <C.TopTextArea>
          <C.Title>{title}</C.Title>
          <C.Text>{text}</C.Text>
        </C.TopTextArea>
      </C.Top>
      {/* <C.Bottom>
        <C.BottomText $evolution={evolution}>
          {evolution ? (
            <ArrowUpwardRoundedIcon />
          ) : (
            <ArrowDownwardRoundedIcon />
          )}
          {evolutionNumber} Desde o mês passado
        </C.BottomText>
      </C.Bottom> */}
    </C.Card>
  );
};

export default TotalCard;
