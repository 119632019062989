import { FC, useReducer } from "react";
import { EnumCupomBoasVindas, EnumCupomBoasVindasNumericMap } from "../../../../../../enums/EnumCupomBoasVindas.enum";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as C from "../style";
import * as D from "../../../../../../styles/appComponents";
import { MenuItem, TextField } from "@mui/material";

enum FormDadosAcesso{
    LOGIN="LOGIN",
    SENHA="SENHA",
    CUPOMBOASVINDAS="CUPOMBOASVINDAS",
    EMISSORNOTAFISCAL="EMISSORNOTAFISCAL",
    LINKCAPTADOR="LINKCAPTADOR"
}

export interface IFormDadosAcesso{
    login:string | undefined;
    senha:string | undefined;
    cupomboasvindas:EnumCupomBoasVindas | undefined;
    emissornotafiscal:string | undefined;
    linkcaptador: string | undefined;
}

type PropsDadosAcesso = {
    onSave: (data:IFormDadosAcesso) => void;
    onUpdate: (data:IFormDadosAcesso) => void;
    onChange: (data:IFormDadosAcesso) => void;
    data?: object | null;
}

export const DadosAcesso:FC<PropsDadosAcesso> = ({onSave,onUpdate,onChange}) => {
    const sendOnSave = (): void => onSave(state);
    const sendOnUpdate = (): void => onUpdate(state);
    const sendOnChange = (update: IFormDadosAcesso): void => onChange(update);

    const setDataForm = (state: IFormDadosAcesso, action: {
        type: FormDadosAcesso;
        payload: string;
      }): IFormDadosAcesso => {
        const change = { ...state, [action.type.toLowerCase()]: action.payload };
        sendOnChange(change);
        return change;
      };

    const [state, dispatch] = useReducer(setDataForm, {
        login: "",
        senha: "",
        cupomboasvindas: undefined,
        emissornotafiscal: undefined,
        linkcaptador:""
    });

    const handleInputChange = (field: FormDadosAcesso) => (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: field, payload: event.target.value });
    };

    return(
        <>
        <RegisterCard title="Dados de acesso">
            <C.FWStack direction={"row"} spacing={2}>
            <TextField
                label="Login"
                sx={{ width: "80%" }}
                value={state?.login}
                onChange={handleInputChange(FormDadosAcesso.LOGIN)}
            />
            <TextField
                label="Senha"
                sx={{ width: "80%" }}
                value={state?.senha}
                onChange={handleInputChange(FormDadosAcesso.SENHA)}
            />
            <TextField
                select
                label="Cupom de boas vindas destinado para?"
                fullWidth
                value={state?.cupomboasvindas}
                onChange={handleInputChange(FormDadosAcesso.CUPOMBOASVINDAS)}
            >
                {Object.values(EnumCupomBoasVindas).map((value) => (
                <MenuItem
                    key={value}
                    value={EnumCupomBoasVindasNumericMap[value]}
                >
                    {value}
                </MenuItem>
                ))}
            </TextField>
            <TextField
                select
                label="Emissor de nota fiscal?"
                sx={{ width: "70%" }}
                value={state?.emissornotafiscal}
                onChange={handleInputChange(FormDadosAcesso.EMISSORNOTAFISCAL)}
            >
                <MenuItem key={1} value={1}>
                Sim
                </MenuItem>
                <MenuItem key={0} value={0}>
                Não
                </MenuItem>
            </TextField>
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
            <TextField
                label="Link do parceiro"
                fullWidth
                InputProps={{
                startAdornment: (
                    <D.StartTextTextfield>
                    wattwise.app.br/Convida/
                    </D.StartTextTextfield>
                ),
                }}
                value={state?.linkcaptador}
                onChange={handleInputChange(FormDadosAcesso.LINKCAPTADOR)}
            />
            </C.FWStack>
        </RegisterCard>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <D.ContainedButton type="submit">Cadastrar</D.ContainedButton>
        </C.FWStack>
        </>
    )
}