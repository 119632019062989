import { TextField } from "@mui/material";
import { useEffect, useReducer } from "react";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import { IFormInformacoesEndereco } from "../../../../../../../../models/Contratar";
import { IFullGDProposta } from "../../../../../../../../models/GDProposta";
import * as D from "../../../../../../../../styles/appComponents";
import { useObjectState } from "../../../../../../../../hooks/useObjectState";
import { IFullGDContratoCliente } from "../../../../../../../../models/GDContrato";

type PropsEnderecoContratar = {
  type: string;
  data?: IFullGDProposta;
  dataContrato?: IFullGDContratoCliente;
  onChange: (data: IFormInformacoesEndereco) => void;
};

const EnderecoContratar = (props: PropsEnderecoContratar) => {
  const { dataContrato } = props;

  const { state, setObject, updateObject, resetObject } =
    useObjectState<IFormInformacoesEndereco>({
      cep: "",
      uf: "",
      cidade: "",
      bairro: "",
      logradouro: "",
      numero: "",
      complemento: "",
    });

  useEffect(() => {
    props.onChange(state);
  }, [state]);

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        cep: dataContrato.cep ?? "",
        bairro: dataContrato.bairro ?? "",
        cidade: dataContrato.cidade ?? "",
        complemento: dataContrato.complemento ?? "",
        logradouro: dataContrato.logradouro ?? "",
        numero: dataContrato.numero ?? "",
        uf: dataContrato.uf ?? "",
      });
    }
  }, [dataContrato]);

  return (
    <RegisterCard title="Endereço">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="CEP"
          required
          fullWidth
          value={state.cep}
          onChange={(e) => updateObject({ cep: e.target.value })}
        />
        <TextField
          label="UF"
          required
          fullWidth
          value={state.uf}
          onChange={(e) => updateObject({ uf: e.target.value })}
        />
        <TextField
          label="Cidade"
          required
          fullWidth
          value={state.cidade}
          onChange={(e) => updateObject({ cidade: e.target.value })}
        />
        <TextField
          label="Bairro"
          required
          fullWidth
          value={state.bairro}
          onChange={(e) => updateObject({ bairro: e.target.value })}
        />

        <TextField
          label="Complemento"
          required
          fullWidth
          value={state.complemento}
          onChange={(e) => updateObject({ complemento: e.target.value })}
        />

        <TextField
          label="Número"
          required
          fullWidth
          value={state.numero}
          onChange={(e) => updateObject({ numero: e.target.value })}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Logradouro"
          required
          sx={{ width: "50%" }}
          value={state.logradouro}
          onChange={(e) => updateObject({ logradouro: e.target.value })}
        />
      </D.FWStack>
      {/* {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null} */}
    </RegisterCard>
  );
};

export default EnderecoContratar;
