import { IDadosUsinaPessoaJuridica } from "../../../../models/Gerador/Cadastro/DadosUsina";
import { AbstractValidation, Validate } from "../../../../utils/AbstractValidation";

export class DadosUsinaValidate extends AbstractValidation<IDadosUsinaPessoaJuridica>{
    getValidate(): Validate<IDadosUsinaPessoaJuridica>[] {
        return[
            {
                field:"razaosocialnome",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Razão social/nome deve ser informada","razaosocialnome")
                }
            },
            {
                field:"razaosocialnome",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string, ref:string) => void) => {
                    if(value?.length > 150) addError("A Razão social Não pode ultrapssar 150 caracteres","razaosocialnome")
                }
            },
            {
                field:"cpfcnpj",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Cpf/Cnpj deve ser informada","cpfcnpj")
                }
            },
            {
                field:"instalacao",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Instalação deve ser informada","instalacao")
                }
            },
            {
                field:"sigla",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Sigla deve ser informada","sigla")
                }
            },
            {
                field:"sigla",
                validate: (value, model:IDadosUsinaPessoaJuridica, addError:(message:string, ref:string) => void) => {
                    if(value?.length > 2) addError("A Sigla deve ter no máximo 2 caracteres","sigla")
                }
            }
        ];
    }
}