import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
 *{
   box-sizing: border-box;
   font-family: "Roboto", sans-serif;

   &::-webkit-scrollbar-thumb {
        background: #dad7d7;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    &::-webkit-scrollbar-track {
        background-color: #F4F4F4;        
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    &::-webkit-scrollbar {
        width: 6px;        
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }
 }

 body {
    box-sizing: border-box;
    margin: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
 }

 #root {
   display: flex;
   width: 100%;
   height: 100%;
}

.swal2-container {
  z-index: 2100 !important;
}
`;
