export enum EnumTipoPessoa{
    pessoaFisica=0,
    pessoaJuridica=1
}

export const enumTipoPessoaEnumMap = [
    {
        name: "Pessoa Física",
        value: EnumTipoPessoa.pessoaFisica
    },
    {
        name: "Pessoa Jurídica",
        value: EnumTipoPessoa.pessoaJuridica
    }
];