import React from "react";
import CurrencyInput from "react-currency-input-field";

export const CurrencyInputWrapper = React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<typeof CurrencyInput>>(function CurrencyInputWrapper(props, ref) {
  
    return (
      <CurrencyInput
        {...props}
        ref={ref}

      />
    );
  });