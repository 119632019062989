import * as C from "./style";
import * as D from "../../../styles/appComponents";

export const SkeletonSectionForm = () => {
    return(
        <C.Container>
            <C.StatusArea>
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
            </C.StatusArea>
            <C.StatusArea>
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
                <C.SkeletonStatusWrapper animation="wave" variant="rounded" />
            </C.StatusArea>
        </C.Container>
    )
};