import { Button, IconButton, Select, Stack, TextField } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: #222222;

  @media (max-width: 1034px) {
    cursor: pointer;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 18%;
  gap: 16px;

  @media (max-width: 1024px) {
    flex-basis: 48%;
  }
`;

export const CardDataAreaMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

export const CardDataMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  flex-basis: 48%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 6px 6px rgba(0, 0, 0, 0.06);
  padding: 15px 10px;
`;

export const CardDataLineMobile = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

export const CardDataItemMobile = styled.div<{ $center?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  white-space: nowrap;
  gap: 2px;
  align-items: ${(props) => (props.$center ? "center" : "flex-start")};

  label {
    color: #222;
    font-size: 14px;
    font-weight: 300;
  }

  p {
    margin: 0;
    color: #222;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ReportsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const SelectArea = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  @media (max-width: 1024px) {
    flex-basis: 48%;
  }
`;

export const ReportsLabel = styled.label`
  color: #3e3c3c;
  font-size: 14px;
  font-weight: 400;
`;

export const ReportsSelect = styled(Select)`
  width: 100%;
  text-align: start;
`;

export const ExportButton = styled(Button)`
  width: auto;
  background-color: #107c41;
  color: white;
  margin-top: 10px;

  &:hover {
    background-color: #185c37;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const LineBreak = styled.hr`
  height: 2px;
  width: 100%;
  opacity: 0.3;
  background: #222222;
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  height: 50%;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }
`;

export const ModalCard = styled.form`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  box-shadow: 6px 6px 6px #0000000f;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;

  h4 {
    margin: 0;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    color: #222;
    font-size: 14px;
    font-weight: 300;
  }

  label {
    margin: 0;
    color: #222;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: #222222;
    font-size: 20px;
    font-weight: 400;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  color: gray;
  aspect-ratio: 1/1;
`;

export const ModalStatusArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
`;

export const ModalStatus = styled.div`
  display: flex;
  flex: 1;
  flex-basis: auto;
  gap: 10px;
`;

export const ModalInputsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

export const ModalInput = styled(TextField)`
  flex: 1;
  flex-basis: 49%;
`;

export const ModalButtonsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

export const ModalDocumentButton = styled(Button)`
  background-color: #144fe7;
  color: white;
  width: 100%;

  &:hover {
    background-color: #1244c4;
  }

  @media (max-width: 768px) {
    flex-basis: 48%;
  }
`;

export const ModalHistoricButton = styled(Button)`
  background-color: #f6e948;
  color: white;
  flex: 1;
  flex-basis: 23%;

  &:hover {
    background-color: #e6d940;
  }

  @media (max-width: 768px) {
    flex-basis: 48%;
  }
`;

export const ModalProposalButton = styled(Button)`
  background-color: #107c41;
  color: white;
  flex: 1;
  flex-basis: 23%;

  &:hover {
    background-color: #0f703b;
  }

  @media (max-width: 768px) {
    flex-basis: 48%;
  }
`;

export const ModalSaveButton = styled(Button)`
  background-color: #d24293;
  color: white;
  flex: 1;
  flex-basis: 23%;

  &:hover {
    background-color: #bf3b86;
  }

  @media (max-width: 768px) {
    flex-basis: 48%;
  }
`;

export const ModalPaginationArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const VisuallyHiddenInput = styled.input`
  display: none;
`;

export const DefaultColorText = styled.span`
  color: #bcbcbc;
`;

export const FWStack = styled(Stack)`
  width: 100%;
  transition: all ease 0.2s;
`;

export const ContainedButton = styled.button<{
    $color?:
      | "blue"
      | "pink"
      | "lightpink"
      | "yellow"
      | "green"
      | "purple"
      | "red"
      | "orange", $width?:string | null}>`
    border-radius: 10px;
    border: 2px solid ${({ $color }) => {
        switch ($color) {
            case "blue":
              return "#144FE7";
            case "pink":
              return "#D24293";
            case "lightpink":
              return "#F36A7B";
            case "yellow":
              return "#F6A648";
            case "green":
              return "#0bb33a";
            case "purple":
              return "#540c74";
            case "red":
              return "#fc0d0d";
            case "orange":
              return "#f6a648";
            default:
              return "#d24293";
          }
      }};
    color: white;
    background-color: ${({ $color }) => {
        switch ($color) {
            case "blue":
                return "#144FE7";
            case "pink":
                return "#D24293";
            case "lightpink":
                return "#F36A7B";
            case "yellow":
                return "#F6A648";
            case "green":
                return "#0bb33a";
            case "purple":
                return "#540c74";
            case "red":
                return "#fc0d0d";
            case "orange":
                return "#f6a648";
            default:
                return "#d24293";
            }
    }};
    width: ${({$width}) => $width};
    cursor: pointer;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
    &:hover {
        background-color: white;
        color: ${({ $color }) => {
            switch ($color) {
                case "blue":
                  return "#144FE7";
                case "pink":
                  return "#D24293";
                case "lightpink":
                  return "#F36A7B";
                case "yellow":
                  return "#F6A648";
                case "green":
                  return "#0bb33a";
                case "purple":
                  return "#540c74";
                case "red":
                  return "#fc0d0d";
                case "orange":
                  return "#f6a648";
                default:
                  return "#d24293";
              }
        }};
    }
  `;

  export const DefaultGridButton = styled.button<{
    $color?:
    | "blue"
    | "pink"
    | "lightpink"
    | "yellow"
    | "green"
    | "purple"
    | "red"
    | "orange", $width?:string | null}>`
  border-radius: 10px;
  border: 2px solid ${({ $color }) => {
    switch ($color) {
        case "blue":
          return "#144FE7";
        case "pink":
          return "#D24293";
        case "lightpink":
          return "#F36A7B";
        case "yellow":
          return "#F6A648";
        case "green":
          return "#0bb33a";
        case "purple":
          return "#540c74";
        case "red":
          return "#fc0d0d";
        case "orange":
          return "#f6a648";
        default:
          return "#d24293";
      }
  }};
  color: ${({ $color }) => {
    switch ($color) {
        case "blue":
          return "#144FE7";
        case "pink":
          return "#D24293";
        case "lightpink":
          return "#F36A7B";
        case "yellow":
          return "#F6A648";
        case "green":
          return "#0bb33a";
        case "purple":
          return "#540c74";
        case "red":
          return "#fc0d0d";
        case "orange":
          return "#f6a648";
        default:
          return "#d24293";
      }
  }};
  background-color: white;
  width: ${({$width}) => $width};
  cursor: pointer;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 14px;
  &:hover {
    background-color: ${({ $color }) => {
        switch ($color) {
            case "blue":
              return "#144FE7";
            case "pink":
              return "#D24293";
            case "lightpink":
              return "#F36A7B";
            case "yellow":
              return "#F6A648";
            case "green":
              return "#0bb33a";
            case "purple":
              return "#540c74";
            case "red":
              return "#fc0d0d";
            case "orange":
              return "#f6a648";
            default:
              return "#d24293";
          }
    }};
    color: white;
  }
`;