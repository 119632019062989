import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import { Chip, MenuItem, TextField } from "@mui/material";
import { EnumTipoChavePix } from "../../../../../enums/Gerador/EnumTipoChavePix.enum";
import { IInformacaoContrato } from "../../../../../models/Gerador/Cadastro/InformacaoContrato";
import { FC, RefObject, useEffect, useState } from "react";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { EnumTipoPessoa, enumTipoPessoaEnumMap } from "../../../../../enums/Gerador/EnumTipoPessoa.enum";
import { EnumTipoTarifa } from "../../../../../enums/Gerador/EnumTipoTarifa.enum";
import {
  EnumStatusContratoGerador,
  enumStatusContratoMap,
} from "../../../../../enums/Gerador/EnumStatusContratoGerador.enum";
import { EnumMedidaCorrente } from "../../../../../enums/Gerador/EnumMedidaCorrente.enum";
import { EnumTipoConexao } from "../../../../../enums/Gerador/EnumTipoConexao.enum";
import { UsinaTipoPessoaJuridica } from "../../../../../models/Gerador/Usina";
import { IOperadoraListItem } from "../../../../../models/Operadora";
import ScroolRef from "../../../../../components/ScrollRef";
import CurrencyInput from "react-currency-input-field";
import { MaskCurrencyInput } from "../../../../../components/MakCurrencyInput";
import { calcValues, formatValueFloatInString, formatValues, formatValueStringInFloat, numberForExtensive } from "../../../../../utils/moneyFormatter";
import { SkeletonSectionForm } from "../../../../../components/SkeletonLoads/SectionForm";
import { iResponsavelIndicacao } from "../../../../../models/Gerador/Cadastro/ResponsavelIndicacao";

export enum InformacaoContratoEnum {
  TIPOPESSOA = "TIPOPESSOA",
  OPERADOR = "OPERADOR",
  TIPOTARIFA = "TIPOTARIFA",
  PORCENTAGEMDESCONTOTARIFA = "PORCENTAGEMDESCONTOTARIFA",
  TARIFAVIGENTECONTRATO = "TARIFAVIGENTECONTRATO",
  TARIFAGERADOR = "TARIFAGERADOR",
  POTENCIA = "POTENCIA",
  POTENCIAEXTENSO = "POTENCIAEXTENSO",
  MEDIDACORRENTE = "MEDIDACORRENTE",
  PERFORMACEALVO = "PERFORMACEALVO",
  CUSTOFIXO = "CUSTOFIXO",
  CUSTOFIXOEXTENSO = "CUSTOFIXOEXTENSO",
  VIGENCIACONTRATUAL = "VIGENCIACONTRATUAL",
  DATALIMITECONEXAO = "DATALIMITECONEXAO",
  TIPOCONEXAO = "TIPOCONEXAO",
  STATUSCONTRATOGERADOR = "STATUSCONTRATOGERADOR",
  DATAASSINATURACONTRATO = "DATAASSINATURACONTRATO",
  UUIDCONTRATO = "UUIDCONTRATO",
  AGENCIARESPONSAVELINDICACAO = "AGENCIARESPONSAVELINDICACAO",
  CONTARESPONSAVELINDICACAO = "CONTARESPONSAVELINDICACAO",
  FAVORECIDORESPONSAVELINDICACAO = "FAVORECIDORESPONSAVELINDICACAO",
  BANCORESPONSAVELINDICACAO = "BANCORESPONSAVELINDICACAO",
  CNPJFAVORECIDORESPONSAVELINDICACAO = "CNPJFAVORECIDORESPONSAVELINDICACAO",
  CHAVEPIXRESPONSAVELINDICACAO = "CHAVEPIXRESPONSAVELINDICACAO",
  TIPOCHAVEPIXRESPONSAVELINDICACAO = "TIPOCHAVEPIXRESPONSAVELINDICACAO",
}

export interface PropsInformacaoContrato {
  onSave: (data: IInformacaoContrato) => void;
  onChange: (data: IInformacaoContrato) => void;
  onUpdate: (data: IInformacaoContrato) => void;
  onGenerate: (data: IInformacaoContrato) => void;
  onDistrato: (data: IInformacaoContrato) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  operadoras: IOperadoraListItem[];
  children: any;
  geradorId: string | null | undefined;
  usina: UsinaTipoPessoaJuridica | undefined;
  scrollRef: RefObject<HTMLDivElement> | null;
  uuidContratoGenerated: string | null;
  generateScroll: boolean;
  buttonSaveGenerate: boolean;
  tipoFormulario: string | null;
  load: { status:boolean, section:string },
  responsavelIndicacaos: iResponsavelIndicacao[] | undefined;
}

export const InformacaoContrato: FC<PropsInformacaoContrato> = ({
  onSave,
  onChange,
  onUpdate,
  onDistrato,
  onGenerate,
  buttonSave,
  fieldErros,
  operadoras,
  children,
  geradorId,
  usina,
  scrollRef,
  uuidContratoGenerated,
  generateScroll,
  buttonSaveGenerate,
  tipoFormulario,
  load,
  responsavelIndicacaos
}) => {
  const formInformacaoContrato: IInformacaoContrato = {
    tipopessoa: EnumTipoPessoa[tipoFormulario as keyof typeof EnumTipoPessoa],
    operador: null,
    tipotarifa: 0,
    porcentagemdescontotarifa: null,
    tarifavigentecontrato: null,
    tarifagerador: null,
    potencia: null,
    potenciaextenso: null,
    medidacorrente: 0,
    performacealvo: null,
    custofixo: undefined,
    custofixoextenso: null,
    vigenciacontratual: null,
    datalimiteconexao: undefined,
    tipoconexao: 0,
    statuscontratogerador: EnumStatusContratoGerador.aguardandoAssinatura,
    dataassinaturacontrato: null,
    uuidcontrato: null,
    agenciaresponsavelindicacao: null,
    favorecidoresponsavelindicacao: null,
    contaresponsavelindicacao: null,
    bancoresponsavelindicacao: null,
    cnpjfavorecidoresponsavelindicacao: null,
    chavepixresponsavelindicacao: null,
    tipochavepixresponsavelindicacao: 0,
    responsavelIndicacaos:[]
  };

  const [informacaoContrato, setInformacaoContrato] =
    useState<IInformacaoContrato>(formInformacaoContrato);
  const sendOnChange = (update: IInformacaoContrato): void => onChange(update);
  const sendOnSave = () => onSave({...informacaoContrato, responsavelIndicacaos});
  const sendOnUpdate = () => onUpdate(informacaoContrato);
  const sendOnGenerate = () => onGenerate({...informacaoContrato,
    uuidcontrato:uuidContratoGenerated,
    dataassinaturacontrato: new Date().toLocaleDateString("en-CA")})

  const sendOnDistrato = () => {
    handleInputChange(
      { 
        target:{ 
          name:InformacaoContratoEnum.STATUSCONTRATOGERADOR, 
          value: EnumStatusContratoGerador.aguardandoAssinaturaDistrato 
        } 
      })

    return onDistrato(informacaoContrato)
  }

  useEffect(() => {
    if (geradorId) {
      const formInformacaoContrato: IInformacaoContrato = {
        tipopessoa: usina?.tipoPessoa as EnumTipoPessoa,
        operador: usina?.distribuidora || null,
        tipotarifa: usina?.tipoTarifa as EnumTipoTarifa,
        porcentagemdescontotarifa: usina?.porcentagemDescontoTarifa || null,
        tarifavigentecontrato: usina?.tarifaVigenteContrato || null,
        tarifagerador: usina?.tarifaGerador || null,
        potencia: usina?.potencia || null,
        potenciaextenso: usina?.potenciaExtenso || null,
        medidacorrente: usina?.medidaCorrente as EnumMedidaCorrente,
        custofixo: usina?.custoFixo || undefined,
        custofixoextenso: usina?.custoFixoExtenso?.toString() || null,
        vigenciacontratual: usina?.vigenciaContratual || null,
        datalimiteconexao: usina?.dataLimiteConexao || undefined,
        tipoconexao: usina?.tipoConexao as EnumTipoConexao,
        performacealvo: usina?.performaceAlvo ?? null,
        statuscontratogerador:
          usina?.statusContratoGerador as EnumStatusContratoGerador,
        dataassinaturacontrato: usina?.dataAssinaturaContrato || undefined,
        uuidcontrato: usina?.uuidContrato || null,
        agenciaresponsavelindicacao: usina?.agenciaResponsavelIndicacao || null,
        favorecidoresponsavelindicacao:
          usina?.favorecidoResponsavelIndicacao || null,
        contaresponsavelindicacao: usina?.contaResponsavelIndicacao || null,
        bancoresponsavelindicacao: usina?.bancoResponsavelIndicacao || null,
        cnpjfavorecidoresponsavelindicacao:
          usina?.cnpjFavorecidoResponsavelIndicacao || null,
        chavepixresponsavelindicacao:
          usina?.chavePixResponsavelIndicacao || null,
        tipochavepixresponsavelindicacao:
          usina?.tipoChavePixResponsavelIndicacao as EnumTipoChavePix,
          responsavelIndicacaos: []
      };
      setInformacaoContrato(formInformacaoContrato);
    }
  }, [geradorId, usina]);

  const handleInputChange = (event: any): void => {
    const name = event.target.name.toLocaleLowerCase();
    const value = event.target.value;

    setInformacaoContrato((state: any) => {
      sendOnChange({ ...state, [name]: value });
      return { ...state, [name]: value };
    });
  };

  return (
      <RegisterCard title="Informações do contrato">
        {
          load.status && load.section === "informaçãoContrato"?
          <SkeletonSectionForm />
          :
          <>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: true }}
              name={InformacaoContratoEnum.TIPOPESSOA}
              onChange={handleInputChange}
              value={informacaoContrato?.tipopessoa ?? ""}
              label="Tipo de pessoa"
              required
              select
              sx={{ width: "80%" }}
            >
              {enumTipoPessoaEnumMap.map((tipo, i) => (
                <MenuItem key={i} value={tipo.value} >
                  {tipo.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.OPERADOR}
              onChange={handleInputChange}
              value={informacaoContrato?.operador ?? ""}
              label="Distribuidora"
              required
              select
              sx={{ width: "80%" }}
            >
              {(operadoras || []).map((operadora, i) => (
                <MenuItem key={i} value={operadora.id}>
                  {operadora.nome}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.TIPOTARIFA}
              onChange={handleInputChange}
              value={informacaoContrato?.tipotarifa ?? ""}
              label="Tipo de tarifa"
              required
              select
              sx={{ width: "80%" }}
            >
              {Object.entries(EnumTipoTarifa)
                .filter(([value, key]) => isNaN(Number(key)))
                .map(([value, key], i) => (
                  <MenuItem value={Number(value)}>{key}</MenuItem>
                ))}
            </TextField>
            <MaskCurrencyInput
              label="Porcentagem de desconto da tarifa"
              readonly={buttonSave}
              name={InformacaoContratoEnum.PORCENTAGEMDESCONTOTARIFA}
              onValueChange={(newValue, name, values) => {
                if (values.float > 65) newValue = "65";

                handleInputChange({ target: { name, value: newValue } });
              }}
              value={informacaoContrato?.porcentagemdescontotarifa || ""}
              placeholder="00,00%"
              defaultValue={0}
              decimalsLimit={2}
              suffix="%"
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <MaskCurrencyInput
              sx={{ width: "50%" }}
              label="Tarifa vigente"
              readonly={buttonSave}
              name={InformacaoContratoEnum.TARIFAVIGENTECONTRATO}
              onValueChange={(newValue, name, values) =>
                handleInputChange({ target: { name, value: newValue } })
              }
              value={informacaoContrato?.tarifavigentecontrato ?? ""}
              placeholder="00,00"
              defaultValue={0}
              decimalsLimit={2}
            />
            <MaskCurrencyInput
              sx={{ width: "50%" }}
              label="Tarifa do gerador"
              readonly
              name={InformacaoContratoEnum.TARIFAGERADOR}
              value={
                calcValues(
                  informacaoContrato?.tarifavigentecontrato, 
                  informacaoContrato?.porcentagemdescontotarifa,
                  "%",
                  4
                )
              }
              placeholder="0,0000"
              defaultValue={0}
              decimalsLimit={4}
            />
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.MEDIDACORRENTE}
              onChange={handleInputChange}
              value={informacaoContrato?.medidacorrente ?? ""}
              label="Medida corrente"
              select
              required
              sx={{ width: "80%" }}
            >
              {Object.entries(EnumMedidaCorrente)
                .filter(([value, key]) => isNaN(Number(key)))
                .map(([value, key]) => (
                  <MenuItem key={key} value={Number(value)}>
                    {key}
                  </MenuItem>
                ))}
            </TextField>
            <MaskCurrencyInput
              sx={{ width: "50%" }}
              label="Potência"
              readonly={buttonSave}
              name={InformacaoContratoEnum.POTENCIA}
              onValueChange={(newValue, name, values) =>
                handleInputChange({ target: { name, value: newValue } })
              }
              value={informacaoContrato?.potencia ?? ""}
              placeholder="00,0"
              defaultValue={0}
              decimalsLimit={1}
            />
            <TextField
              type="text"
              inputProps={{ readOnly: true }}
              name={InformacaoContratoEnum.POTENCIAEXTENSO}
              onChange={handleInputChange}
              value={
                `${numberForExtensive(informacaoContrato?.potencia)} ${informacaoContrato.medidacorrente === EnumMedidaCorrente.KWCA? "KWH":"MKWH"}`
              }
              label="Potência por extenso"
              required
              sx={{ width: "100%" }}
              onKeyDown={(event) => {
                const regex = /[0-9]/;
                if (regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <MaskCurrencyInput
              sx={{ width: "60%" }}
              label="Performance alvo"
              readonly={buttonSave}
              name={InformacaoContratoEnum.PERFORMACEALVO}
              onValueChange={(newValue, name, values) =>
                handleInputChange({ target: { name, value: newValue } })
              }
              value={informacaoContrato?.performacealvo ?? ""}
              placeholder="00,00kwh"
              defaultValue={0}
              decimalsLimit={2}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={{ width: "70%" }}
              label="Custo fixo"
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.CUSTOFIXO}
              value={
                formatValues(calcValues(
                  informacaoContrato?.performacealvo,
                  calcValues(
                    informacaoContrato?.tarifavigentecontrato, 
                    informacaoContrato?.porcentagemdescontotarifa,
                    "%"
                  ),
                  "*"
                ))
              }
              placeholder="R$ 00,00"
            />
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.CUSTOFIXOEXTENSO}
              onChange={handleInputChange}
              value={numberForExtensive(calcValues(
                informacaoContrato?.performacealvo,
                calcValues(
                  informacaoContrato?.tarifavigentecontrato, 
                  informacaoContrato?.porcentagemdescontotarifa,
                  "%"
                ),
                "*"
              ),"$")}
              label="Custo fixo por extenso"
              required
              fullWidth
              onKeyDown={(event) => {
                const regex = /[0-9]/;
                if (regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              type="number"
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.VIGENCIACONTRATUAL}
              onChange={({ target }) => {
                if (Number(target.value) > 120) target.value = "120";
                handleInputChange({ target });
              }}
              onBlur={({ target }) => {
                if (Number(target.value) < 12) target.value = "12";
                handleInputChange({ target });
              }}
              value={informacaoContrato?.vigenciacontratual || ""}
              label="Vigência contratual (meses)"
              required
              fullWidth
            />
            <D.DateTextfield
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.DATALIMITECONEXAO}
              onChange={handleInputChange}
              value={
                ((informacaoContrato?.datalimiteconexao ?? "")
                .toString())?.split("T")[0]
              }
              InputLabelProps={{
                shrink: true,
              }}
              label="Data limite de conexão"
              type="date"
              required
              fullWidth
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.TIPOCONEXAO}
              onChange={handleInputChange}
              value={informacaoContrato?.tipoconexao ?? ""}
              label="Tipo de conexão"
              select
              required
              sx={{ width: "60%" }}
            >
              {Object.entries(EnumTipoConexao)
                .filter(([value, key]) => isNaN(Number(key)))
                .map(([value, key]) => (
                  <MenuItem key={key} value={Number(value)}>
                    {key}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              inputProps={{ readOnly: buttonSave || !geradorId }}
              name={InformacaoContratoEnum.STATUSCONTRATOGERADOR}
              onChange={handleInputChange}
              value={informacaoContrato?.statuscontratogerador ?? ""}
              label="Status do contrato gerador"
              select
              required
              sx={{ width: "100%" }}
            >
              {enumStatusContratoMap.map(
                (
                  x: { name: string; value: EnumStatusContratoGerador },
                  i: number
                ) => (
                  <MenuItem key={i} value={x.value}>
                    {" "}
                    {x.name}{" "}
                  </MenuItem>
                )
              )}
            </TextField>
            <D.DateTextfield
              inputProps={{ readOnly: true }}
              name={InformacaoContratoEnum.DATAASSINATURACONTRATO}
              onChange={handleInputChange}
              value={
                ((informacaoContrato?.dataassinaturacontrato ?? "").toString())?.split("T")[0]
              }
              InputLabelProps={{
                shrink: true,
              }}
              label="Data de assinatura do contrato"
              type="date"
              required
              fullWidth
            />
          </C.FWStack>
          {children}
          <C.FWStack direction={"row"} spacing={16}>
            <C.MWDiv>
              <C.Subtitle>Dados bancários*</C.Subtitle>
              <C.Text>Insira seus dados bancários</C.Text>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.AGENCIARESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.agenciaresponsavelindicacao || ""}
                  type="number"
                  label="Agência"
                  required
                  fullWidth
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.CONTARESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.contaresponsavelindicacao || ""}
                  type="number"
                  label="Conta"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.FAVORECIDORESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.favorecidoresponsavelindicacao || ""}
                  label="Favorecido"
                  required
                  fullWidth
                  onKeyDown={(event) => {
                    const regex = /[0-9]/;
                    if (regex.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.BANCORESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.bancoresponsavelindicacao || ""}
                  label="Banco"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  type="number"
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.CNPJFAVORECIDORESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={
                    informacaoContrato?.cnpjfavorecidoresponsavelindicacao || ""
                  }
                  label="CNPJ do banco"
                  required
                  fullWidth
                />
                <C.FWDiv></C.FWDiv>
              </C.FWStack>
            </C.MWDiv>
            <C.MWDiv>
              <C.Subtitle>Pix*</C.Subtitle>
              <C.Text>Insira sua chave pix</C.Text>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  type={
                    informacaoContrato.tipochavepixresponsavelindicacao ===
                      EnumTipoChavePix.CELULAR ||
                    informacaoContrato.tipochavepixresponsavelindicacao ===
                      EnumTipoChavePix.ALEATORIO ||
                    informacaoContrato.tipochavepixresponsavelindicacao ===
                      EnumTipoChavePix.CPF
                      ? "number"
                      : "text"
                  }
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.CHAVEPIXRESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.chavepixresponsavelindicacao || ""}
                  label="Chave PIX"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.Text>Qual dado você quer usar?</C.Text>
              <C.FWStack direction={"row"} spacing={2}>
                {Object.entries(EnumTipoChavePix)
                  .filter(([key, value]) => isNaN(Number(key)))
                  .map(([key, value]) => (
                    <Chip
                      disabled={buttonSave}
                      label={key}
                      onClick={() =>
                        handleInputChange({
                          target: {
                            name: InformacaoContratoEnum.TIPOCHAVEPIXRESPONSAVELINDICACAO,
                            value,
                          },
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                      color={
                        informacaoContrato?.tipochavepixresponsavelindicacao ===
                        value
                          ? "primary"
                          : "default"
                      }
                    />
                  ))}
              </C.FWStack>
            </C.MWDiv>
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <D.ContainedButton
              disabled={!buttonSave || buttonSaveGenerate}
              onClick={sendOnGenerate}
            >
              Gerar Contrato
            </D.ContainedButton>
            <D.ContainedButton
              disabled={!buttonSave}
              onClick={sendOnDistrato}
            >
              Gerar Distrato
            </D.ContainedButton>

            <D.ContainedButton
              disabled={!buttonSave}
              onClick={sendOnUpdate}
              $color="yellow"
            >
              Editar
            </D.ContainedButton>
            <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
              Salvar
            </D.ContainedButton>
          </C.FWStack>
          </>
        }
      </RegisterCard>
  );
};
