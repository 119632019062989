export enum EnumStatusContratoGerador{
    aguardandoAssinatura = 0,
    contratoAssinado = 1,
    emProcessoTrocaTitularidade = 2,
    mudancaTitularidadeConcluida = 3,
    conectado = 4,
    desconexaoSolicitada = 5,
    emProcessoDeDesconexao = 6,
    aguardandoAssinaturaDistrato = 7,
    cancelado = 8
}

export const enumStatusContratoMap = [
    {
        name:"Aguardando assinatura",
        value: EnumStatusContratoGerador.aguardandoAssinatura
    },
    {
        name:"Contrato assinado",
        value: EnumStatusContratoGerador.contratoAssinado
    },
    {
        name:"Em troca de Titularidade",
        value: EnumStatusContratoGerador.emProcessoTrocaTitularidade
    },
    {
        name:"Mudança de Titularidade concluída",
        value: EnumStatusContratoGerador.mudancaTitularidadeConcluida
    },
    {
        name:"Conectado",
        value: EnumStatusContratoGerador.conectado
    },
    {
        name:"Desconexão Solicitada",
        value: EnumStatusContratoGerador.desconexaoSolicitada
    },
    {
        name:"Em processo de desconexão",
        value: EnumStatusContratoGerador.aguardandoAssinaturaDistrato
    },
    {
        name:"Aguardando assinatura de distrato",
        value: EnumStatusContratoGerador.aguardandoAssinaturaDistrato
    },
    {
        name:"Cancelado",
        value: EnumStatusContratoGerador.cancelado
    }
];