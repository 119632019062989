import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Box, Checkbox, Stack, TextField } from "@mui/material";
import RegisterCard from "../../../../../../../components/RegisterCard";
import { useObjectState } from "../../../../../../../hooks/useObjectState";
import { IFormPreferencia } from "../../../../../../../models/Contratar";
import * as D from "../../../../../../../styles/appComponents";
import * as G from "../../../../../Colaborador/Colaborador/Cadastro/style";
import { CheckboxGroup, ReportsArea } from "../../style";
import { useEffect } from "react";
import {
  IFullGDContratoCliente,
  IUpdateEmailCommand,
} from "../../../../../../../models/GDContrato";

type PropsPreferenciaContratar = {
  type: string;
  dataContrato?: IFullGDContratoCliente;
  onChange: (value: IFormPreferencia) => void;
};

const PreferenciaContratar = (props: PropsPreferenciaContratar) => {
  const { state, setObject, updateObject } = useObjectState<IFormPreferencia>({
    emails: [""],
    whatsapp: "",
    tresdias: false,
    setedias: false,
    geracao: false,
    vencimento: false,
    preferenciadediasemrecebimentofatura: false,
    possuigeracaoenergiapropria: false,
    obedecefranquia: false,
    preferenciatitularidadefatura: false,
    updateEmails: [],
  });

  const handleChange = (value: string, index: number) => {
    const updatedEmails = [...state.emails];
    updatedEmails[index] = value;
    updateObject({ emails: updatedEmails });
  };

  const handleChangeUpdated = (value: string, id: string, index: number) => {
    const updatedEmails = [...state.updateEmails!];
    updatedEmails[index] = { id: id, emailAddress: value };
    updateObject({ updateEmails: updatedEmails });
  };

  const handleAddEmails = (type: "Update" | "Create") => {
    if (type == "Create") {
      updateObject({ emails: [...state.emails, ""] });
    } else {
      updateObject({
        updateEmails: [...state.updateEmails!, { id: "", emailAddress: "" }],
      });
    }
  };

  const handleRemoveEmails = (type: "Update" | "Create") => {
    if (type == "Create") {
      if (state.emails.length > 1) {
        updateObject({ emails: state.emails.slice(0, -1) });
      }
    } else {
      if (state.updateEmails && state.updateEmails.length > 1) {
        updateObject({ updateEmails: state.updateEmails.slice(0, -1) });
      }
    }
  };

  useEffect(() => {
    props.onChange(state);
  }, [state]);

  useEffect((): void => {
    if (props.dataContrato) {
      const mappedEmails: IUpdateEmailCommand[] | undefined =
        props.dataContrato.emails?.map((item) => ({
          id: item.id,
          emailAddress: item.emailAddress,
        }));

      setObject({
        emails: [],
        geracao: props.dataContrato.recebeGeracao,
        obedecefranquia: props.dataContrato.obdeceFranquia,
        possuigeracaoenergiapropria:
          props.dataContrato.possuiGeracaoEnergiaPropria,
        preferenciadediasemrecebimentofatura:
          props.dataContrato.preferenciaDiaRecebimento,
        preferenciatitularidadefatura:
          props.dataContrato.preferenciaTitularidade,
        setedias: props.dataContrato.recebeSeteDias,
        tresdias: props.dataContrato.recebeTresDias,
        vencimento: props.dataContrato.recebeVencimento,
        whatsapp: props.dataContrato.whatsapp ?? "",
        updateEmails: mappedEmails ?? [],
      });
    }
  }, [props.dataContrato]);

  return (
    <RegisterCard title="Preferência">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Whatsapp"
          required
          value={state.whatsapp}
          onChange={(e) => updateObject({ whatsapp: e.target.value })}
          sx={{ width: "50%" }}
        />

        <CheckboxGroup>
          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.tresdias}
                onChange={() => updateObject({ tresdias: !state.tresdias })}
              />
              <p>3 dias</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.setedias}
                onChange={() => updateObject({ setedias: !state.setedias })}
              />
              <p>7 dias</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.geracao}
                onChange={() => updateObject({ geracao: !state.geracao })}
              />
              <p>Geração</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.vencimento}
                onChange={() => updateObject({ vencimento: !state.vencimento })}
              />
              <p>Vencimento</p>
            </G.CheckboxWrapper>
          </Box>
        </CheckboxGroup>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2} alignItems={"flex-end"}>
        <Stack sx={{ width: "50%" }} spacing={2} direction={"column"}>
          <p>Lista de email para recebimento de faturas</p>
          {props.dataContrato
            ? state.updateEmails!.map((email, index) => (
                <TextField
                  key={email.id} // Adicione uma chave única para cada item
                  value={email.emailAddress}
                  onChange={(e) =>
                    handleChangeUpdated(e.target.value, email.id, index)
                  } // Atualiza o estado ao digitar
                  label={`Email ${index + 1}`}
                  required
                  fullWidth
                />
              ))
            : state.emails.map((email, index) => (
                <TextField
                  key={index} // Adicione uma chave única para cada item
                  value={email}
                  onChange={(e) => handleChange(e.target.value, index)} // Atualiza o estado ao digitar
                  label={`Email ${index + 1}`}
                  required
                  fullWidth
                />
              ))}
        </Stack>
        {props.dataContrato ? (
          <>
            {state.updateEmails!.length > 1 && (
              <D.ContainedButton
                $color="blue"
                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                onClick={() => handleRemoveEmails("Update")}
              >
                Remover email
              </D.ContainedButton>
            )}
            <D.ContainedButton
              $color="lightpink"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => handleAddEmails("Update")}
            >
              Adicionar mais email
            </D.ContainedButton>
          </>
        ) : (
          <>
            {state.emails.length > 1 && (
              <D.ContainedButton
                $color="blue"
                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                onClick={() => handleRemoveEmails("Create")}
              >
                Remover email
              </D.ContainedButton>
            )}
            <D.ContainedButton
              $color="lightpink"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => handleAddEmails("Create")}
            >
              Adicionar mais email
            </D.ContainedButton>
          </>
        )}
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <ReportsArea>
          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.preferenciadediasemrecebimentofatura}
                onChange={(e) =>
                  updateObject({
                    preferenciadediasemrecebimentofatura:
                      !state.preferenciadediasemrecebimentofatura,
                  })
                }
              />
              <p>Preferência de dia de recebimento fatura?</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.possuigeracaoenergiapropria}
                onChange={(e) =>
                  updateObject({
                    possuigeracaoenergiapropria:
                      !state.possuigeracaoenergiapropria,
                  })
                }
              />
              <p>Possui geração de energia própria</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.obedecefranquia}
                onChange={(e) =>
                  updateObject({ obedecefranquia: !state.obedecefranquia })
                }
              />
              <p>Obedece a franquia</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                checked={state.preferenciatitularidadefatura}
                onChange={(e) =>
                  updateObject({
                    preferenciatitularidadefatura:
                      !state.preferenciatitularidadefatura,
                  })
                }
              />
              <p>Preferência titularidade fatura</p>
            </G.CheckboxWrapper>
          </Box>
        </ReportsArea>
      </D.FWStack>
    </RegisterCard>
  );
};

export default PreferenciaContratar;
