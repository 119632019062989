import React, { FC, useEffect, useState } from "react";
import { Modal, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import * as D from "../../../../styles/appComponents";
import * as A from "../../../../components/StatusStyle/Style";
import { UsinaTipoPessoaFisica, UsinaTipoPessoaJuridica } from "../../../../models/Gerador/Usina";
import * as C from "../../Parceiro/Leads/style";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { HistoricoSection } from "../../../../components/HistoricSection";
import { ICreateLog, IFullLog, ILog } from "../../../../models/Log";
import { EnumTipoConexao } from "../../../../enums/Gerador/EnumTipoConexao.enum";
import { EnumTipoTarifa } from "../../../../enums/Gerador/EnumTipoTarifa.enum";
import { EnumStatusContratoGerador } from "../../../../enums/Gerador/EnumStatusContratoGerador.enum";
import { SkeletonCard } from "../../../../components/SkeletonLoads/CreationCards/style";
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";
import { SkeletonSectionForm } from "../../../../components/SkeletonLoads/SectionForm";

export interface PropsModalInfoGerador {
  openModal: boolean;
  handleCloseModal: () => void;
  usina: UsinaTipoPessoaJuridica | UsinaTipoPessoaFisica | undefined;
  createNewLog: (data:ICreateLog) => Promise<void>;
  logs:ILog[];
  editLog: (id:string, data:ICreateLog) => Promise<void>;
  load:boolean;
}

export const ModalInfoGerador: FC<PropsModalInfoGerador> = (
  {
    openModal, 
    handleCloseModal, 
    usina, 
    createNewLog,
    logs,
    editLog,
    load
  }
) => {

  return (
    <Modal open={openModal}>
      <C.ModalContainer>
        <C.ModalArea>
          <C.ModalCard>
            {
              load?
              <SkeletonSectionForm />:
              <>
              <C.ModalHeader>
                <h2>Gerador</h2>
                <C.CloseButton aria-label="close" onClick={handleCloseModal}>
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <D.FWStack direction={"column"} spacing={4}>
                <D.FWStack direction={"column"} spacing={2}>
                  <h4>Status atual:</h4>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <A.StatusStyle
                      status={usina?.statusContratoGerador}
                    ></A.StatusStyle>
                    <Typography>
                      {usina?.statusContratoGerador === EnumStatusContratoGerador.aguardandoAssinatura
                        ? "Aguardando assinatura"
                        : usina?.statusContratoGerador === EnumStatusContratoGerador.contratoAssinado
                        ? "Contrato assinado"
                        : usina?.statusContratoGerador === EnumStatusContratoGerador.conectado
                        ? "Conectado"
                        : usina?.statusContratoGerador === EnumStatusContratoGerador.emProcessoTrocaTitularidade
                        ? "Em troca de titularidade"
                        : usina?.statusContratoGerador === EnumStatusContratoGerador.aguardandoAssinaturaDistrato
                        ? "Aguardando assinatura distrato"
                        : ""}
                    </Typography>
                  </Stack>
                </D.FWStack>

                <D.FWStack direction="row" spacing={2}>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Desconto da tarifa</label>
                    <p>
                      {usina?.porcentagemDescontoTarifa
                        ? usina.porcentagemDescontoTarifa + " %"
                        : "Não informado."}
                    </p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Data da conexão</label>
                    <p>{moment.utc(usina?.dataLimiteConexao).format("DD/MM/YYYY") === "Invalid date" ? "Não informado" : moment.utc(usina?.dataLimiteConexao).format("DD/MM/YYYY")}</p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Término do contrato</label>
                    <p>
                      {moment.utc(usina?.dataTermino).format("DD/MM/YYYY") === "Invalid date" ? "Não informado" : moment.utc(usina?.dataTermino).format("DD/MM/YYYY")}
                    </p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Celular</label>
                    <p>{usina?.celularDadosAcesso}</p>
                  </D.FWStack>
                </D.FWStack>
                {/* Linha 2 com 6 colunas */}
                <D.FWStack direction="row" spacing={2}>
                  <D.FWStack direction="column" spacing={2}>
                    <label>CEP</label>
                    <p>{usina?.cep ?? "Não informado."}</p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>UF</label>
                    <p>{usina?.uf ?? "Não informado."}</p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Cidade</label>
                    <p>{usina?.cidade ?? "Não informado."}</p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Bairro</label>
                    <p>{usina?.bairro ?? "Não informado."}</p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Número</label>
                    <p>{usina?.numero ?? "Não informado."}</p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Tipo de Conexão</label>
                    <p>{EnumTipoTarifa[usina?.tipoTarifa as EnumTipoTarifa] ?? "Não informado."}</p>
                  </D.FWStack>
                </D.FWStack>
                {/* Linha 3 com 2 colunas */}
                <D.FWStack direction="row" spacing={2}>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Logradouro</label>
                    <p>{usina?.logradouro ?? "Não informado."}</p>
                  </D.FWStack>
                  <D.FWStack direction="column" spacing={2}>
                    <label>Complemento</label>
                    <p>{usina?.complemento ?? "Não informado."}</p>
                  </D.FWStack>
                  <D.FWStack></D.FWStack>
                  <D.FWStack></D.FWStack>
                </D.FWStack>
              </D.FWStack>
              </>
            }

          </C.ModalCard>
          <HistoricoSection
              tableData={logs}
              saveFunction={createNewLog}
              reloadTable={() => {}}
              editLog={editLog} 
            />
        </C.ModalArea>
      </C.ModalContainer>
    </Modal>
  );
};
