export enum EnumGatewayDePagamento {
  plataforma = "Plataforma",
  usina = "Usina",
  clienteComLimiteDeInjecao = "Cliente com limite de injeção",
}

export const EnumGatewayDePagamentoNumericMap = {
  [EnumGatewayDePagamento.plataforma]: 0,
  [EnumGatewayDePagamento.usina]: 1,
  [EnumGatewayDePagamento.clienteComLimiteDeInjecao]: 2,
};
