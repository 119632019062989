import {
  ICreateGDContratoClientePF,
  ICreateGDContratoClientePJ,
  IFullGDContratoCliente,
  IGDContrato,
  IGDContratoPaged,
  IGDContratoStats,
  IUpdateGDContratoClientePF,
  IUpdateGDContratoClientePJ,
} from "../../models/GDContrato";
import { IPaginatedList } from "../../models/PaginatedList";
import http from "../common/http-common";

export const GetStats = () => {
  return http.get<IGDContratoStats[]>("/GDContratoCliente/Stats");
};

export const GetContratos = (
  pageNumber: number,
  pageSize: number,
  search?: string
) => {
  var searchString: string = "";

  if (search) {
    searchString = `&Search=${search}`;
  }

  return http.get<IPaginatedList<IGDContratoPaged>>(
    `/GDContratoCliente?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
  );
};

export const GetContratoById = (id: string) => {
  return http.get<IGDContrato>(`/GDContratoCliente/${id}`);
};

export const GetFullContratoById = (id: string) => {
  return http.get<IFullGDContratoCliente>(
    `/GDContratoCliente/GetFullGDContratoCliente/${id}`
  );
};

export const CreateGDContratoPessoaFisica = (
  data: ICreateGDContratoClientePF
) => {
  return http.post("/GDContratoCliente/PessoaFisica", data);
};

export const CreateGDContratoPessoaJuridica = (
  data: ICreateGDContratoClientePJ
) => {
  return http.post("/GDContratoCliente/PessoaJuridica", data);
};

export const UpdateGDContratoPessoaFisica = (
  data: IUpdateGDContratoClientePF
) => {
  return http.put("/GDContratoCliente/PessoaFisica", data);
};

export const UpdateGDContratoPessoaJuridica = (
  data: IUpdateGDContratoClientePJ
) => {
  return http.put("/GDContratoCliente/PessoaJuridica", data);
};

export const EnviaContratoParaClickSign = (id: string) => {
  return http.post(
    `/GDContratoCliente/EnviaContratoParaAssinaturaDigital/${id}`
  );
};

export const VerificaPodeEnviarContratoParaClickSign = (id: string) => {
  return http.get<boolean>(
    `/GDContratoCliente/VerifyCanCreateIndividualSignature/${id}`
  );
};
