import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { FC, useReducer, useState } from "react";
import * as G from "../../style";
import { Checkbox, MenuItem, Stack, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { EnumContribuicaoSindical } from "../../../../../../../enums/EnumContribuicaoSindical.enum";
import { EnumTipoVaga } from "../../../../../../../enums/EnumTipoVaga.enum";
import { EnumHorasSemanais } from "../../../../../../../enums/EnumHorasSemanais.enum";
import * as C from "../Situacao/style";
import {EnumIncidencia} from "../../../../../../../enums/EnumIncidencia";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";

enum FormSalarioFuncao {
  SALARIOBASE = "SALARIOBASE",
  FUNCAO = "FUNCAO",
  PARTIDOSINDICATO = "PARTIDOSINDICATO",
  BOOLPERIODOEXPERIENCIA = "BOOLPERIODOEXPERIENCIA",
  INICIOPERIODOEXPERIENCIA = "INICIOPERIODOEXPERIENCIA",
  FIMPERIODOEXPERIENCIA = "FIMPERIODOEXPERIENCIA",
  BOOLINCIDEINSS = "BOOLINCIDEINSS",
  BOOLINCIDEIRF = "BOOLINCIDEIRF",
  BOOLINCIDEFGTS = "BOOLINCIDEFGTS",
  ENUMCONTRIBUICAOSINDICAL = "ENUMCONTRIBUICAOSINDICAL",
  ENUMTIPOVAGA = "ENUMTIPOVAGA",
  ENUMHORASSEMANAIS = "ENUMHORASSEMANAIS",
}

export interface IFormSalarioFuncao {
  salariobase: Number | undefined,
  funcao: string,
  partidosindicato: string,
  boolperiodoexperiencia: Boolean,
  inicioperiodoexperiencia: Date | null,
  fimperiodoexperiencia: Date | null,
  boolincideinss: Boolean,
  boolincideirf: Boolean,
  boolincidefgts: Boolean,
  enumcontribuicaosindical: Number,
  enumtipovaga: Number,
  enumhorassemanais: Number,
}

type PropsSalarioFuncao = {
  onSave: (data: IFormSalarioFuncao) => void;
  onUpdate: (data: IFormSalarioFuncao) => void;
  onChange: (data: IFormSalarioFuncao) => void;
  fieldErros: ErrorMessage[] | null;
  buttonSave: boolean;
  data?: object | null;
};

const styleError = {
  "& .MuiOutlinedInput-root": {
    color: "#000",
    fontFamily: "Arial",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "2px",
    },
  },
  "& .MuiInputLabel-outlined": {
    color: "#FF0000"
  },
};

const incidenciaMapping = {
  [EnumIncidencia.incidencia]: "BOOLINCIDEINSS",
  [EnumIncidencia.incidencia_aceptada]: "BOOLINCIDEIRF",
  [EnumIncidencia.incidencia_rechazada]: "BOOLINCIDEFGTS",
};

const SalarioFuncao:FC<PropsSalarioFuncao> = ({onSave,onUpdate,onChange,data,buttonSave,fieldErros}) => {
  const sendOnSave = (): void => onSave(state);
  const sendOnUpdate = (): void => onUpdate(state);
  const sendOnChange = (update: IFormSalarioFuncao): void => onChange(update);

  const [selectedIncidencias, setSelectedIncidencias] = useState<{ [key: string]: boolean }>({
    BOOLINCIDEINSS: false,
    BOOLINCIDEIRF: false,
    BOOLINCIDEFGTS: false,
  });

  const setDataForm = (state: IFormSalarioFuncao, action: { type: FormSalarioFuncao; payload: string | boolean | number; }): IFormSalarioFuncao => {
    const newState = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(newState);
    return newState;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    salariobase: undefined,
    funcao: "",
    partidosindicato: "",
    boolperiodoexperiencia: false,
    inicioperiodoexperiencia: null,
    fimperiodoexperiencia: null,
    boolincideinss: false,
    boolincideirf: false,
    boolincidefgts: false,
    enumcontribuicaosindical: 0,
    enumtipovaga: 0,
    enumhorassemanais: 0,
  });

  const handleInputChange = (field: FormSalarioFuncao) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: field, payload: event.target.value });
  };

  const handleChangeSelectIncidencia = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const { value, checked } = event.target;
    const field = incidenciaMapping[value as keyof typeof incidenciaMapping];
    if (field) {
      setSelectedIncidencias(prev => ({
        ...prev,
        [field]: checked,
      }));

      dispatch({ type: FormSalarioFuncao[field as keyof typeof FormSalarioFuncao], payload: checked });
    }
  };

  const verifyFieldError = (field:string) => {
    return fieldErros?.some((x) => x.ref === field.toLocaleLowerCase())
  };

  return (
    <RegisterCard title="Salário/função">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={verifyFieldError(FormSalarioFuncao.SALARIOBASE)? styleError: {}}
          disabled={buttonSave}
          label="Salário base" 
          type="number" 
          fullWidth 
          onChange={handleInputChange(FormSalarioFuncao.SALARIOBASE)} />
        <TextField
          sx={verifyFieldError(FormSalarioFuncao.FUNCAO)? styleError: {}}
          disabled={buttonSave}
          label="Função" 
          fullWidth 
          onChange={handleInputChange(FormSalarioFuncao.FUNCAO)} />
        <TextField
          sx={verifyFieldError(FormSalarioFuncao.PARTIDOSINDICATO)? styleError: {}} 
          disabled={buttonSave}
          label="Partido sindicato" 
          fullWidth 
          onChange={handleInputChange(FormSalarioFuncao.PARTIDOSINDICATO)} />
        <TextField
          disabled={buttonSave}
          fullWidth
          select
          value={state?.boolperiodoexperiencia}
          label="Periodo de experiência"
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => dispatch({ type: FormSalarioFuncao.BOOLPERIODOEXPERIENCIA, payload: e.target.value == "true" })}
        >
          <MenuItem   value={"true"}>Sim</MenuItem>
          <MenuItem  value={"false"}>Não</MenuItem>
        </TextField>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        {state?.boolperiodoexperiencia && (
          <>
            <D.DateTextfield
            disabled={buttonSave}
            type="date" 
            label="Inicio" 
            sx={{ width: "20%" }} onChange={handleInputChange(FormSalarioFuncao.INICIOPERIODOEXPERIENCIA)}/>

            <D.DateTextfield
            disabled={buttonSave}
            type="date" 
            label="Fim" 
            sx={{ width: "20%" }}  onChange={handleInputChange(FormSalarioFuncao.FIMPERIODOEXPERIENCIA)}/>

          </>
        )}
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={8}>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Incidência</G.Title>
          {Object.values(EnumIncidencia).map((value, index) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={buttonSave}
                checked={selectedIncidencias[incidenciaMapping[value]]}
                value={value}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleChangeSelectIncidencia(e, index)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Contribuição sindical</G.Title>
          {Object.values(EnumContribuicaoSindical).map((value, index) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={buttonSave}
                checked={state?.enumcontribuicaosindical === index}
                value={value}
                onChange={() => dispatch({ type: FormSalarioFuncao.ENUMCONTRIBUICAOSINDICAL, payload: index })}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Tipo de vaga</G.Title>
          {Object.values(EnumTipoVaga).map((value, index) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={buttonSave}
                checked={state?.enumtipovaga === index}
                value={value}
                onChange={() => dispatch({ type: FormSalarioFuncao.ENUMTIPOVAGA, payload: index })}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Horas semanais</G.Title>
          {Object.values(EnumHorasSemanais).map((value, index) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={buttonSave}
                checked={state?.enumhorassemanais === index}
                value={value}
                onChange={() => dispatch({ type: FormSalarioFuncao.ENUMHORASSEMANAIS, payload: index })}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
      </G.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
        <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};

export default SalarioFuncao;
