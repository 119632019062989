export const getFirstWord = (word:string | null): string => {
    return (word || "")?.split(" ")[0]?.toLowerCase()
}

export const siglaTransform = (name:string, reverse:boolean = false, data:any=[]) => {

    const getAllInitialsCombinations = (initials:string) => {
        const combinations:string[] = [];
        
        function generatePermutations(arr:string[], current = '') {
            if (arr.length === 0) {
                combinations.push(current);
                return;
            }
            for (let i = 0; i < arr.length; i++) {
                const newArr = [...arr];
                const letter = newArr.splice(i, 1);
                generatePermutations(newArr, current + letter);
            }
        }
    
        generatePermutations(initials.split(''));
        return combinations;
    };

    const verifyIfHasSigla = (sigla:string) => {
        return (data ?? []).some((x:any) => x.sigla === sigla);
    };

    if (!name) {
        return null;
    }

    if (reverse) {
        return name.split("").reverse().join("");
    } else {
        const partes = name.split(" ");
        let siglaTransformed = partes.map(palavra => palavra.charAt(0).toUpperCase()).join("");

        if (!verifyIfHasSigla(siglaTransformed)) {
            return siglaTransformed;
        } else {
            const combinations = getAllInitialsCombinations(siglaTransformed);
            
            for (let sigla of combinations) {
                if (!verifyIfHasSigla(sigla)) {
                    return sigla;
                }
            }
            
            return siglaTransformed;
        }
    }
};

export const firstNameTransform = (name: string | null, data: any[] = []) => {
    const generateRandomNumber = () => Math.floor(Math.random() * 1000);

    const verifyIfNameExists = (newName: string) => {
        return (data ?? []).some((x: any) => x.loginDadosAcesso === newName);
    };

    if (!name) {
        return null;
    }

    const firstName = name.split(" ")[0].toLowerCase();

    if (!verifyIfNameExists(firstName)) {
        return firstName;
    } else {
        let newName = firstName;

        do {
            newName = `${firstName}${generateRandomNumber()}`;
        } while (verifyIfNameExists(newName));

        return newName;
    }
};