import { ISidebarMenu } from "../../../models/SidebarPopulate";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { IAcessoDTO } from "../../../models/Acesso";
import  { ReactComponent as CadastroIcon } from "../../../assets/Layouts/Colaborador/cadastro.svg";
import  { ReactComponent as GeradorIcon } from "../../../assets/Layouts/Colaborador/gerador.svg";


const ColaboradorRoutes = (acesso?: IAcessoDTO) => {
  var routes: ISidebarMenu[] = [
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "Dashboard",
    //   hasSubMenus: false,
    //   path: "/Colaborador/Dashboard",
    // },
    {
      icon: () => <CadastroIcon/>,
      title: "Cadastro",
      hasSubMenus: true,
      subMenus: [
        {
          title: "GD",
          itens: [
            // {
            //   title: "Cliente",
            //   path: "/Colaborador/Cadastro/GD/Cliente",
            //   show: true,
            // },
            // {
            //   title: "Colaborador",
            //   path: "/Colaborador/Cadastro/GD/Colaborador",
            //   show: true,
            // },
            // {
            //   title: "Cooperativa",
            //   path: "/Colaborador/Gerador",
            //   show: true,
            // },
            // {
            //   title: "Função do colaborador",
            //   path: "/Colaborador/Cadastro/GD/FuncaoColaborador",
            //   show: true,
            // },
            {
              title: "Gerador",
              path: "/Colaborador/Gerador",
              show: true,
            },
            // {
            //   title: "Grupo cliente",
            //   path: "/Colaborador/Cadastro/GD/GrupoCliente",
            //   show: true,
            // },
            // {
            //   title: "Grupo gerador",
            //   path: "/Colaborador/Cadastro/GD/GrupoGerador",
            //   show: true,
            // },
            // {
            //   title: "Lista de tarifas",
            //   path: "/Colaborador/Cadastro/GD/ListaTarifas",
            //   show: true,
            // },
            // {
            //   title: "Parceiro",
            //   path: "/Colaborador/Cadastro/GD/Parceiro",
            //   show: true,
            // },
            // {
            //   title: "Tarifas fio B",
            //   path: "/Colaborador/Cadastro/GD/TarifasB",
            //   show: true,
            // },
          ],
        },
        // {
        //   title: "ML",
        //   itens: [
        //     {
        //       title: "Cliente",
        //       path: "/Colaborador/Cadastro/ML/Cliente",
        //       show: true,
        //     },
        //     {
        //       title: "Colaborador",
        //       path: "/Colaborador/Cadastro/ML/Colaborador",
        //       show: true,
        //     },
        //     {
        //       title: "Cooperativa",
        //       path: "/Colaborador/Cadastro/ML/Cooperativa",
        //       show: true,
        //     },
        //     {
        //       title: "Função do colaborador",
        //       path: "/Colaborador/Cadastro/ML/FuncaoColaborador",
        //       show: true,
        //     },
        //     {
        //       title: "Gerador",
        //       path: "/Colaborador/Cadastro/ML/Gerador",
        //       show: true,
        //     },
        //     {
        //       title: "Grupo cliente",
        //       path: "/Colaborador/Cadastro/ML/GrupoCliente",
        //       show: true,
        //     },
        //     {
        //       title: "Grupo gerador",
        //       path: "/Colaborador/Cadastro/ML/GrupoGerador",
        //       show: true,
        //     },
        //     {
        //       title: "Lista de tarifas",
        //       path: "/Colaborador/Cadastro/ML/ListaTarifas",
        //       show: true,
        //     },
        //     {
        //       title: "Parceiro",
        //       path: "/Colaborador/Cadastro/ML/Parceiro",
        //       show: true,
        //     },
        //     {
        //       title: "Tarifas fio B",
        //       path: "/Colaborador/Cadastro/ML/TarifasB",
        //       show: true,
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "Comercial",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [
    //         {
    //           title: "Lead",
    //           path: "/Colaborador/Comercial/GD/Lead",
    //           show: true,
    //         },
    //         {
    //           title: "Proposta",
    //           path: "/Colaborador/Comercial/GD/Proposta",
    //           show: true,
    //         },
    //         {
    //           title: "Contrato cliente",
    //           path: "/Colaborador/Comercial/GD/ContratoCliente",
    //           show: true,
    //         },
    //       ],
    //     },
    //     {
    //       title: "ML",
    //       itens: [
    //         {
    //           title: "Lead",
    //           path: "/Colaborador/Comercial/ML/Lead",
    //           show: true,
    //         },
    //         {
    //           title: "Proposta",
    //           path: "/Colaborador/Comercial/ML/Proposta",
    //           show: true,
    //         },
    //         {
    //           title: "Contrato cliente",
    //           path: "/Colaborador/Comercial/ML/ContratoCliente",
    //           show: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "Parceiro",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [
    //         {
    //           title: "Comissão colaborador",
    //           path: "/Colaborador/Parceiro/GD/ComissaoColaborador",
    //           show: true,
    //         },
    //         {
    //           title: "Comissão parceiro",
    //           path: "/Colaborador/Parceiro/GD/ComissaoParceiro",
    //           show: true,
    //         },
    //         {
    //           title: "Comissão parceiro gerador",
    //           path: "/Colaborador/Parceiro/GD/ComissaoGerador",
    //           show: true,
    //         },
    //         {
    //           title: "Contrato parceiro",
    //           path: "/Colaborador/Parceiro/GD/ContratoParceiro",
    //           show: true,
    //         },
    //         {
    //           title: "Contrato parceiro gerador",
    //           path: "/Colaborador/Parceiro/GD/ContratoParceiroGerador",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato backoffice",
    //           path: "/Colaborador/Parceiro/GD/ExtratoBackoffice",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato parceiro",
    //           path: "/Colaborador/Parceiro/GD/ExtratoParceiro",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato parceiro gerador",
    //           path: "/Colaborador/Parceiro/GD/ExtratoParceiroGerador",
    //           show: true,
    //         },
    //         {
    //           title: "Lista de backoffice",
    //           path: "/Colaborador/Parceiro/GD/ListaBackoffice",
    //           show: true,
    //         },
    //       ],
    //     },
    //     {
    //       title: "ML",
    //       itens: [
    //         {
    //           title: "Comissão colaborador",
    //           path: "/Colaborador/Parceiro/ML/ComissaoColaborador",
    //           show: true,
    //         },
    //         {
    //           title: "Comissão parceiro",
    //           path: "/Colaborador/Parceiro/ML/ComissaoParceiro",
    //           show: true,
    //         },
    //         {
    //           title: "Comissão parceiro gerador",
    //           path: "/Colaborador/Parceiro/ML/ComissaoGerador",
    //           show: true,
    //         },
    //         {
    //           title: "Contrato parceiro",
    //           path: "/Colaborador/Parceiro/ML/ContratoParceiro",
    //           show: true,
    //         },
    //         {
    //           title: "Contrato parceiro gerador",
    //           path: "/Colaborador/Parceiro/ML/ContratoParceiroGerador",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato backoffice",
    //           path: "/Colaborador/Parceiro/ML/ExtratoBackoffice",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato parceiro",
    //           path: "/Colaborador/Parceiro/ML/ExtratoParceiro",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato parceiro gerador",
    //           path: "/Colaborador/Parceiro/ML/ExtratoParceiroGerador",
    //           show: true,
    //         },
    //         {
    //           title: "Lista de backoffice",
    //           path: "/Colaborador/Parceiro/ML/ListaBackoffice",
    //           show: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   icon: () => <GeradorIcon />,
    //   title: "Gerador",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [
    //         // {
    //         //   title: "Contrato gerador",
    //         //   path: "/Colaborador/Gerador/GD/ContratoGerador",
    //         //   show: true,
    //         // },
    //         // {
    //         //   title: "Informe rendimento",
    //         //   path: "/Colaborador/Gerador/GD/InformeRendimento",
    //         //   show: true,
    //         // },
    //         {
    //           title: "Ordem de pagamento",
    //           path: "/Colaborador/Gerador/GD/OrdemPagamento",
    //           show: true,
    //         },
    //       ],
    //     },
    //     // {
    //     //   title: "ML",
    //     //   itens: [
    //     //     {
    //     //       title: "Contrato gerador",
    //     //       path: "/Colaborador/Gerador/ML/ContratoGerador",
    //     //       show: true,
    //     //     },
    //     //     {
    //     //       title: "Informe rendimento",
    //     //       path: "/Colaborador/Gerador/ML/InformeRendimento",
    //     //       show: true,
    //     //     },
    //     //     {
    //     //       title: "Ordem de pagamento",
    //     //       path: "/Colaborador/Gerador/ML/OrdemPagamento",
    //     //       show: true,
    //     //     },
    //     //   ],
    //     // },
    //   ],
    // },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "Financeiro",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [
    //         {
    //           title: "Buscar pix",
    //           path: "/Colaborador/Financeiro/GD/BuscarPix",
    //           show: true,
    //         },
    //         {
    //           title: "Cadastro cupom",
    //           path: "/Colaborador/Financeiro/GD/CadastroCupom",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato bancário",
    //           path: "/Colaborador/Financeiro/GD/ExtratoBancario",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato por usina",
    //           path: "/Colaborador/Financeiro/GD/ExtratoBancario",
    //           show: true,
    //         },
    //         {
    //           title: "Faturas canceladas",
    //           path: "/Colaborador/Financeiro/GD/FaturasCanceladas",
    //           show: true,
    //         },
    //         {
    //           title: "Faturas parceladas",
    //           path: "/Colaborador/Financeiro/GD/FaturasParceladas",
    //           show: true,
    //         },
    //         {
    //           title: "Faturas refaturadas",
    //           path: "/Colaborador/Financeiro/GD/FaturasRefaturadas",
    //           show: true,
    //         },
    //         {
    //           title: "Gateway de pagamento",
    //           path: "/Colaborador/Financeiro/GD/GatewayPagamento",
    //           show: true,
    //         },
    //         {
    //           title: "Inadimplentes",
    //           path: "/Colaborador/Financeiro/GD/Inadimplentes",
    //           show: true,
    //         },
    //         {
    //           title: "Recebimento diário",
    //           path: "/Colaborador/Financeiro/GD/RecebimentoDiario",
    //           show: true,
    //         },
    //       ],
    //     },
    //     {
    //       title: "ML",
    //       itens: [
    //         {
    //           title: "Buscar pix",
    //           path: "/Colaborador/Financeiro/ML/BuscarPix",
    //           show: true,
    //         },
    //         {
    //           title: "Cadastro cupom",
    //           path: "/Colaborador/Financeiro/ML/CadastroCupom",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato bancário",
    //           path: "/Colaborador/Financeiro/ML/ExtratoBancario",
    //           show: true,
    //         },
    //         {
    //           title: "Extrato por usina",
    //           path: "/Colaborador/Financeiro/ML/ExtratoBancario",
    //           show: true,
    //         },
    //         {
    //           title: "Faturas canceladas",
    //           path: "/Colaborador/Financeiro/ML/FaturasCanceladas",
    //           show: true,
    //         },
    //         {
    //           title: "Faturas parceladas",
    //           path: "/Colaborador/Financeiro/ML/FaturasParceladas",
    //           show: true,
    //         },
    //         {
    //           title: "Faturas refaturadas",
    //           path: "/Colaborador/Financeiro/ML/FaturasRefaturadas",
    //           show: true,
    //         },
    //         {
    //           title: "Gateway de pagamento",
    //           path: "/Colaborador/Financeiro/ML/GatewayPagamento",
    //           show: true,
    //         },
    //         {
    //           title: "Inadimplentes",
    //           path: "/Colaborador/Financeiro/ML/Inadimplentes",
    //           show: true,
    //         },
    //         {
    //           title: "Recebimento diário",
    //           path: "/Colaborador/Financeiro/ML/RecebimentoDiario",
    //           show: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "ODC",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [
    //         {
    //           title: "Abrir ODC",
    //           path: "/Colaborador/ODC/GD/AbrirODC",
    //           show: true,
    //         },
    //         {
    //           title: "Auditar ODC",
    //           path: "/Colaborador/ODC/GD/AuditarODC",
    //           show: true,
    //         },
    //         {
    //           title: "Buscar XML distribuidora",
    //           path: "/Colaborador/ODC/GD/BuscarXML",
    //           show: true,
    //         },
    //         {
    //           title: "Emitir ODC",
    //           path: "/Colaborador/ODC/GD/EmitirODC",
    //           show: true,
    //         },
    //         {
    //           title: "Faturar ODC",
    //           path: "/Colaborador/ODC/GD/FaturarODC",
    //           show: true,
    //         },
    //         {
    //           title: "Listar ODC",
    //           path: "/Colaborador/ODC/GD/ListarODC",
    //           show: true,
    //         },
    //       ],
    //     },
    //     {
    //       title: "ML",
    //       itens: [
    //         {
    //           title: "Abrir ODC",
    //           path: "/Colaborador/ODC/ML/AbrirODC",
    //           show: true,
    //         },
    //         {
    //           title: "Auditar ODC",
    //           path: "/Colaborador/ODC/ML/AuditarODC",
    //           show: true,
    //         },
    //         {
    //           title: "Buscar XML distribuidora",
    //           path: "/Colaborador/ODC/ML/BuscarXML",
    //           show: true,
    //         },
    //         {
    //           title: "Emitir ODC",
    //           path: "/Colaborador/ODC/ML/EmitirODC",
    //           show: true,
    //         },
    //         {
    //           title: "Faturar ODC",
    //           path: "/Colaborador/ODC/ML/FaturarODC",
    //           show: true,
    //         },
    //         {
    //           title: "Listar ODC",
    //           path: "/Colaborador/ODC/ML/ListarODC",
    //           show: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "Relatórios",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [
    //         {
    //           title: "Gestão de auditorias",
    //           path: "/Colaborador/Relatorios/GD/GestaoAuditorias",
    //           show: true,
    //         },
    //       ],
    //     },
    //     {
    //       title: "ML",
    //       itens: [
    //         {
    //           title: "Gestão de auditorias",
    //           path: "/Colaborador/Relatorios/ML/GestaoAuditorias",
    //           show: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "DEV",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [],
    //     },
    //     {
    //       title: "ML",
    //       itens: [],
    //     },
    //   ],
    // },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "Qualidade",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [],
    //     },
    //     {
    //       title: "ML",
    //       itens: [],
    //     },
    //   ],
    // },
    // {
    //   icon: () => <DashboardIcon />,
    //   title: "Tutoriais",
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       title: "GD",
    //       itens: [],
    //     },
    //     {
    //       title: "ML",
    //       itens: [],
    //     },
    //   ],
    // },
  ];

  return routes;
};

export default ColaboradorRoutes;
