import { EnumTipoParceiro, EnumTipoParceiroNumericMap } from "../enums/EnumTipoParceiro.enum";
import { ICreateAssociado } from "../models/Associado";
import { ICreateColaborador } from "../models/Colaborador/Colaborador";
import { DadosPagamentos, IDadosGerador } from "../models/Gerador/Cadastro/DadosPagamentoGerador";
import { IFormCreateGeradorPessoaFisica, IFormCreateGeradorPessoaJuridica, IFormUpdateGeradorPessoaFisica, IFormUpdateGeradorPessoaJuridica } from "../models/Gerador/Cadastro/Gerador";
import { iResponsavelIndicacao } from "../models/Gerador/Cadastro/ResponsavelIndicacao";
import { IResponsavelLegal } from "../models/Gerador/Cadastro/ResponsavelLegal";
import { formatValueStringInFloat } from "./moneyFormatter";

export const dataCreateAssociado = (model:any):ICreateAssociado => {

    return {
        razaoSocial: model?.razaosocial,
        cnpj: model?.cnpj,
        cep: model?.cep,
        uf: model?.uf,
        cidade: model?.cidade,
        bairro: model?.bairro,
        logradouro: model?.logradouro,
        numero: model?.numero,
        complemento: model?.complemento,
        responsaveisLegais: model?.dadosResponsavelLegal,
        responsavelComunicacao: model.responsavelComunicacao,
        percentualComissao: model?.percentualcomissao,
        comissaoPorExtenso: model?.comissaoporextenso,
        vigenciaContrato: model?.vigenciacontrato
          ? new Date(model?.vigenciacontrato)
          : undefined,
        banco: model?.banco,
        agencia: model?.agencia,
        conta: model?.conta,
        cnpjFavorecido: model?.cnpjfavorecido,
        favorecido: model?.favorecido,
        tipoChavePix: model?.tipochavepix,
        chavePix: model?.chavepix,
        enumConvenio: model?.enumconvenio !== undefined? 
          Number(model?.enumconvenio): undefined,
        backofficeId: model?.backoffice,
        isMaster: model?.ismaster? 
        Number(model?.ismaster) ===
          EnumTipoParceiroNumericMap[EnumTipoParceiro.associadoMaster]: undefined,
        limiteDesconto: model?.limitedesconto,
        statusContrato: model?.statuscontrato,
        ultimaAlteracaoStatus: model?.ultimaalteracaostatus? 
          new Date(model?.ultimaalteracaostatus): undefined,
        dataAssinaturaContrato: model?.dataassinaturacontrato? 
          new Date(model?.dataassinaturacontrato): undefined,
        uuidContrato: model?.uuidcontrato,
        cupomBoasVindas: model?.cupomboasvindas? 
          Number(model?.cupomboasvindas) : undefined,
        user: model?.login,
        password: model?.senha,
        userEmail: model?.login,
        userCelular: "",
        linkCaptador: model?.linkcaptador,
        captadorMasterId: model.user?.idCaptador
    }
}

export const dataCreateColaborador = (model:any):ICreateColaborador => {
  return{
    empresa: model?.empresa,
    numeroRegistro: model?.numeroregistro,
    situacao: model?.situacao,
    condicao: model?.condicao,
    dataAfastamento: model?.dataafastamento? 
    new Date(model?.dataafastamento): null,
    nome: model?.nome,
    dataNascimento: model?.datanascimento?
    new Date(model?.datanascimento): null,
    sexo: model?.sexo,
    estadoCivil: model?.estadocivil,
    grauInstrucao: model?.grauinstrucao,
    municipioNascimento: model?.municipionascimento,
    nacionalidade: model?.nacionalidade,
    dataAdmissao: model?.dataadmissao? 
    new Date(model.dataadmissao): null,
    cep: model?.cep,
    uf: model?.uf === undefined? null: model?.uf,
    cidade: model?.cidade === undefined? null: model?.cidade,
    bairro: model?.bairro,
    logradouro: model?.logradouro,
    numero: model?.numero,
    celularParaContato: model?.telefone,
    emailParaContato: model?.email,
    banco: model?.banco,
    agencia: model?.agencia,
    conta: model?.conta,
    cpFFavorecido: model?.cpffavorecido,
    favorecido: model?.favorecido,
    tipoChavePix: model?.tipochavepix,
    chavePix: model?.chavepix,
    salarioBase: model?.salariobase,
    funcao: 0,
    partidoSindicato: model?.partidosindicato,
    boolPeriodoExperiencia: model?.boolperiodoexperiencia,
    inicioPeriodoExperiencia: model?.inicioperiodoexperiencia,
    fimPeriodoExperiencia: model?.fimperiodoexperiencia,
    boolIncideINSS: model?.boolincideinss,
    boolIncideIRF: model?.boolincideirf,
    boolIncideFGTS: model?.boolincidefgts,
    enumContribuicaoSindical: model?.enumcontribuicaosindical,
    enumTipoVaga: model?.enumtipovaga,
    enumHorasSemanais: model?.enumhorassemanais,
    boolPlanoSaude: model?.boolplanosaude,
    boolPlanoOdontologico: model?.boolplanoodontologico,
    enumModalidadePlanoSaude: model?.enummodalidadeplanosaude,
    enumModalidadePlanoOdontologico: model?.enummodalidadeplanoodontologico,
    boolPontoEletronico: model?.boolpontoeletronico,
    boolRecebeComissao: model?.boolrecebecomissao,
    enumTipoComissao: model?.enumtipocomissao,
    percentualComissao: model?.percentualcomissao,
    valorFixoComissao: model?.valorfixocomissao,
    boolRecebeVR: model?.boolrecebevr,
    valorDiaVR: model?.valordiavr,
    boolRecebeVA: model?.boolrecebeva,
    valorDiaVA: model?.valordiava,
    boolRecebeCombustivel: model?.boolrecebecombustivel,
    valorDiaCombustivel: model?.valordiacombustivel,
    boolRecebeVT: model?.boolrecebevt,
    enumTipoVT: model?.enumtipovt,
    linhaOnibus: model.linhaOnibus,
    valorDiaVT: model.valorDiaVT,
    user: model.login,
    password: model?.senha,
    userEmail: model?.email,
    userCelular: model?.telefone
  }
}

export const dataCreateGeradorPessoaJuridica = (model:any):IFormCreateGeradorPessoaJuridica => {
  return{
    nomeDadosAcesso: (model?.dadosResponsavelLegal ?? [{nome:""}])[0].nome,
    razaoSocialNome: model?.razaosocialnome,
    nomeGerador: model?.razaosocialnome,
    cnpjcpf: model?.cpfcnpj,
    instalacao: model?.instalacao,
    sigla: model?.sigla,
    cep: model?.cep,
    uf: model?.uf,
    cidade: model?.cidade,
    bairro: model?.bairro,
    logradouro: model?.logradouro,
    numero: model?.numero,
    complemento: model?.complemento,
    responsavelLegal:(model?.dadosResponsavelLegal ?? []).map((x:IResponsavelLegal) => {
      const {
        cpf,
        cep,
        uf,
        ...rest
      } = {...x, CPF: x.cpf, CEP: x.cep, UF: x.uf}

      return rest;
    }),
    nomeResponsavelComunicacao: model?.nomeresponsavelcomunicacao,
    emailResponsavelComunicacao: model?.emailresponsavelcomunicacao,
    celularResponsavelComunicacao: model?.celularresponsavelcomunicacao,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.operador,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): null,
    tarifaVigenteContrato: model?.tarifavigentecontrato?
    formatValueStringInFloat(model?.tarifavigentecontrato, 4): null,//4 casas
    potencia: model?.potencia?
    formatValueStringInFloat(model?.potencia, 1): null,//1 casa
    medidaCorrente: model?.medidacorrente,
    performaceAlvo: model?.performacealvo?
    formatValueStringInFloat(model?.performacealvo): null,
    potenciaExtenso: model?.potenciaextenso,
    custoFixoExtenso: model?.custofixoextenso,
    vigenciaContratual: model?.vigenciacontratual,//max12 min120 ??
    dataLimiteConexao: model?.datalimiteconexao? 
    new Date(model?.datalimiteconexao): null,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador === undefined? null: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato? new Date(model.dataassinaturacontrato): null,
    uuidContrato: null,
    responsavelIndicacaos: (model?.responsavelIndicacaos ?? []).map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): null /*2 casas depois da vírgula;
        -Percentual de desconto da tarifa for entre 62% até 65% o campo tem comissão não pode ser possível alterar para sim.
        -Limitado = 2,0% */
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao,
    agenciaPagamentoGerador: model.agenciapagamentogerador,
    cnpjFavorecidoPagamentoGerador: model.cnpjfavorecidopagamentogerador,
    bancoPagamentoGerador: model.bancopagamentogerador,
    tipoChavePixPagamentoGerador: model.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model.favorecidopagamentogerador,
    contaPagamentoGerador: model.contapagamentogerador,
    chavePixPagamentoGerador: model.chavepixpagamentogerador,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa:model.dadospagamentos[0].tarifagerador !== undefined? (model?.dadospagamentos ?? [])
    .map((x:DadosPagamentos) => { 
      return {
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }): [],
    emailDadosAcesso: model?.email,
    celularDadosAcesso: model?.telefone,
    whatsappDadosAcesso: model?.whatsapp
  }
}

export const dataCreateGeradorPessoaFisica = (model:any):IFormCreateGeradorPessoaFisica => {
  return{
    nomeDadosAcesso: model?.nomeresponsavelusina,
    nomeUsina: model?.nomeusina,
    nomeGerador: model?.nomeusina,
    cpf: model?.cpf,
    instalacao: model?.instalacao,
    sigla: model?.sigla,
    cep: model?.cep,
    uf: model?.uf,
    cidade: model?.cidade,
    bairro: model?.bairro,
    logradouro: model?.logradouro,
    numero: model?.numero,
    complemento: model?.complemento,
    nomeResponsavelUsina: model?.nomeresponsavelusina,
    emailResponsavelUsina: model?.emailresponsavelusina,
    celularResponsavelUsina: model?.celularresponsavelusina,
    cpfResponsavelUsina: model?.cpfresponsavelusina,
    cepResponsavelUsina: model?.cepresponsavelusina,
    ufResponsavelUsina: model?.ufresponsavelusina,
    cidadeResponsavelUsina: model?.cidaderesponsavelusina,
    bairroResponsavelUsina: model?.bairroresponsavelusina,
    numeroResponsavelUsina: model?.numeroresponsavelusina,
    complementoResponsavelUsina: model?.complementoresponsavelusina,
    logradouroResponsavelUsina: model?.logradouroresponsavelusina,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.operador,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): null,
    tarifaVigenteContrato: model?.tarifavigentecontrato?
    formatValueStringInFloat(model?.tarifavigentecontrato, 4): null,//4 casas
    potencia: model?.potencia?
    formatValueStringInFloat(model?.potencia, 1): null,//1 casa
    medidaCorrente: model?.medidacorrente,
    performaceAlvo: model?.performacealvo?
    formatValueStringInFloat(model?.performacealvo): null,
    potenciaExtenso: model?.potenciaextenso,
    custoFixoExtenso: model?.custofixoextenso,
    vigenciaContratual: model?.vigenciacontratual,//max12 min120 ??
    dataLimiteConexao: model?.datalimiteconexao? 
    new Date(model?.datalimiteconexao): null,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador === undefined? null: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato? new Date(model.dataassinaturacontrato): null,
    uuidContrato: null,
    responsavelIndicacaos: (model?.responsavelIndicacaos ?? []).map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): null
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao,
    agenciaPagamentoGerador: model.agenciapagamentogerador,
    cnpjFavorecidoPagamentoGerador: model.cnpjfavorecidopagamentogerador,
    bancoPagamentoGerador: model.bancopagamentogerador,
    tipoChavePixPagamentoGerador: model.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model.favorecidopagamentogerador,
    contaPagamentoGerador: model.contapagamentogerador,
    chavePixPagamentoGerador: model.chavepixpagamentogerador,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa:model.dadospagamentos[0].tarifagerador !== undefined? (model?.dadospagamentos ?? [])
    .map((x:DadosPagamentos) => { 
      return {
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }): [],
    emailDadosAcesso: model?.email,
    celularDadosAcesso: model?.telefone,
    whatsappDadosAcesso: model?.whatsapp
  }
}

export const dataUpdateGeradorPessoaJuridica = (model:any):IFormUpdateGeradorPessoaJuridica => {
  return{
    nomeDadosAcesso: model?.dadosResponsavelLegal?.length === 0?undefined: (model?.dadosResponsavelLegal ?? [{nome:""}])[0].nome,
    razaoSocialNome: model?.razaosocialnome? model?.razaosocialnome: undefined,
    nomeGerador: model?.razaosocialnome? model?.razaosocialnome: undefined,
    cnpjcpf: model?.cpfcnpj? model?.cpfcnpj: undefined,
    instalacao: model?.instalacao? model?.instalacao: undefined,
    sigla: model?.sigla? model?.sigla: undefined,
    cep: model?.cep? model?.cep: undefined,
    uf: model?.uf? model?.uf: undefined,
    cidade: model?.cidade? model?.cidade: undefined,
    bairro: model?.bairro? model?.bairro: undefined,
    logradouro: model?.logradouro? model?.logradouro: undefined,
    numero: model?.numero? model?.numero: undefined,
    complemento: model?.complemento? model?.complemento: undefined,
    responsavelLegal: (!model.dadosResponsavelLegal || model.dadosResponsavelLegal.length === 0)?undefined: model.dadosResponsavelLegal.map((x: IResponsavelLegal) => {
        const { cpf, cep, uf, ...rest } = { ...x, CPF: x.cpf, CEP: x.cep, UF: x.uf };
        return rest;
    }),
    nomeResponsavelComunicacao: model?.nomeresponsavelcomunicacao? model?.nomeresponsavelcomunicacao: undefined,
    emailResponsavelComunicacao: model?.emailresponsavelcomunicacao? model?.emailresponsavelcomunicacao: undefined,
    celularResponsavelComunicacao: model?.celularresponsavelcomunicacao? model?.celularresponsavelcomunicacao: undefined,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.operador? model.operador: undefined,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): undefined,
    tarifaVigenteContrato: model?.tarifavigentecontrato?
    formatValueStringInFloat(model?.tarifavigentecontrato, 2): undefined,//2 casas
    potencia: model?.potencia?
    formatValueStringInFloat(model?.potencia, 1): undefined,//1 casa
    medidaCorrente: model?.medidacorrente,
    performaceAlvo: model?.performacealvo?
    formatValueStringInFloat(model?.performacealvo): undefined,
    potenciaExtenso: model?.potenciaextenso? model?.potenciaextenso: undefined,
    custoFixoExtenso: model?.custofixoextenso? model?.custofixoextenso: undefined,
    vigenciaContratual: model?.vigenciacontratual? model?.vigenciacontratual: undefined,//max12 min120 ??
    dataLimiteConexao: model?.datalimiteconexao? 
    new Date(model?.datalimiteconexao): undefined,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato? new Date(model?.dataassinaturacontrato): undefined,
    uuidContrato: model.uuidcontrato,
    responsavelIndicacaos:(!model?.responsavelIndicacaos || model?.responsavelIndicacaos?.length === 0)?undefined: model?.responsavelIndicacaos.map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): undefined 
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao? model?.agenciaresponsavelindicacao: undefined,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao? model?.bancoresponsavelindicacao: undefined,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao? model?.contaresponsavelindicacao: undefined,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao? model?.cnpjfavorecidoresponsavelindicacao: undefined,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao? model?.chavepixresponsavelindicacao: undefined,
    agenciaPagamentoGerador: model.agenciapagamentogerador? model?.agenciapagamentogerador: undefined,
    cnpjFavorecidoPagamentoGerador: model.cnpjfavorecidopagamentogerador? model?.cnpjfavorecidopagamentogerador: undefined,
    bancoPagamentoGerador: model.bancopagamentogerador? model?.bancopagamentogerador: undefined,
    tipoChavePixPagamentoGerador: model.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model.favorecidopagamentogerador? model?.favorecidopagamentogerador: undefined,
    contaPagamentoGerador: model.contapagamentogerador? model?.contapagamentogerador: undefined,
    chavePixPagamentoGerador: model.chavepixpagamentogerador? model?.chavepixpagamentogerador: undefined,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao? model?.favorecidoresponsavelindicacao: undefined,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa: (!model?.dadospagamentos || model?.dadospagamentos?.length === 0)?undefined: model?.dadospagamentos?.map((x:DadosPagamentos) => { 
      return {
        id: x?.id,
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }),
    emailDadosAcesso: model?.email? model?.email: undefined,
    celularDadosAcesso: model?.telefone? model?.telefone: undefined,
    whatsappDadosAcesso: model?.whatsapp? model?.whatsapp: undefined
  }
}

export const dataUpdateGeradorPessoaFisica = (model:any):IFormUpdateGeradorPessoaFisica => {
  return{
    nomeDadosAcesso: model?.nomeresponsavelusina? model?.nomeresponsavelusina: undefined,
    nomeUsina: model?.nomeusina? model?.nomeusina: undefined,
    nomeGerador: model?.nomeusina? model?.nomeusina: undefined,
    cpf: model?.cpf? model?.cpf: undefined,
    instalacao: model?.instalacao? model?.instalacao: undefined,
    sigla: model?.sigla? model?.sigla: undefined,
    cep: model?.cep? model?.cep: undefined,
    uf: model?.uf? model?.uf: undefined,
    cidade: model?.cidade? model?.cidade: undefined,
    bairro: model?.bairro? model?.bairro: undefined,
    logradouro: model?.logradouro? model?.logradouro: undefined,
    numero: model?.numero? model?.numero: undefined,
    complemento: model?.complemento? model?.complemento: undefined,
    nomeResponsavelUsina: model.nomeresponsavelusina || undefined,
    emailResponsavelUsina: model.emailresponsavelusina || undefined,
    celularResponsavelUsina: model.celularresponsavelusina || undefined,
    cpfResponsavelUsina: model.cpfresponsavelusina || undefined,
    cepResponsavelUsina: model.cepresponsavelusina || undefined,
    ufResponsavelUsina: model.ufresponsavelusina || undefined,
    cidadeResponsavelUsina: model.cidaderesponsavelusina || undefined,
    bairroResponsavelUsina: model.bairroresponsavelusina || undefined,
    numeroResponsavelUsina: model.numeroresponsavelusina || undefined,
    complementoResponsavelUsina: model.complementoresponsavelusina || undefined,
    logradouroResponsavelUsina: model.logradouroresponsavelusina || undefined,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.operador? model.operador: undefined,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): undefined,
    tarifaVigenteContrato: model?.tarifavigentecontrato?
    formatValueStringInFloat(model?.tarifavigentecontrato, 2): undefined,//2 casas
    potencia: model?.potencia?
    formatValueStringInFloat(model?.potencia, 1): undefined,//1 casa
    medidaCorrente: model?.medidacorrente,
    performaceAlvo: model?.performacealvo?
    formatValueStringInFloat(model?.performacealvo): undefined,
    potenciaExtenso: model?.potenciaextenso? model?.potenciaextenso: undefined,
    custoFixoExtenso: model?.custofixoextenso? model?.custofixoextenso: undefined,
    vigenciaContratual: model?.vigenciacontratual? model?.vigenciacontratual: undefined,//max12 min120 ??
    dataLimiteConexao: model?.datalimiteconexao? 
    new Date(model?.datalimiteconexao): undefined,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato? new Date(model?.dataassinaturacontrato): undefined,
    uuidContrato: model.uuidcontrato,
    responsavelIndicacaos:(!model?.responsavelIndicacaos || model?.responsavelIndicacaos?.length === 0)?undefined: model?.responsavelIndicacaos.map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): undefined /*2 casas depois da vírgula;
        -Percentual de desconto da tarifa for entre 62% até 65% o campo tem comissão não pode ser possível alterar para sim.
        -Limitado = 2,0% */
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao? model?.agenciaresponsavelindicacao: undefined,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao? model?.bancoresponsavelindicacao: undefined,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao? model?.contaresponsavelindicacao: undefined,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao? model?.cnpjfavorecidoresponsavelindicacao: undefined,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao? model?.chavepixresponsavelindicacao: undefined,
    agenciaPagamentoGerador: model.agenciapagamentogerador? model?.agenciapagamentogerador: undefined,
    cnpjFavorecidoPagamentoGerador: model.cnpjfavorecidopagamentogerador? model?.cnpjfavorecidopagamentogerador: undefined,
    bancoPagamentoGerador: model.bancopagamentogerador? model?.bancopagamentogerador: undefined,
    tipoChavePixPagamentoGerador: model.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model.favorecidopagamentogerador? model?.favorecidopagamentogerador: undefined,
    contaPagamentoGerador: model.contapagamentogerador? model?.contapagamentogerador: undefined,
    chavePixPagamentoGerador: model.chavepixpagamentogerador? model?.chavepixpagamentogerador: undefined,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao? model?.favorecidoresponsavelindicacao: undefined,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa: (!model?.dadospagamentos || model?.dadospagamentos?.length === 0)?undefined: model?.dadospagamentos?.map((x:DadosPagamentos) => { 
      return {
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }),
    emailDadosAcesso: model?.email? model?.email: undefined,
    celularDadosAcesso: model?.telefone? model?.telefone: undefined,
    whatsappDadosAcesso: model?.whatsapp? model?.whatsapp: undefined
  }
}
