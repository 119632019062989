import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../hooks/useAuth";
import * as C from "./style";
import { ICreateAssociado } from "../../../../../models/Associado";
import * as service from "../../../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../../../services/api/ColaboradorService";
import { AxiosError } from "axios";
import { IBackofficeListItem } from "../../../../../models/Colaborador/Colaborador";
import { DadosContrato, IFormDadosContrato } from "./Components/DadosContrato";
import { IFormResponsavelLegal, ReponsavelLegal } from "./Components/ReponsavelLegal";
import { IFormResponsavelComunicacao, ResponsavelComunicacao } from "./Components/ResponsavelComunicacao";
import { ComissaoPagamento, IFormComissaoPagamento } from "./Components/ComissaPagamento";
import { IFormInformacaoAssociado, InformacaoAssociado } from "./Components/InformacaoAssociado";
import { IFormStatusContrato, StatusContrato } from "./Components/StatusContrato";
import { DadosAcesso, IFormDadosAcesso } from "./Components/DadosAcesso";
import { toastMessage } from "../../../../../utils/toastMessage";
import { dataCreateAssociado } from "../../../../../utils/returnNewData";

const NovoAssociado = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [dadosContrato, setDadosContrato] = useState<IFormDadosContrato | undefined>(undefined)
  const [dadosResponsavelLegal, setDadosResponsavelLegal] = useState<IFormResponsavelLegal[] | []>([])
  const [dadosResponsavelComunicacao, setDadosResponsavelComunicacao] = useState<IFormResponsavelComunicacao | undefined>(undefined)
  const [dadosComissaoPagamento, setDadosComissaoPagamento] = useState<IFormComissaoPagamento | undefined>(undefined)
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [ dadosInformacaoAssociado, setDadosInformacaoAssociado ] = useState<IFormInformacaoAssociado | undefined>(undefined);
  const [ dadosStatusContrato, setDadosStatusContrato ] = useState<IFormStatusContrato | undefined>(undefined)
  const [ dadosAcesso, setDadosAcesso ] = useState<IFormDadosAcesso | undefined>(undefined)

  const getBackoffices = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";

        toastMessage("warning", errorMessage)
      });
  };

  const createAssociado = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      ...dadosContrato, 
      ...dadosAcesso, 
      ...dadosComissaoPagamento, 
      dadosResponsavelLegal,
      ...dadosResponsavelComunicacao,
      ...dadosStatusContrato,
      ...dadosInformacaoAssociado,
      user
    }
    var createAssociadoData: ICreateAssociado = dataCreateAssociado(data)

    await service
      .CreateAssociado(createAssociadoData)
      .then(async (_) => {
        toastMessage("success", "Adicionado com sucesso!")
        navigate("/Parceiro/Associados");
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        toastMessage("warning", errorMessage)

      });
  };

  useEffect(() => {
    getBackoffices();
  }, []);

  const onSaveDadosContratos = (data:IFormDadosContrato | undefined) => {
    setDadosContrato(data)
  }

  const onSaveDadosResponsaveLegal = (data:IFormResponsavelLegal[]) => {
    setDadosResponsavelLegal(data)
  }

  const onSaveDadosResponsavelComunicacao = (data:IFormResponsavelComunicacao) => {
    setDadosResponsavelComunicacao(data)
  }

  const onSaveDadosComissaoPagamento = (data:IFormComissaoPagamento) => {
    setDadosComissaoPagamento(data)
  }

  const onSaveInformacaoAssociado = (data:IFormInformacaoAssociado) => {
    setDadosInformacaoAssociado(data)
  }

  const onSaveStatusContrato = (data:IFormStatusContrato) => {
    setDadosStatusContrato(data)
  }

  const onSaveDadosAcesso = (data:IFormDadosAcesso) => {
    setDadosAcesso(data)
  }

  return (
    <C.Container onSubmit={createAssociado}>
      <DadosContrato
        onSave={onSaveDadosContratos}
        onChange={onSaveDadosContratos}
        onUpdate={onSaveDadosContratos}
      />
      <ReponsavelLegal
        onSave={onSaveDadosResponsaveLegal}
        onChange={onSaveDadosResponsaveLegal}
        onUpdate={onSaveDadosResponsaveLegal}
      >
          <ResponsavelComunicacao
            onSave={onSaveDadosResponsavelComunicacao}
            onChange={onSaveDadosResponsavelComunicacao}
            onUpdate={onSaveDadosResponsavelComunicacao}
          />
      </ReponsavelLegal>
      <ComissaoPagamento
          onSave={onSaveDadosComissaoPagamento}
          onChange={onSaveDadosComissaoPagamento}
          onUpdate={onSaveDadosComissaoPagamento}
      />
      <InformacaoAssociado
          onSave={onSaveInformacaoAssociado}
          onChange={onSaveInformacaoAssociado}
          onUpdate={onSaveInformacaoAssociado}
          backOffices={backoffices}
      />
      <StatusContrato
        onSave={onSaveStatusContrato}
        onChange={onSaveStatusContrato}
        onUpdate={onSaveStatusContrato}
      />
      <DadosAcesso
        onSave={onSaveDadosAcesso}
        onChange={onSaveDadosAcesso}
        onUpdate={onSaveDadosAcesso} 
      />
    </C.Container>
  );
};

export default NovoAssociado;
