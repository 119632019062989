import { FC, useReducer } from "react";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as C from "../style";
import { Button, TextField } from "@mui/material";
import { SectionAction } from "../../../Components/SectionAction";

enum FormDadosContrato {
  RAZAOSOCIAL = "RAZAOSOCIAL",
  CNPJ = "CNPJ",
  CEP = "CEP",
  UF = "UF",
  CIDADE = "CIDADE",
  BAIRRO = "BAIRRO",
  LOGRADOURO = "LOGRADOURO",
  NUMERO = "NUMERO",
  COMPLEMENTO = "COMPLEMENTO",
}

export interface IFormDadosContrato {
  razaosocial: string;
  cnpj: string;
  cep: string;
  uf: string;
  cidade: string;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string;
}

type PropsDadosContrato = {
  onSave: (data: IFormDadosContrato) => void;
  onUpdate: (data: IFormDadosContrato) => void;
  onChange: (data: IFormDadosContrato) => void;
  data?: object | null;
};

export const DadosContrato: FC<PropsDadosContrato> = ({
  onSave,
  onUpdate,
  onChange,
}) => {
  const sendOnSave = (): void => onSave(state);
  const sendOnUpdate = (): void => onUpdate(state);
  const sendOnChange = (update: IFormDadosContrato): void => onChange(update);

  const setDataForm = (
    state: IFormDadosContrato,
    action: {
      type: FormDadosContrato;
      payload: string;
    }
  ): IFormDadosContrato => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    razaosocial: "",
    cnpj: "",
    cep: "",
    uf: "",
    cidade: "",
    bairro: "",
    logradouro: "",
    numero: "",
    complemento: "",
  });

  const handleInputChange =
    (field: FormDadosContrato) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: field, payload: event.target.value });
    };

  return (
    <RegisterCard title="Dados do contrato">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Razão social"
          required
          fullWidth
          onChange={handleInputChange(FormDadosContrato.RAZAOSOCIAL)}
        />
        <TextField
          label="CNPJ"
          required
          fullWidth
          onChange={handleInputChange(FormDadosContrato.CNPJ)}
        />
        <TextField
          label="CEP"
          required
          fullWidth
          onChange={handleInputChange(FormDadosContrato.CEP)}
        />
        <TextField
          required
          label="UF"
          onChange={handleInputChange(FormDadosContrato.UF)}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Cidade"
          required
          fullWidth
          onChange={handleInputChange(FormDadosContrato.CIDADE)}
        />
        <TextField
          label="Bairro"
          required
          fullWidth
          onChange={handleInputChange(FormDadosContrato.BAIRRO)}
        />
        <TextField
          label="Logradouro"
          required
          fullWidth
          onChange={handleInputChange(FormDadosContrato.LOGRADOURO)}
        />
        <TextField
          label="Número"
          type="number"
          required
          sx={{ width: "40%" }}
          onChange={handleInputChange(FormDadosContrato.NUMERO)}
        />
        <TextField
          label="Complemento"
          required
          fullWidth
          onChange={handleInputChange(FormDadosContrato.COMPLEMENTO)}
        />
      </C.FWStack>
    </RegisterCard>
  );
};
