import {
  ICooperativa,
  ICooperativaListItem,
  ICooperativaPaginated,
} from "../../models/Cooperativa";
import http from "../common/http-common";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../models/PaginatedList";
import {ICooperativaRelatoriosFilters} from "../../pages/private/Colaborador/Cooperativa";

export const GetCooperativas: () => Promise<
  AxiosResponse<ICooperativaListItem[], any>
> = (): Promise<AxiosResponse<ICooperativaListItem[], any>> => {
  return http.get<ICooperativaListItem[]>(
    `/Cooperativa/BuscaTodasCooperativas`
  );
};

export const GetAll: (
  pageNumber: number,
  pageSize: number,
  queryString?: string
) => Promise<AxiosResponse<IPaginatedList<ICooperativaPaginated>, any>> = (
  pageNumber: number,
  pageSize: number,
  queryString?: string
): Promise<AxiosResponse<IPaginatedList<ICooperativaPaginated>, any>> => {
  if (queryString === undefined) {
    queryString = "";
  }
  return http.get<IPaginatedList<ICooperativaPaginated>>(
    `/Cooperativa?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${queryString}`
  );
};

export const Create: (
  cooperativa: ICooperativa
) => Promise<AxiosResponse<ICooperativa, any>> = (
  cooperativa: ICooperativa
): Promise<AxiosResponse<ICooperativa, any>> => {
  return http.post<ICooperativa>(`/Cooperativa`, cooperativa);
};

export const GeraRelatorio = (filters: ICooperativaRelatoriosFilters) => {
  const queryParams: string[] = [];

  if (filters.selectedstatusproposta !== undefined) {
    queryParams.push(
      `SelectedStatusProposta=${filters.selectedstatusproposta}`
    );
  }

  if (filters.selectedparceiro !== undefined) {
    queryParams.push(`SelectedParceiro=${filters.selectedparceiro}`);
  }

  if (filters.selectedbackoffice) {
    queryParams.push(`SelectedBackOffice=${filters.selectedbackoffice}`);
  }

  if (filters.datainicio) {
    queryParams.push(`DataInicio=${filters.datainicio}`);
  }

  if (filters.datafim) {
    queryParams.push(`DataFim=${filters.datafim}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(
    `/Cooperativa/BuscaRelatorioCooperativa${queryString}`,
    {
      responseType: "blob",
    }
  );
};
