import { AxiosResponse } from "axios";
import {
  IFormCreateGeradorPessoaFisica,
  IFormCreateGeradorPessoaJuridica,
  IFormUpdateGeradorPessoaJuridica,
} from "../../models/Gerador/Cadastro/Gerador";
import { GeradorResponse } from "../../models/Gerador/GeradorResponse";
import { StatusUsina } from "../../models/Gerador/StatusContrato";
import { IPaginatedList } from "../../models/PaginatedList";
import http from "../common/http-common";
import {
  IUsinaListItem,
  UsinaTipoPessoaFisica,
  UsinaTipoPessoaJuridica,
} from "../../models/Gerador/Usina";
import { GeradorRelatorioResponse } from "../../models/Gerador/GeradorRelatorioResponse";

export const GetAll = (
    pageSize:number | null=null, 
    pageNumber:number | null=null, 
    search:string | null=null,
    isNotSendClickSign:boolean | null=null
    ) => {

    let url = '/Gerador/Usina';

    const params = [];
    
    if (pageSize) params.push(`PageSize=${pageSize}`);
    if (pageNumber) params.push(`PageNumber=${pageNumber}`);
    if (search) params.push(`Search=${search}`);
    if (isNotSendClickSign !== undefined) params.push(`isNotSendClickSign=${isNotSendClickSign}`);
    
    if (params.length > 0) {
        url += `?${params.join('&')}`;
    }

  return http.get<IPaginatedList<GeradorResponse>>(url);
};

export const GetOnePessaoJuridica = (id: string | null) => {
  return http.get<UsinaTipoPessoaJuridica>(
    `/Gerador/UsinaJuridicaById?id=${id}`
  );
};

export const GetOnePessaoFisica = (id: string | null) => {
  return http.get<UsinaTipoPessoaFisica>(`/Gerador/UsinaFisicaById?id=${id}`);
};

export const GetStatusUsina = () => {
  return http.get<StatusUsina>(`/Gerador/GetUsinaStatus`);
};

export const GetUsinasListItem = () => {
  return http.get<IUsinaListItem[]>(`/Gerador/BuscarTodasUsinasPorNome`);
};

export const CreateGeradorPessoaJuridica = (
  createGerador: IFormCreateGeradorPessoaJuridica
) => {
  return http.post<IFormCreateGeradorPessoaJuridica>(
    "/Gerador/UsinaJuridica",
    createGerador
  );
};

export const CreateGeradorPessoaFisica = (
  createGerador: IFormCreateGeradorPessoaFisica
) => {
  return http.post<IFormCreateGeradorPessoaFisica>(
    "/Gerador/UsinaFisica",
    createGerador
  );
};

export const UpdateGeradorPessoaJuridica = (
  updateGerador: UsinaTipoPessoaJuridica | undefined
) => {
  return http.put<UsinaTipoPessoaJuridica>(
    "/Gerador/UpdateUsinaJuridica",
    updateGerador
  );
};

export const UpdateGeradorPessoaFisica = (
  updateGerador: UsinaTipoPessoaFisica | undefined
) => {
  return http.put<UsinaTipoPessoaFisica>(
    "/Gerador/UpdateUsinaFisica",
    updateGerador
  );
};

export const GerarRelatorio = () => {
  return http.get<File>("/Gerador/BuscaRelatorioUsinas", {
    responseType: "blob",
  });
};
