import { Button, CircularProgress, TextField } from "@mui/material";
import RegisterCard from "../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../styles/appComponents";
import { useEffect, useReducer, useState } from "react";
import { IFormInformacoesContato } from "../../../../../../../models/Contratar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useObjectState } from "../../../../../../../hooks/useObjectState";
import { IFullGDContratoCliente } from "../../../../../../../models/GDContrato";
import * as service from "../../../../../../../services/api/UsuarioService";
import { ICreateUsuario } from "../../../../../../../models/Usuario";
import Swal from "sweetalert2";
import { AxiosError } from "axios";

type PropsDadosContato = {
  type: string;
  onChange: (value: IFormInformacoesContato) => void;
  dataContrato?: IFullGDContratoCliente;
  reload: () => void;
};

const DadosContato = (props: PropsDadosContato) => {
  const { dataContrato, reload } = props;

  const [insideLoading, setInsideLoading] = useState<boolean>(false);

  const { state, setObject, updateObject } =
    useObjectState<IFormInformacoesContato>({
      celular: "",
      email: "",
      login: "",
      senha: "",
    });

  useEffect(() => {
    props.onChange(state);
  }, [state]);

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        celular: dataContrato.celularUsuario ?? "",
        email: dataContrato.emailUsuario ?? "",
        login: dataContrato.loginUsuario ?? "",
        senha: "**********",
      });
    }
  }, [dataContrato]);

  const createClienteUser = async () => {
    setInsideLoading(true);
    if (state.celular == "" || state.email == "") {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Celular/Email não podem ser nulos.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      return;
    }

    var data: ICreateUsuario = {
      contratoClienteId: dataContrato!.id!,
      celular: state.celular,
      email: state.email,
    };

    await service
      .CreateCliente(data)
      .then(async () => {
        setInsideLoading(false);
        reload();
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        setInsideLoading(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao gerar o contrato.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <RegisterCard title="Dados de contato">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Celular"
          required
          fullWidth
          value={state.celular}
          onChange={(e) => updateObject({ celular: e.target.value })}
        />
        <TextField
          label="E-mail"
          required
          fullWidth
          value={state.email}
          onChange={(e) => updateObject({ email: e.target.value })}
        />
      </D.FWStack>
      {state.login ? (
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Login"
            required
            sx={{ width: "25%" }}
            value={state.login}
            onChange={(e) => updateObject({ login: e.target.value })}
          />
          <TextField
            label="Senha"
            required
            sx={{ width: "25%" }}
            value={state.senha}
            onChange={(e) => updateObject({ senha: e.target.value })}
          />

          {/* LÓGICA DE RESET DE SENHA NÃO IMPLEMENTADA */}
          {/* <D.ContainedButton $color="lightpink" variant="contained">
          Reset senha
        </D.ContainedButton> */}
        </D.FWStack>
      ) : (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            $color="pink"
            variant="contained"
            onClick={createClienteUser}
          >
            {insideLoading ? (
              <CircularProgress size={25} />
            ) : (
              <>Criar usuário</>
            )}
          </D.ContainedButton>
        </D.FWStack>
      )}

      {/* LÓGICA DE WHATSAPP NÃO IMPLEMENTADA */}
      {/* <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Whatsapp"
          required
          sx={{ width: "50%" }}
          onChange={(e) =>
            dispatch({
              type: formInformacoesTitular.CELULAR,
              payload: e.target.value,
            })
          }
        />
        <D.ContainedButton
          $color="pink"
          variant="contained"
          startIcon={<WhatsAppIcon />}
        >
          Enviar whatsapp
        </D.ContainedButton>
      </D.FWStack> */}
    </RegisterCard>
  );
};

export default DadosContato;
