import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../../../../../hooks/useAuth";
import {
  IFormInformacoesContato,
  IFormInformacoesEndereco,
  IFormInformacoesInformacoesContratoPF,
  IFormInformacoesInformacoesContratoPJ,
  IFormInformacoesOutrasInformacoes,
  IFormPreferencia,
} from "../../../../../../models/Contratar";
import * as D from "../../../../../../styles/appComponents";
import * as C from "../../style";
import InformacoesContratoPJContratar from "../components/cnpj/InformacoesContratoPJ";
import InformacoesProposta, {
  IFormInformacoesProposta,
} from "../components/cnpj/informacoesProposta";
import InformacoesResponsavelLegal, {
  IInformacoesResponsavelLegal,
} from "../components/cnpj/informacoesResponsavelLegal";
import EnderecoContratar from "../components/cpf/Endereco";
import InformacoesContratoPFContratar from "../components/cpf/InformacoesContratoPF";
import DadosContato from "../components/DadosContato";
import InformacoesTitularContratar, {
  IInformacoesTitularContratar,
} from "../components/InformacoesTitular";
import OutrasInformacoesContratar from "../components/OutrasInformacoes";
import PreferenciaContratar from "../components/Preferencia";
import * as service from "../../../../../../services/api/GDContratoClienteService";
import { AxiosError } from "axios";
import {
  IUpdateGDContratoClientePF,
  IUpdateGDContratoClientePJ,
  IFullGDContratoCliente,
} from "../../../../../../models/GDContrato";
import { CircularProgress } from "@mui/material";
import { SkeletonCreationCards } from "../../../../../../components/SkeletonLoads/CreationCards";

const EditarContrato = () => {
  const { user } = useAuth();
  const navigate: NavigateFunction = useNavigate();
  const { idContrato } = useParams();

  const [clickSignLoading, setClickSignLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [insideLoading, setInsideLoading] = useState<boolean>(false);

  const [data, setData] = useState<IFullGDContratoCliente>({
    isPF: false,
    id: "",
    idReferencial: null,
    createdAt: null,
    updatedAt: null,
    cep: "",
    uf: "",
    cidade: "",
    bairro: "",
    logradouro: "",
    numero: "",
    complemento: "",
    instalacao: "",
    numeroCliente: "",
    dataVencimento: null,
    conexao: null,
    tarifaDistribuidora: null,
    descontoTarifaDistribuidora: null,
    taxaDisponibilidade: null,
    mediaDozeMeses: null,
    franquia: null,
    valorAssinatura: null,
    tarifaUsina: null,
    potencia: null,
    valorMaximoInjecao: null,
    lote: null,
    economiaMensal: null,
    economiaAnual: null,
    usinaId: "",
    operadoraId: "",
    signatario: null,
    comissaoCaptador: null,
    gatewayPagamento: null,
    limiteInjecao: null,
    preferenciaRecebimento: null,
    apoioParceiro: "",
    dataGeracaoContrato: null,
    dataAssinatura: null,
    energiaAdicionalkWh: null,
    energiaAdicionalReais: null,
    status: null,
    whatsapp: "",
    emails: [],
    recebeTresDias: true,
    recebeSeteDias: true,
    recebeGeracao: true,
    recebeVencimento: true,
    preferenciaDiaRecebimento: true,
    possuiGeracaoEnergiaPropria: true,
    obdeceFranquia: true,
    preferenciaTitularidade: true,
    celularUsuario: "",
    emailUsuario: "",
    loginUsuario: "",
    nome: "",
    cpf: "",
    enumEstadoCivil: null,
    nacionalidade: "",
    profissao: "",
    razaoSocial: "",
    cnpj: "",
    responsaveisLegais: null,
    backofficeId: "",
    captadorId: "",
  });

  const [tipo, setTipo] = useState<"Fisica" | "Juridica" | undefined>(
    undefined
  );

  //#region - Informações do titular

  const [informacoesTitularContratar, setInformacoesTitularContratar] =
    useState<IInformacoesTitularContratar | undefined>(undefined);

  const onChangeInformacoesTitularContratar = (
    data: IInformacoesTitularContratar
  ): void => {
    setInformacoesTitularContratar(data);
  };

  //#endregion

  //#region - PF

  //#region - Endereço PF

  const [enderecoContratar, setEnderecoContratar] = useState<
    IFormInformacoesEndereco | undefined
  >(undefined);

  const onChangeEnderecoContratar = (data: IFormInformacoesEndereco): void => {
    setEnderecoContratar(data);
  };

  //#endregion

  //#region - Informacoes do contrato PF

  const [informacoesContratoContratarPF, setInformacoesContratoContratarPF] =
    useState<IFormInformacoesInformacoesContratoPF | undefined>(undefined);

  const onChangeInformacoesContratoContratarPF = (
    data: IFormInformacoesInformacoesContratoPF
  ): void => {
    setInformacoesContratoContratarPF(data);
  };

  //#endregion

  //#endregion - PF

  //#region - PJ

  //#region - Informações contrato PJ
  const [informacoesContratoContratarPJ, setInformacoesContratoContratarPJ] =
    useState<IFormInformacoesInformacoesContratoPJ | undefined>(undefined);

  const onChangeInformacoesContratoContratarPJ = (
    data: IFormInformacoesInformacoesContratoPJ
  ): void => {
    setInformacoesContratoContratarPJ(data);
  };
  //#endregion

  //#region - Informações responsável legal PJ
  const [informacoesResponsavelLegal, setInformacoesResponsavelLegal] =
    useState<IInformacoesResponsavelLegal | undefined>(undefined);

  const onChangeInformacoesResponsavelLegal = (
    data: IInformacoesResponsavelLegal
  ): void => {
    setInformacoesResponsavelLegal(data);
  };
  //#endregion

  //#region - Informações proposta PJ
  const [informacoesProposta, setInformacoesProposta] = useState<
    IFormInformacoesProposta | undefined
  >(undefined);

  const onChangeInformacoesProposta = (
    data: IFormInformacoesProposta
  ): void => {
    setInformacoesProposta(data);
  };
  //#endregion

  //#endregion

  //#region - Outras informações
  const [outrasInformacoesContratar, setOutrasInformacoesContratar] = useState<
    IFormInformacoesOutrasInformacoes | undefined
  >(undefined);

  const onChangeOutrasInformacoesContratar = (
    data: IFormInformacoesOutrasInformacoes
  ): void => {
    setOutrasInformacoesContratar(data);
  };

  //#endregion

  //#region - Preferências
  const [formPreferencia, setFormPreferencia] = useState<
    IFormPreferencia | undefined
  >(undefined);

  const onChangeDataIFormPreferencia = (data: IFormPreferencia): void => {
    setFormPreferencia(data);
  };

  //#endregion

  //#region - DadosContato
  const [formDadosContato, setFormDadosContato] = useState<
    IFormInformacoesContato | undefined
  >(undefined);

  const onChangeDataIFormDadosContato = (
    data: IFormInformacoesContato
  ): void => {
    setFormDadosContato(data);
  };

  //#endregion

  const handleValidForm = (form?: unknown): boolean => {
    if (!form) return true;

    const formValues: unknown[] = Object.values(form);
    const hasValue: boolean = formValues.some(
      (value) => value !== null && value !== undefined && value !== ""
    );
    const allFilled: boolean = formValues.every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    return hasValue ? allFilled : true;
  };

  const reload = () => {
    getFullContrato(idContrato!);
  };

  const getFullContrato = async (id: string): Promise<void> => {
    try {
      const [response] = await Promise.all([service.GetFullContratoById(id)]);
      setTipo(response.data.isPF ? "Fisica" : "Juridica");
      setData(response.data);
      setLoading(false);
    } catch (e: unknown) {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Ocorreu um erro ao buscar o contrato",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  useEffect((): void => {
    getFullContrato(idContrato!);
  }, []);

  const SalvaContrato = async () => {
    setInsideLoading(true);
    if (tipo === "Fisica") {
      var dataPF: IUpdateGDContratoClientePF = {
        id: idContrato!,
        nome: informacoesTitularContratar?.dataPF.nome,
        cpf: informacoesTitularContratar?.dataPF.cpf,
        enumEstadoCivil: Number(
          informacoesTitularContratar?.dataPF.estado_civil
        ),
        nacionalidade: informacoesTitularContratar?.dataPF.nacionalidade,
        profissao: informacoesTitularContratar?.dataPF.profissao,
        cep: enderecoContratar?.cep,
        uf: enderecoContratar?.uf,
        cidade: enderecoContratar?.cidade,
        bairro: enderecoContratar?.bairro,
        logradouro: enderecoContratar?.logradouro,
        numero: enderecoContratar?.numero,
        complemento: enderecoContratar?.complemento,
        instalacao: informacoesContratoContratarPF?.numeroInstalacao,
        numeroCliente: informacoesContratoContratarPF?.numeroCliente,
        dataVencimento: informacoesContratoContratarPF?.dataVencimento!,
        conexao: Number(informacoesContratoContratarPF?.conexao),
        tarifaDistribuidora: Number(
          informacoesContratoContratarPF?.tarifaDistribuidora
        ),
        descontoTarifaDistribuidora: Number(
          informacoesContratoContratarPF?.descontoTarifaDistribuidora
        ),
        taxaDisponibilidade: Number(
          informacoesContratoContratarPF?.taxaDisponibilidade
        ),
        mediaDozeMeses: Number(informacoesContratoContratarPF?.media12meses),
        franquia: Number(informacoesContratoContratarPF?.franquia),
        valorAssinatura: Number(
          informacoesContratoContratarPF?.valorAssinatura
        ),
        tarifaUsina: Number(informacoesContratoContratarPF?.tarifaUsina),
        potencia: Number(informacoesContratoContratarPF?.potencia),
        valorMaximoInjecao: Number(
          informacoesContratoContratarPF?.valorMaximoInjecao
        ),
        lote: Number(informacoesContratoContratarPF?.tarifaUsina),
        economiaMensal: Number(informacoesContratoContratarPF?.economiaMensal),
        economiaAnual: Number(informacoesContratoContratarPF?.economiaAnual),
        usinaId: outrasInformacoesContratar?.usina,
        operadoraId: outrasInformacoesContratar?.operadora,
        signatario: await convertEmptyStringsToNullAsync(
          outrasInformacoesContratar!.updateSiginatario!
        ),
        comissaoCaptador: Number(outrasInformacoesContratar?.comissaoCaptador),
        gatewayPagamento: Number(outrasInformacoesContratar?.gatewayPagamento),
        limiteInjecao: Number(outrasInformacoesContratar?.limiteInjecao),
        preferenciaRecebimento: Number(
          outrasInformacoesContratar?.prefRecebido
        ),
        apoioParceiro: outrasInformacoesContratar?.apoioParceiro,
        dataAssinatura: outrasInformacoesContratar?.dataAssinatura.toString(),
        whatsapp: formPreferencia?.whatsapp,
        recebeTresDias: formPreferencia?.tresdias,
        recebeSeteDias: formPreferencia?.setedias,
        recebeGeracao: formPreferencia?.geracao,
        recebeVencimento: formPreferencia?.vencimento,
        emails: formPreferencia?.updateEmails,
        preferenciaDiaRecebimento:
          formPreferencia?.preferenciadediasemrecebimentofatura,
        possuiGeracaoEnergiaPropria:
          formPreferencia?.possuigeracaoenergiapropria,
        obdeceFranquia: formPreferencia?.obedecefranquia,
        preferenciaTitularidade: formPreferencia?.preferenciatitularidadefatura,
        status: Number(outrasInformacoesContratar?.status),
        energiaAdicionalkWh: undefined,
        energiaAdicionalReais: undefined,
        dataGeracaoContrato: undefined,
      };

      var convertedDataPF = await convertEmptyStringsToNullAsync(dataPF);

      await service
        .UpdateGDContratoPessoaFisica(convertedDataPF)
        .then(async () => {
          setInsideLoading(false);
          navigate("/Parceiro/Contratos");
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Criado com sucesso!",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        })
        .catch((e: AxiosError) => {
          setInsideLoading(false);
          var errorMessage: string = e.response
            ? String(e.response?.data)
            : "Houve um erro ao gerar o contrato.";

          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "warning",
            title: errorMessage,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        });
    } else {
      var dataPJ: IUpdateGDContratoClientePJ = {
        id: idContrato!,
        razaoSocial: informacoesTitularContratar?.dataPJ.razaoSocial,
        cnpj: informacoesTitularContratar?.dataPJ.cnpj,
        instalacao: informacoesTitularContratar?.dataPJ.numeroInstalacao,
        cep: informacoesTitularContratar?.dataPJ.cep,
        uf: informacoesTitularContratar?.dataPJ.uf,
        cidade: informacoesTitularContratar?.dataPJ.cidade,
        bairro: informacoesTitularContratar?.dataPJ.bairro,
        logradouro: informacoesTitularContratar?.dataPJ.logradouro,
        numero: informacoesTitularContratar?.dataPJ.numero,
        complemento: informacoesTitularContratar?.dataPJ.complemento,
        numeroCliente: informacoesContratoContratarPJ?.numeroCliente,
        dataVencimento: informacoesContratoContratarPJ?.dataVencimento,
        conexao: Number(informacoesContratoContratarPJ?.conexao),
        tarifaDistribuidora: Number(
          informacoesContratoContratarPJ?.tarifaDistribuidora
        ),
        descontoTarifaDistribuidora: Number(
          informacoesContratoContratarPJ?.descontoTarifaDistribuidora
        ),
        taxaDisponibilidade: Number(
          informacoesContratoContratarPJ?.taxaDisponibilidade
        ),
        mediaDozeMeses: Number(informacoesContratoContratarPJ?.media12meses),
        franquia: Number(informacoesContratoContratarPJ?.franquia),
        valorAssinatura: Number(
          informacoesContratoContratarPJ?.valorAssinatura
        ),
        tarifaUsina: Number(informacoesContratoContratarPJ?.tarifaUsina),
        potencia: Number(informacoesContratoContratarPJ?.potencia),
        valorMaximoInjecao: Number(
          informacoesContratoContratarPJ?.valorMaximoInjecao
        ),
        lote: Number(informacoesContratoContratarPJ?.lote),
        economiaMensal: Number(informacoesContratoContratarPJ?.economiaMensal),
        economiaAnual: Number(informacoesContratoContratarPJ?.economiaAnual),
        responsaveisLegais: [
          {
            id: informacoesResponsavelLegal?.id!,
            nome: informacoesResponsavelLegal?.nome,
            email: informacoesResponsavelLegal?.email,
            celular: informacoesResponsavelLegal?.celular,
            cpf: informacoesResponsavelLegal?.cpf,
            enumEstadoCivil: Number(informacoesResponsavelLegal?.estado_civil),
            nacionalidade: informacoesResponsavelLegal?.nacionalidade,
            profissao: informacoesResponsavelLegal?.profissao,
          },
        ],
        usinaId: outrasInformacoesContratar?.usina,
        operadoraId: outrasInformacoesContratar?.operadora,
        signatario: await convertEmptyStringsToNullAsync(
          outrasInformacoesContratar!.updateSiginatario!
        ),
        comissaoCaptador: Number(outrasInformacoesContratar?.comissaoCaptador),
        gatewayPagamento: Number(outrasInformacoesContratar?.gatewayPagamento),
        limiteInjecao: Number(outrasInformacoesContratar?.limiteInjecao),
        preferenciaRecebimento: Number(
          outrasInformacoesContratar?.prefRecebido
        ),
        apoioParceiro: outrasInformacoesContratar?.apoioParceiro,
        dataAssinatura: outrasInformacoesContratar?.dataAssinatura.toString(),
        whatsapp: formPreferencia?.whatsapp,
        recebeTresDias: formPreferencia?.tresdias,
        recebeSeteDias: formPreferencia?.setedias,
        recebeGeracao: formPreferencia?.geracao,
        recebeVencimento: formPreferencia?.vencimento,
        emails: formPreferencia?.updateEmails,
        preferenciaDiaRecebimento:
          formPreferencia?.preferenciadediasemrecebimentofatura,
        possuiGeracaoEnergiaPropria:
          formPreferencia?.possuigeracaoenergiapropria,
        obdeceFranquia: formPreferencia?.obedecefranquia,
        preferenciaTitularidade: formPreferencia?.preferenciatitularidadefatura,
        status: Number(outrasInformacoesContratar?.status),
        energiaAdicionalkWh: undefined,
        energiaAdicionalReais: undefined,
      };

      var convertedDataPJ = await convertEmptyStringsToNullAsync(dataPJ);

      await service
        .UpdateGDContratoPessoaJuridica(convertedDataPJ)
        .then(async () => {
          setInsideLoading(false);
          navigate("/Parceiro/Contratos");
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "success",
            title: "Criado com sucesso!",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        })
        .catch((e: AxiosError) => {
          setInsideLoading(false);
          var errorMessage: string = e.response
            ? String(e.response?.data)
            : "Houve um erro ao gerar o contrato.";

          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "warning",
            title: errorMessage,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        });
    }
  };

  const EnviaContratoPraAssinaturaDigital = async () => {
    setClickSignLoading(true);

    await service
      .VerificaPodeEnviarContratoParaClickSign(idContrato!)
      .then(async (response) => {
        if (response.data) {
          EnviaContratoPraClickSign();
        } else {
          setClickSignLoading(false);
          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "warning",
            title:
              "É preciso preencher os dados para enviar este contrato para a assinatura digital.",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        }
      })
      .catch((e: AxiosError) => {
        setClickSignLoading(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao enviar o contrato para a ClickSign.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const EnviaContratoPraClickSign = async () => {
    await service
      .EnviaContratoParaClickSign(idContrato!)
      .then(async () => {
        setClickSignLoading(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Enviado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        setClickSignLoading(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao enviar o contrato para a ClickSign.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const convertEmptyStringsToNullAsync = async <T extends Record<string, any>>(
    obj: T
  ): Promise<T> => {
    const updatedObj = { ...obj };

    await Promise.all(
      (Object.keys(updatedObj) as (keyof T)[]).map(async (key) => {
        const value = updatedObj[key];
        if (typeof value === "string" && value === "") {
          updatedObj[key] = null as T[keyof T]; // Garante que `null` seja atribuído de forma segura.
        }
      })
    );

    return updatedObj;
  };

  return (
    <>
      {loading ? (
        <SkeletonCreationCards />
      ) : (
        <C.Container>
          <InformacoesTitularContratar
            type={tipo as string}
            dataContrato={data}
            onChange={onChangeInformacoesTitularContratar}
          />
          {tipo === "Fisica" ? (
            <>
              <EnderecoContratar
                type={tipo as string}
                dataContrato={data}
                onChange={onChangeEnderecoContratar}
              />
              <InformacoesContratoPFContratar
                type={tipo as string}
                dataContrato={data}
                onChange={onChangeInformacoesContratoContratarPF}
              />
            </>
          ) : (
            <>
              <InformacoesContratoPJContratar
                type={tipo as string}
                dataContrato={data}
                onChange={onChangeInformacoesContratoContratarPJ}
              />
              <InformacoesResponsavelLegal
                type={tipo as string}
                dataContrato={data}
                onChange={onChangeInformacoesResponsavelLegal}
              />
            </>
          )}
          <OutrasInformacoesContratar
            type={tipo as string}
            dataContrato={data}
            onChange={onChangeOutrasInformacoesContratar}
          />
          <PreferenciaContratar
            type={tipo as string}
            dataContrato={data}
            onChange={onChangeDataIFormPreferencia}
          />
          <DadosContato
            type={tipo as string}
            reload={reload}
            dataContrato={data}
            onChange={onChangeDataIFormDadosContato}
          />
          <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
            <D.ContainedButton
              $color="blue"
              onClick={EnviaContratoPraAssinaturaDigital}
            >
              {clickSignLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>Enviar para assinatura digital ClickSign</>
              )}
            </D.ContainedButton>
            <D.ContainedButton $color="lightpink" onClick={SalvaContrato}>
              {insideLoading ? <CircularProgress size={25} /> : <>Salvar</>}
            </D.ContainedButton>
          </D.FWStack>
        </C.Container>
      )}
    </>
  );
};

export default EditarContrato;
