import { FC, useReducer } from "react";
import { EnumConvenio, EnumConvenioNumericMap } from "../../../../../../enums/EnumConvenio.enum";
import { EnumTipoParceiro, EnumTipoParceiroNumericMap } from "../../../../../../enums/EnumTipoParceiro.enum";
import * as C from "../style";
import RegisterCard from "../../../../../../components/RegisterCard";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import { IBackofficeListItem } from "../../../../../../models/Colaborador/Colaborador";
import { SectionAction } from "../../../Components/SectionAction";

enum FormInformacaoAssociado{
    ISMASTER="ISMASTER",
    ENUMCONVENIO="ENUMCONVENIO",
    BACKOFFICE="BACKOFFICE",
    LIMITEDESCONTO="LIMITEDESCONTO"
}

export interface IFormInformacaoAssociado{
    ismaster:EnumTipoParceiro | undefined;
    enumconvenio:EnumConvenio | undefined;
    backoffice:string | undefined;
    limitedesconto:number | undefined;
}

type PropsInformacaoAssociado = {
    onSave: (data:IFormInformacaoAssociado) => void;
    onUpdate: (data:IFormInformacaoAssociado) => void;
    onChange: (data:IFormInformacaoAssociado) => void;
    data?: object | null;
    backOffices:IBackofficeListItem[];
}

export const InformacaoAssociado:FC<PropsInformacaoAssociado> = ({onSave,onUpdate,onChange,backOffices}) => {
    const sendOnSave = (): void => onSave(state);
    const sendOnUpdate = (): void => onUpdate(state);
    const sendOnChange = (update: IFormInformacaoAssociado): void => onChange(update);

    const setDataForm = (state: IFormInformacaoAssociado, action: {
        type: FormInformacaoAssociado;
        payload: string;
      }): IFormInformacaoAssociado => {
        const change = { ...state, [action.type.toLowerCase()]: action.payload };
        sendOnChange(change);
        return change;
    };

    const [state, dispatch] = useReducer(setDataForm, {
        ismaster: undefined,
        enumconvenio: undefined,
        limitedesconto:undefined,
        backoffice: ""
    });

    const handleInputChange = (field: FormInformacaoAssociado, event:React.ChangeEvent<HTMLInputElement> | any) => {
        dispatch({ type: field, payload: event.target.value });
    }

    return(
        <RegisterCard title="Informações do associado">
            <C.FWStack direction={"row"} spacing={2}>
            <TextField
                label="Tipo de associado"
                select
                fullWidth
                value={state.ismaster}
                onChange={(event) => handleInputChange(FormInformacaoAssociado.ISMASTER, event)}
            >
                {Object.values(EnumTipoParceiro)
                .filter((value) => value !== EnumTipoParceiro.parceiroMaster)
                .map((value) => (
                    <MenuItem key={value} value={EnumTipoParceiroNumericMap[value]}>
                    {value}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Limite de Desconto"
                fullWidth
                value={state?.limitedesconto}
                onChange={(event) => handleInputChange(FormInformacaoAssociado.LIMITEDESCONTO, event)}
            />
            <TextField
                label="Convênio"
                select
                fullWidth
                value={state?.enumconvenio}
                onChange={(event) => handleInputChange(FormInformacaoAssociado.ENUMCONVENIO, event)}
            >
                {Object.values(EnumConvenio).map((value) => (
                <MenuItem key={value} value={EnumConvenioNumericMap[value]}>
                    {value}
                </MenuItem>
                ))}
            </TextField>
            <Autocomplete
                options={backOffices}
                fullWidth
                noOptionsText="Nenhum backoffice encontrado"
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                <TextField {...params} label="Backoffice" />
                )}
                defaultValue={undefined}
                onChange={(event, newValue) => handleInputChange(FormInformacaoAssociado.BACKOFFICE, {target:{ value:newValue?.id }})}
                isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            </C.FWStack>
        </RegisterCard>
    )
}