import { MenuItem, TextField } from "@mui/material";
import { useEffect, useReducer } from "react";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import { EnumEstadoCivil } from "../../../../../../../../enums/EnumEstadoCivil.enum";
import * as D from "../../../../../../../../styles/appComponents";
import { IFullGDProposta } from "../../../../../../../../models/GDProposta";
import { useObjectState } from "../../../../../../../../hooks/useObjectState";
import { IFullGDContratoCliente } from "../../../../../../../../models/GDContrato";

export interface IInformacoesResponsavelLegal {
  id?: string;
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  estado_civil: string;
  nacionalidade: string;
  profissao: string;
}

type PropsInformacoesResponsavelLegal = {
  type: string;
  data?: IFullGDProposta;
  dataContrato?: IFullGDContratoCliente;
  onChange: (data: IInformacoesResponsavelLegal) => void;
};

const InformacoesResponsavelLegal = (
  props: PropsInformacoesResponsavelLegal
) => {
  const { type, data, dataContrato } = props;

  const { state, setObject, updateObject, resetObject } =
    useObjectState<IInformacoesResponsavelLegal>({
      nome: "",
      email: "",
      celular: "",
      cpf: "",
      estado_civil: "",
      nacionalidade: "",
      profissao: "",
    });

  useEffect(() => {
    props.onChange(state);
  }, [state]);

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        id:
          dataContrato.responsaveisLegais &&
          dataContrato.responsaveisLegais.length > 0
            ? dataContrato.responsaveisLegais[0].id ?? ""
            : "",
        nome:
          dataContrato.responsaveisLegais &&
          dataContrato.responsaveisLegais.length > 0
            ? dataContrato.responsaveisLegais[0].nome ?? ""
            : "",
        email:
          dataContrato.responsaveisLegais &&
          dataContrato.responsaveisLegais.length > 0
            ? dataContrato.responsaveisLegais[0].email ?? ""
            : "",
        celular:
          dataContrato.responsaveisLegais &&
          dataContrato.responsaveisLegais.length > 0
            ? dataContrato.responsaveisLegais[0].celular ?? ""
            : "",
        cpf:
          dataContrato.responsaveisLegais &&
          dataContrato.responsaveisLegais.length > 0
            ? dataContrato.responsaveisLegais[0].cpf ?? ""
            : "",
        estado_civil:
          dataContrato.responsaveisLegais &&
          dataContrato.responsaveisLegais.length > 0
            ? dataContrato.responsaveisLegais[0].enumEstadoCivil ?? ""
            : "",
        nacionalidade:
          dataContrato.responsaveisLegais &&
          dataContrato.responsaveisLegais.length > 0
            ? dataContrato.responsaveisLegais[0].nacionalidade ?? ""
            : "",
        profissao: dataContrato.responsaveisLegais
          ? dataContrato.responsaveisLegais[0].profissao ?? ""
          : "",
      });
    }
  }, [dataContrato]);

  return (
    <RegisterCard title="Informações do responsável legal">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Nome do responsável legal"
          required
          fullWidth
          value={state.nome}
          onChange={(e) => updateObject({ nome: e.target.value })}
        />
        <TextField
          label="Email"
          required
          fullWidth
          value={state.email}
          onChange={(e) => updateObject({ email: e.target.value })}
        />
        <TextField
          label="Celular"
          required
          fullWidth
          value={state.celular}
          onChange={(e) => updateObject({ celular: e.target.value })}
        />
        <TextField
          label="CPF"
          required
          fullWidth
          value={state.cpf}
          onChange={(e) => updateObject({ cpf: e.target.value })}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Estado Civil"
          required
          fullWidth
          select
          value={state.estado_civil}
          onChange={(e) => updateObject({ estado_civil: e.target.value })}
        >
          {Object.values(EnumEstadoCivil).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Nacionalidade"
          required
          fullWidth
          value={state.nacionalidade}
          onChange={(e) => updateObject({ nacionalidade: e.target.value })}
        />
        <TextField
          label="Profissão"
          required
          fullWidth
          value={state.profissao}
          onChange={(e) => updateObject({ profissao: e.target.value })}
        />
      </D.FWStack>
      {/* {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null} */}
    </RegisterCard>
  );
};

export default InformacoesResponsavelLegal;
