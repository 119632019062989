import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const NoDataDiv = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  gap: 40px;

  @media (max-width: 1024px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 0;
  }

  @media (max-width: 510px) {
    flex-direction: column;
    gap: 10px;
  }
`;
