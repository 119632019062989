import styled from "styled-components";
import { ColorType } from "../../types/ColorType";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

export const Circle = styled.div<{
  color: ColorType;
  selected: boolean;
}>`
  width: 16px;
  height: 16px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${({ color }) => {
    switch (color) {
      case "blue":
        return "blue";
      case "yellow":
        return "yellow";
      case "orange":
        return "#DF7A43";
      case "green":
        return "green";
      case "red":
        return "red";
      case "purple":
        return "purple";
      default:
        return "transparent";
    }
  }};

  background-color: ${({ color, selected }) => {
    if (!selected) {
      return "white";
    }
    switch (color) {
      case "blue":
        return "blue";
      case "yellow":
        return "yellow";
      case "orange":
        return "#DF7A43";
      case "green":
        return "green";
      case "red":
        return "red";
      case "purple":
        return "purple";
      default:
        return "transparent";
    }
  }};
`;
