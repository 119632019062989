import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { RefObject, useEffect, useState } from "react";
import * as G from "../../style";
import { TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { iDadosAcesso } from "../../../../../../../models/Colaborador/cadastros/DadosAcesso";
import { ErrorMessage } from "../../../../../../../utils/AbstractValidation";
import { handleStyleFieldError } from "../../../../../../../utils/verifyFieldError";
import { UsinaTipoPessoaFisica, UsinaTipoPessoaJuridica } from "../../../../../../../models/Gerador/Usina";
import { firstNameTransform, getFirstWord } from "../../../../../../../utils/customWords";
import { GeradorResponse } from "../../../../../../../models/Gerador/GeradorResponse";
import { EnumTipoPessoa } from "../../../../../../../enums/Gerador/EnumTipoPessoa.enum";
import { IDadosUsinaPessoaFisica, IDadosUsinaPessoaJuridica } from "../../../../../../../models/Gerador/Cadastro/DadosUsina";
import { IResponsavelUsina } from "../../../../../../../models/Gerador/Cadastro/ResponsavelUsina";
import { IResponsavelComunicacao } from "../../../../../../../models/ResponsavelComunicacao";
import { ICreateResponsavelLegal, IResponsavelLegal } from "../../../../../../../models/ResponsavelLegal";

export enum DadosAcessoEnum {
  NOMEDADOSACESSO = "NOMEDADOSACESSO",
  LOGIN = "LOGIN",
  EMAIL = "EMAIL",
  SENHA = "SENHA",
  TELEFONE = "TELEFONE",
  WHATSAPP = "WHATSAPP"
}
export interface PropsDadosAcesso {
  onSave: (data: iDadosAcesso) => void;
  onChange: (data: iDadosAcesso) => void;
  onUpdate: () => void;
  onReset: (usuarioId:string) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  geradorId?: string | null | undefined;
  usina?: any;
  usinas?: GeradorResponse[];
  scrollRef: RefObject<HTMLDivElement> | null;
  dadosUsina?: any;
  tipoFormulario?: string | null;
  responsavelUsina?: IResponsavelUsina | undefined;
  responsavelLegal?: ICreateResponsavelLegal[] | undefined;
}

const DadosAcesso: React.FC<PropsDadosAcesso> = ({
  onSave,
  onChange,
  onUpdate,
  buttonSave,
  fieldErros,
  geradorId,
  usina,
  usinas,
  scrollRef,
  onReset,
  dadosUsina,
  tipoFormulario,
  responsavelUsina,
  responsavelLegal
}) => {
  const formDadosAcessoModel: iDadosAcesso = {
    nomedadosacesso: null,
    email: null,
    telefone: null,
    login: null,
    whatsapp: tipoFormulario === "pessoaFisica"? 
    responsavelUsina?.celularresponsavelusina:
    tipoFormulario === "pessoaJuridica"?
    (responsavelLegal || [{celular:""}])[0]?.celular:
    null
  };
  const [dadosAcesso, setDadosAcesso] =
    useState<iDadosAcesso>(formDadosAcessoModel);
  const sendOnSave = (): void => onSave(dadosAcesso);
  const sendOnUpdate = (): void => onUpdate();
  const sendOnChange = (update: iDadosAcesso): void => onChange(update);
  const sendOnReset = (usuarioId:string): void => onReset(usuarioId);

  useEffect(() => {
    if(geradorId){
      const formDadosAcessoModel: any = {
        nomedadosacesso: usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica? 
        usina?.nomeResponsavelUsina: (usina?.responsavelLegal ?? [{nome:""}])[0]?.nome,
        email: usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica? 
        usina?.emailResponsavelUsina: (usina?.responsavelLegal ?? [{email:""}])[0]?.email,
        telefone: usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica? 
        usina?.celularResponsavelUsina: (usina?.responsavelLegal || [{celular:""}])[0]?.celular,
        whatsapp: (usina?.whatsappDadosAcesso === "" && usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica)?
        usina?.celularResponsavelUsina: (usina?.whatsappDadosAcesso === "" && usina?.tipoPessoa === EnumTipoPessoa.pessoaJuridica)?
        (usina?.responsavelLegal || [{celular:""}])[0]?.celular: usina?.whatsappDadosAcesso,
        login: usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica? 
        usina?.cpfResponsavelUsina: (usina?.responsavelLegal || [{cpf:""}])[0]?.cpf
      };
      setDadosAcesso(formDadosAcessoModel);
    }

  }, [geradorId, usina]);

  useEffect(() => {
    if(!geradorId){
      handleInputChange(
        { 
          target:{ 
            name:DadosAcessoEnum.NOMEDADOSACESSO, 
            value: tipoFormulario === "pessoaFisica"? 
            responsavelUsina?.nomeresponsavelusina:
            tipoFormulario === "pessoaJuridica"? 
            (responsavelLegal || [{nome:""}])[0]?.nome:
            null
          } 
        } as React.ChangeEvent<HTMLInputElement>);
  
      handleInputChange(
        { 
          target:{ 
            name:DadosAcessoEnum.TELEFONE, 
            value: tipoFormulario === "pessoaFisica"? 
            responsavelUsina?.celularresponsavelusina:
            tipoFormulario === "pessoaJuridica"?
            (responsavelLegal || [{celular:""}])[0]?.celular:
            null
          } 
        } as React.ChangeEvent<HTMLInputElement>);
  
      handleInputChange(
          { 
            target:{ 
              name:DadosAcessoEnum.WHATSAPP, 
              value: tipoFormulario === "pessoaFisica"? 
              responsavelUsina?.celularresponsavelusina:
              tipoFormulario === "pessoaJuridica"?
              (responsavelLegal || [{celular:""}])[0]?.celular:
              null
            } 
          } as React.ChangeEvent<HTMLInputElement>);
  
      handleInputChange(
        { 
          target:{ 
            name:DadosAcessoEnum.EMAIL, 
            value: tipoFormulario === "pessoaFisica"? 
            responsavelUsina?.emailresponsavelusina:
            tipoFormulario === "pessoaJuridica"?
            (responsavelLegal || [{email:""}])[0]?.email:
            null
          } 
        } as React.ChangeEvent<HTMLInputElement>);
  
        handleInputChange(
          { 
            target:{ 
              name:DadosAcessoEnum.LOGIN, 
              value: tipoFormulario === "pessoaFisica"? 
              responsavelUsina?.cpfresponsavelusina:
              tipoFormulario === "pessoaJuridica"?
              (responsavelLegal || [{cpf:""}])[0]?.cpf:
              null
            } 
          } as React.ChangeEvent<HTMLInputElement>);
    }

  }, [responsavelUsina, responsavelLegal])

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.target.name.toLocaleLowerCase();
    const value = event.target.value;

    setDadosAcesso((state: iDadosAcesso) => {
      sendOnChange({ ...state, [name]: value });
      return { ...state, [name]: value };
    });
  };

  return (
      <RegisterCard title="Dados de acesso">
        <G.FWStack direction={"row"} spacing={2}>
          <TextField
            inputProps={{ readOnly: true }}
            sx={handleStyleFieldError(DadosAcessoEnum.NOMEDADOSACESSO, fieldErros, { width:"33%" })}
            name={DadosAcessoEnum.NOMEDADOSACESSO}
            onChange={handleInputChange}
            value={dadosAcesso?.nomedadosacesso ?? ""}
            label="Nome"
            fullWidth
          />
          <TextField
            inputProps={{ readOnly: true }}
            sx={handleStyleFieldError(DadosAcessoEnum.TELEFONE, fieldErros, { width:"33%" })}
            name={DadosAcessoEnum.TELEFONE}
            onChange={handleInputChange}
            value={dadosAcesso?.telefone || ""}
            label="Celular"
            fullWidth
          />
          <TextField
            inputProps={{ readOnly: true }}
            sx={handleStyleFieldError(DadosAcessoEnum.LOGIN, fieldErros, { width:"33%" })}
            name={DadosAcessoEnum.LOGIN}
            value={dadosAcesso?.login ?? ""}
            label="Login"
            fullWidth
          />
        </G.FWStack>
        <G.FWStack direction={"row"} spacing={2}>
          <TextField
            inputProps={{ readOnly: buttonSave }}
            name={DadosAcessoEnum.WHATSAPP}
            value={dadosAcesso?.whatsapp ?? ""}
            onChange={handleInputChange}
            type="number"
            label="whatsapp"
            sx={{ width:"33%" }}
          />
        </G.FWStack>
        <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            disabled={buttonSave}
            $color="lightpink"
            onClick={() => sendOnReset(usina?.idUsuario)}
          >
            Reset Senha
          </D.ContainedButton>
          <D.ContainedButton
            disabled={!buttonSave}
            onClick={sendOnUpdate}
            $color="yellow"
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
            Salvar
          </D.ContainedButton>
        </G.FWStack>
      </RegisterCard>
  );
};

export default DadosAcesso;
