import { IFormDadosPessoais } from "../../../../../../../models/Colaborador/cadastros/DadosPessoais";
import { AbstractValidation, Validate } from "../../../../../../../utils/AbstractValidation";

export class DadosPessoaisValidate extends AbstractValidation<IFormDadosPessoais>{
    getValidate(): Validate<IFormDadosPessoais>[] {
        return[
            {
                field: "nome",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Nome deve ser informado", "nome")
                }
            },
            {
                field: "nome",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(value.length > 150) addError("O Nome não pode exceder 150 caracteres","nome")
                }
            },
            {
                field: "datanascimento",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Data de nascimento deve ser informada","datanascimento")
                }
            },
            {
                field: "municipionascimento",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Municipio de nascimento deve ser informado","municipionascimento")
                }
            },
            {
                field: "nacionalidade",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Nacionalidade deve ser informada","nacionalidade")
                }
            },
            {
                field: "dataadmissao",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Data de admissão deve ser informada","dataadmissao")
                }
            },
            {
                field: "cep",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O CEP deve ser informado","cep")
                }
            },
            {
                field: "uf",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Estado deve ser informado","uf")
                }
            },
            {
                field: "cidade",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Cidade deve ser informado","cidade")
                }
            },
            {
                field: "bairro",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Bairro deve ser informado","bairro")
                }
            },
            {
                field: "logradouro",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Logradouro deve ser informado","logradouro")
                }
            },
            {
                field: "numero",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Número deve ser informado","numero")
                }
            },
            {
                field: "banco",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Banco deve ser informado","banco")
                }
            },
            {
                field: "agencia",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Agência deve ser informada","agencia")
                }
            },
            {
                field: "conta",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Conta deve ser informada","conta")
                }
            },
            {
                field: "cpffavorecido",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O CPF do favorecido deve ser informado","cpffavorecido")
                }
            },
            {
                field: "favorecido",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Favoreciso deve ser informado","favorecido")
                }
            },
            {
                field: "chavepix",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("A Chave deve ser informada","chavepix")
                }
            }
        ]
    }
}