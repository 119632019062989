import { IOperadoraListItem } from "../../models/Operadora";
import http from "../common/http-common";

export const GetOperadoras = () => {
  return http.get<IOperadoraListItem[]>(`/Operadora/BuscaTodasOperadoras`);
};

export const GetOperadoraById = () => {
  return http.get<IOperadoraListItem[]>(`/Operadora/BuscaTodasOperadoras`);
};
