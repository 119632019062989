import { MenuItem, TextField } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import RegisterCard from "../../../../../../../../components/RegisterCard";
import {
  EnumTipoConexao,
  EnumTipoConexaoValueMap,
} from "../../../../../../../../enums/EnumTipoConexao.enum";
import { useObjectState } from "../../../../../../../../hooks/useObjectState";
import { IFormInformacoesInformacoesContratoPF } from "../../../../../../../../models/Contratar";
import { IFullGDProposta } from "../../../../../../../../models/GDProposta";
import * as D from "../../../../../../../../styles/appComponents";
import { IFullGDContratoCliente } from "../../../../../../../../models/GDContrato";
import moment from "moment";

type PropsInformacoesContratoContratar = {
  type: string;
  data?: IFullGDProposta;
  dataContrato?: IFullGDContratoCliente;
  onChange: (data: IFormInformacoesInformacoesContratoPF) => void;
};

const InformacoesContratoPFContratar = (
  props: PropsInformacoesContratoContratar
) => {
  const { type, data, dataContrato } = props;

  const { state, setObject, updateObject, resetObject } =
    useObjectState<IFormInformacoesInformacoesContratoPF>({
      numeroInstalacao: "",
      numeroCliente: "",
      dataVencimento: "",
      conexao: "",
      tarifaDistribuidora: "",
      descontoTarifaDistribuidora: "",
      taxaDisponibilidade: "",
      media12meses: "",
      franquia: "",
      valorAssinatura: "",
      tarifaUsina: "",
      potencia: "",
      valorMaximoInjecao: "",
      lote: "",
      economiaMensal: "",
      economiaAnual: "",
    });

  useEffect(() => {
    props.onChange(state);
  }, [state]);

  useEffect(() => {
    setObject({
      ...state,
      numeroInstalacao: data?.numeroInstalacao ?? "",
      conexao: data?.conexao ?? "",
      economiaMensal: data?.economiaMensalLiquida ?? "",
      economiaAnual: data?.economiaAnual ?? "",
    });
  }, [data]);

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        conexao: dataContrato.conexao?.toString() ?? "",
        dataVencimento:
          moment(new Date(dataContrato?.dataVencimento!)).format(
            "YYYY-MM-DD"
          ) ?? "",
        descontoTarifaDistribuidora:
          dataContrato.descontoTarifaDistribuidora?.toString() ?? "",
        economiaAnual: dataContrato.economiaAnual?.toString() ?? "",
        economiaMensal: dataContrato.economiaMensal?.toString() ?? "",
        franquia: dataContrato.franquia?.toString() ?? "",
        lote: dataContrato.lote?.toString() ?? "",
        media12meses: dataContrato.mediaDozeMeses?.toString() ?? "",
        numeroCliente: dataContrato.numeroCliente ?? "",
        numeroInstalacao: dataContrato.instalacao ?? "",
        potencia: dataContrato.potencia?.toString() ?? "",
        tarifaDistribuidora: dataContrato.tarifaDistribuidora?.toString() ?? "",
        tarifaUsina: dataContrato.tarifaUsina?.toString() ?? "",
        taxaDisponibilidade: dataContrato.taxaDisponibilidade?.toString() ?? "",
        valorAssinatura: dataContrato.valorAssinatura?.toString() ?? "",
        valorMaximoInjecao: dataContrato.valorMaximoInjecao?.toString() ?? "",
      });
    }
  }, [dataContrato]);

  useEffect((): void => {
    if (data) {
      const kwp: number = 11.46;
      const percent: number = 1.12377;
      const valorAluguel: number = 935.96;

      var despesaUsina = Number(data?.valorCooperativa);

      var fracaoUsina = (percent * despesaUsina) / valorAluguel;
      var potencia = (kwp * fracaoUsina) / percent;

      setObject({
        ...state,
        conexao: data.conexao?.toString() ?? "",
        dataVencimento:
          moment(new Date()).add(5, "years").format("YYYY-MM-DD") ?? "",
        descontoTarifaDistribuidora: data.valorDesconto ?? "",
        economiaAnual: data.economiaAnual?.toString() ?? "",
        economiaMensal: data.economiaMensalLiquida?.toString() ?? "",
        numeroInstalacao: data.numeroInstalacao ?? "",
        tarifaDistribuidora: data.tarifaDistribuidora?.toString() ?? "",
        taxaDisponibilidade: data.taxaDisponibilidadekWh?.toString() ?? "",
        valorAssinatura: data.valorTotal ?? "",
        franquia: data.energiaInjetadakWh ?? "",
        potencia: potencia.toFixed(2) ?? "",
        lote: fracaoUsina.toFixed(5) ?? "",
        tarifaUsina: data.tarifaCooperativa ?? "",
        media12meses: data.consumoMensalkWh ?? "",
      });
    }
  }, [data]);

  const handleChangeConexao = (e: ChangeEvent<HTMLInputElement>) => {
    updateObject({ conexao: e.target.value });
    updateObject({
      taxaDisponibilidade:
        EnumTipoConexaoValueMap[
          Number(e.target.value) as keyof typeof EnumTipoConexaoValueMap
        ],
    });
  };

  return (
    <RegisterCard title="Informações do contrato">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Número de instalação"
          required
          fullWidth
          value={state.numeroInstalacao}
          onChange={(e) => updateObject({ numeroInstalacao: e.target.value })}
        />
        <TextField
          label="Número cliente"
          required
          fullWidth
          value={state.numeroCliente}
          onChange={(e) => updateObject({ numeroCliente: e.target.value })}
        />
        <D.DateTextfield
          type="date"
          label="Data de vencimento"
          fullWidth
          value={state.dataVencimento}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Conexão"
          select
          required
          fullWidth
          value={state.conexao}
          onChange={handleChangeConexao}
        >
          {Object.values(EnumTipoConexao).map((value, index) => (
            <MenuItem key={value} value={index}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Tarifa distribuidora"
          required
          fullWidth
          value={state.tarifaDistribuidora}
          onChange={(e) =>
            updateObject({ tarifaDistribuidora: e.target.value })
          }
        />
        <TextField
          label="Desconto tarifa distribuidora"
          required
          fullWidth
          value={state.descontoTarifaDistribuidora}
          inputProps={{ readOnly: true }}
        />
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Taxa de disponibilidade"
          required
          fullWidth
          value={state.taxaDisponibilidade}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Média 12 meses"
          required
          fullWidth
          value={state.media12meses}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Franquia"
          required
          fullWidth
          value={state.franquia}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Valor assinatura"
          required
          fullWidth
          value={state.valorAssinatura}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Tarifa usina"
          required
          fullWidth
          value={state.tarifaUsina}
          onChange={(e) => updateObject({ tarifaUsina: e.target.value })}
        />
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Potencia"
          required
          fullWidth
          value={state.potencia}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Lote (fração da usina)"
          required
          fullWidth
          value={state.lote}
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Economia mensal"
          required
          fullWidth
          value={state.economiaMensal}
          inputProps={{ readOnly: true }}
          InputProps={{
            startAdornment: state.economiaMensal ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <TextField
          label="Economia anual"
          required
          fullWidth
          value={state.economiaAnual}
          inputProps={{ readOnly: true }}
          InputProps={{
            startAdornment: state.economiaAnual ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
      </D.FWStack>
      {/* {props.data !== null && props.data !== undefined ? (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </D.FWStack>
      ) : null} */}
    </RegisterCard>
  );
};

export default InformacoesContratoPFContratar;
