import { ICreateUsuario, ILoginParams, IUsuario } from "../../models/Usuario";
import http from "../common/http-common";

export const LoginColaborador = (loginParams: ILoginParams) => {
  return http.post<IUsuario>("/Usuario/Colaborador", loginParams);
};

export const LoginCaptador = (loginParams: ILoginParams) => {
  return http.post<IUsuario>("/Usuario/Captador", loginParams);
};

export const LoginGerador = (loginParams: ILoginParams) => {
  return http.post<IUsuario>("/Usuario/Gerador", loginParams);
};

export const LoginCliente = (loginParams: ILoginParams) => {
  return http.post<IUsuario>("/Usuario/Cliente", loginParams);
};

export const ResetSenha = (usuarioId: string) => 
  http.post<IUsuario>(`/Usuario/ResetSenha/${usuarioId}`, );

export const CreateCliente = (data: ICreateUsuario) => {
  return http.post("/Usuario/CreateUsuarioCliente", data);
};
