export const styleError = {
    "& .MuiOutlinedInput-root": {
      color: "#000",
      fontFamily: "Arial",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FF0000",
        borderWidth: "2px",
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "#FF0000"
    },
  };