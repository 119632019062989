export class CopyObjects{
    static set(dataUpdated:any, datToUpdated:any){

        Object.entries(dataUpdated).forEach(([key, value]) => {
            if (key in datToUpdated && value !== undefined) {
                    (datToUpdated  as Record<string, any>)[key] = value;
            }
        });

    }
}
