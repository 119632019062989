import { Button, Chip, TextField } from "@mui/material"
import RegisterCard from "../../../../../../components/RegisterCard"
import * as C from "../style";
import * as D from "../../../../../../styles/appComponents";
import { EnumTipoChavePix } from "../../../../../../enums/EnumTipoChavePix.enum";
import { FC, useReducer } from "react";
import { SectionAction } from "../../../Components/SectionAction";

enum FormComissaoPagamento{
    PERCENTUALCOMISSAO="PERCENTUALCOMISSAO",
    COMISSAOPOREXTENSO="COMISSAOPOREXTENSO",
    VIGENCIACONTRATO="VIGENCIACONTRATO",
    BANCO="BANCO",
    AGENCIA="AGENCIA",
    CONTA="CONTA",
    CNPJFAVORECIDO="CNPJFAVORECIDO",
    FAVORECIDO="FAVORECIDO",
    CHAVEPIX="CHAVEPIX",
    TIPOCHAVEPIX="TIPOCHAVEPIX"
}

export interface IFormComissaoPagamento{
    percentualcomissao:number | undefined;
    comissaoporextenso:string;
    vigenciacontrato:string;
    banco:string;
    agencia:string;
    conta:string;
    cnpjfavorecido:string;
    favorecido:string;
    chavepix:string;
    complemento:string;
    logradouro:string;
    tipochavepix:number;
}

type PropsComissaoPagamento = {
    onSave: (data:IFormComissaoPagamento) => void;
    onUpdate: (data:IFormComissaoPagamento) => void;
    onChange: (data:IFormComissaoPagamento) => void;
    data?: object | null;
}

export const ComissaoPagamento:FC<PropsComissaoPagamento> = ({onSave,onUpdate,onChange}) => {
    const sendOnSave = (): void => onSave(state);
    const sendOnUpdate = (): void => onUpdate(state);
    const sendOnChange = (update: IFormComissaoPagamento): void => onChange(update);

    const setDataForm = (state: IFormComissaoPagamento, action: {
        type: FormComissaoPagamento;
        payload: string;
      }): IFormComissaoPagamento => {
        const change = { ...state, [action.type.toLowerCase()]: action.payload };
        sendOnChange(change);
        return change;
    };

    const [state, dispatch] = useReducer(setDataForm, {
        percentualcomissao: undefined,
        comissaoporextenso: "",
        vigenciacontrato: "",
        banco: "",
        agencia: "",
        conta: "",
        cnpjfavorecido: "",
        favorecido: "",
        chavepix: "",
        complemento: "",
        logradouro: "",
        tipochavepix: 0
    });

    const handleInputChange = (field: FormComissaoPagamento, event:React.ChangeEvent<HTMLInputElement> | any) => {
        dispatch({ type: field, payload: event.target.value });
    }

    return(
        <RegisterCard title="Comissão e dados para pagamentos">
            <C.FWStack direction={"row"} spacing={2}>
            <TextField
                label="Porcentual de comissão"
                fullWidth
                value={state.percentualcomissao}
                type="number"
                onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.PERCENTUALCOMISSAO, event)}
            />
            <TextField
                label="Comissão por extenso"
                fullWidth
                value={state.comissaoporextenso}
                onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.COMISSAOPOREXTENSO, event)}
            />
            <D.DateTextfield
                label="Vigência do contrato"
                type="date"
                value={state.vigenciacontrato}
                fullWidth
                onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.VIGENCIACONTRATO, event)}
            />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
            <C.Subtitle>Dados para pagamento de comissão*</C.Subtitle>
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={16}>
            <C.MWDiv>
                <C.Subtitle>Dados bancários*</C.Subtitle>
                <C.Text>Insira seus dados bancários</C.Text>
                <C.FWStack direction={"row"} spacing={2}>
                <TextField
                    label="Banco"
                    fullWidth
                    value={state.banco}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.BANCO, event)}
                />
                <TextField
                    label="Agência"
                    fullWidth
                    value={state.agencia}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.AGENCIA, event)}
                />
                </C.FWStack>
                <C.FWStack direction={"row"} spacing={2}>
                <TextField
                    label="Conta"
                    fullWidth
                    value={state.conta}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.CONTA, event)}
                />
                <TextField
                    label="CNPJ do favorecido"
                    fullWidth
                    value={state.cnpjfavorecido}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.CNPJFAVORECIDO, event)}
                />
                </C.FWStack>
                <C.FWStack direction={"row"} spacing={2}>
                <C.FWDiv>
                    <TextField
                    label="Favorecido"
                    value={state.favorecido}
                    fullWidth
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.FAVORECIDO, event)}
                    />
                    <C.FWDiv></C.FWDiv>
                </C.FWDiv>
                </C.FWStack>
            </C.MWDiv>
            <C.MWDiv>
                <C.Subtitle>Pix*</C.Subtitle>
                <C.Text>Insira sua chave pix</C.Text>
                <C.FWStack direction={"row"} spacing={2}>
                <TextField
                    label="Chave PIX"
                    fullWidth
                    value={state.chavepix}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleInputChange(FormComissaoPagamento.CHAVEPIX, event)}
                />
                </C.FWStack>
                <C.Text>Qual dado você quer usar?</C.Text>
                <C.FWStack direction="row" spacing={2}>
                {Object.values(EnumTipoChavePix).map((label, index) => (
                    <Chip
                    key={label}
                    label={label}
                    onClick={() => handleInputChange(FormComissaoPagamento.TIPOCHAVEPIX, { target:{ value:index } })}
                    color={state.tipochavepix === index ? "primary" : "default"}
                    />
                ))}
                </C.FWStack>
            </C.MWDiv>
            </C.FWStack>
        </RegisterCard>
    )
}