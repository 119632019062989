import {
  FC,
  ReactNode,
  RefObject,
  useEffect,
  useReducer,
  useState,
} from "react";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
} from "@mui/material";
import { IDadosUsinaPessoaFisica, IDadosUsinaPessoaJuridica } from "../../../../../models/Gerador/Cadastro/DadosUsina";
import { EnumEstado } from "../../../../../enums/EnumEstado.enum";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { handleStyleFieldError } from "../../../../../utils/verifyFieldError";
import { styleError } from "../../../../../styles/stylesCustom";
import { UsinaTipoPessoaFisica, UsinaTipoPessoaJuridica } from "../../../../../models/Gerador/Usina";
import ScroolRef from "../../../../../components/ScrollRef";
import { siglaTransform } from "../../../../../utils/customWords";
import { GeradorResponse } from "../../../../../models/Gerador/GeradorResponse";
import SkeletonDefaultPage from "../../../../../components/SkeletonLoads/DefaultPage";
import { SkeletonCard } from "../../../../../components/SkeletonLoads/CreationCards/style";
import { SkeletonCreationCards } from "../../../../../components/SkeletonLoads/CreationCards";
import { SkeletonSectionForm } from "../../../../../components/SkeletonLoads/SectionForm";
import { MaskedTextInput } from "../../../../../components/MaskTextInput";

export enum DadosUsinaEnum {
  RAZAOSOCIALNOME = "RAZAOSOCIALNOME",
  NOMEUSINA = "NOMEUSINA",
  CPFCNPJ = "CPFCNPJ",
  CPF = "CPF",
  INSTALACAO = "INSTALACAO",
  SIGLA = "SIGLA",
  CEP = "CEP",
  UF = "UF",
  CIDADE = "CIDADE",
  BAIRRO = "BAIRRO",
  LOGRADOURO = "LOGRADOURO",
  NUMERO = "NUMERO",
  COMPLEMENTO = "COMPLEMENTO",
}

export interface PropsDadosUsina {
  onSave: (data: IDadosUsinaPessoaJuridica) => void;
  onChange: (data: IDadosUsinaPessoaJuridica) => void;
  onUpdate: (data: IDadosUsinaPessoaJuridica) => void;
  fieldErros: ErrorMessage[] | null;
  buttonSave: boolean;
  geradorId: string | null | undefined;
  cities: string[];
  usina: any;
  scrollRef: RefObject<HTMLDivElement> | null;
  tipoFormulario: string | null;
  usinas: GeradorResponse[];
  load: { status:boolean, section:string }
}

export const DadosUsina: FC<PropsDadosUsina> = ({
  onSave,
  onChange,
  onUpdate,
  fieldErros,
  buttonSave,
  geradorId,
  cities,
  usina,
  scrollRef,
  tipoFormulario,
  usinas,
  load
}) => {
  const formDadosUsinaModel: IDadosUsinaPessoaJuridica | IDadosUsinaPessoaFisica = tipoFormulario === "pessoaJuridica"? {
    razaosocialnome: null,
    cpfcnpj: null,
    instalacao: null,
    sigla: null,
    cep: null,
    uf: null,
    cidade: null,
    bairro: null,
    logradouro: null,
    numero: null,
    complemento: null,
  }:{
    nomeusina: null,
    cpf: null,
    instalacao: null,
    sigla: null,
    cep: null,
    uf: null,
    cidade: null,
    bairro: null,
    logradouro: null,
    numero: null,
    complemento: null,
  };
  const [dadosUsina, setDadosUsina] =
    useState<any>(formDadosUsinaModel);

  const sendOnChange = (update: IDadosUsinaPessoaJuridica): void => onChange(update);
  const sendOnSave = () => onSave(dadosUsina);
  const sendOnUpdate = () => onUpdate(dadosUsina);

  useEffect(() => {
    if (geradorId) {
      const formDadosUsinaModel: IDadosUsinaPessoaJuridica | IDadosUsinaPessoaFisica = tipoFormulario === "pessoaJuridica"? {
        razaosocialnome: geradorId ? usina?.razaoSocialNome : null,
        cpfcnpj: geradorId ? usina?.cnpjcpf : null,
        instalacao: usina?.instalacao,
        sigla: geradorId ? usina?.sigla : null,
        cep: geradorId ? usina?.cep : null,
        uf: geradorId ? usina?.uf : null,
        cidade: geradorId ? usina?.cidade : null,
        bairro: geradorId ? usina?.bairro : null,
        logradouro: geradorId ? usina?.logradouro : null,
        numero: geradorId ? usina?.numero : null,
        complemento: geradorId ? usina?.complemento : null,
      }:{
        nomeusina: geradorId ? usina?.nomeUsina : null,
        cpf: geradorId ? usina?.cpf : null,
        instalacao: usina?.instalacao,
        sigla: geradorId ? usina?.sigla : null,
        cep: geradorId ? usina?.cep : null,
        uf: geradorId ? usina?.uf : null,
        cidade: geradorId ? usina?.cidade : null,
        bairro: geradorId ? usina?.bairro : null,
        logradouro: geradorId ? usina?.logradouro : null,
        numero: geradorId ? usina?.numero : null,
        complemento: geradorId ? usina?.complemento : null,
      };
      setDadosUsina(formDadosUsinaModel);
    }
  }, [geradorId, usina, tipoFormulario]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.target.name.toLocaleLowerCase();
    const value = event.target.value;

    if(name === DadosUsinaEnum.NOMEUSINA.toLocaleLowerCase()){
      setDadosUsina((state: IDadosUsinaPessoaFisica) => {
        sendOnChange({ ...state, [DadosUsinaEnum.SIGLA.toLocaleLowerCase()]: siglaTransform(value,false,usinas) });
        return { ...state, [DadosUsinaEnum.SIGLA.toLocaleLowerCase()]: siglaTransform(value,false,usinas) };
      });
    }

    if(name === DadosUsinaEnum.RAZAOSOCIALNOME.toLocaleLowerCase()){
      setDadosUsina((state: IDadosUsinaPessoaJuridica) => {
        sendOnChange({ ...state, [DadosUsinaEnum.SIGLA.toLocaleLowerCase()]:siglaTransform(value,false,usinas) });
        return { ...state, [DadosUsinaEnum.SIGLA.toLocaleLowerCase()]:siglaTransform(value,false,usinas) };
      });
    }

    setDadosUsina((state: IDadosUsinaPessoaJuridica) => {
      sendOnChange({ ...state, [name]: value });
      return { ...state, [name]: value };
    });
  };

  return (
      <RegisterCard title="Dados da usina">
        {
          load.status && load.section === "dadosUsina"?
          <SkeletonSectionForm />
          :
          <>
          <C.FWStack direction={"row"} spacing={2}>
          {
            tipoFormulario === "pessoaJuridica"?
            <TextField
              name={DadosUsinaEnum.RAZAOSOCIALNOME}
              value={dadosUsina?.razaosocialnome || ""}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(
                DadosUsinaEnum.RAZAOSOCIALNOME,
                fieldErros
              )}
              onChange={handleInputChange}
              label="Razão social/Nome"
              required
              fullWidth
            />
          :
          <TextField
            name={DadosUsinaEnum.NOMEUSINA}
            value={dadosUsina?.nomeusina ?? ""}
            inputProps={{ readOnly: buttonSave }}
            sx={handleStyleFieldError(
              DadosUsinaEnum.NOMEUSINA,
              fieldErros
            )}
            onChange={handleInputChange}
            label="Nome da Usina"
            required
            fullWidth
          />
          }

          {
            tipoFormulario === "pessoaJuridica"?
            <TextField
              name={DadosUsinaEnum.CPFCNPJ}
              value={dadosUsina?.cpfcnpj ?? ""}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.CPFCNPJ, fieldErros)}
              onChange={handleInputChange}
              label={"CPF/CNPJ"}
              required
              fullWidth
            />
          :
          <TextField
            name={DadosUsinaEnum.CPF}
            value={dadosUsina?.cpf ?? ""}
            inputProps={{ readOnly: buttonSave }}
            sx={handleStyleFieldError(DadosUsinaEnum.CPF, fieldErros)}
            onChange={handleInputChange}
            label={"CPF"}
            required
            fullWidth
          />
          }


          <TextField
            value={dadosUsina?.instalacao || ""}
            name={DadosUsinaEnum.INSTALACAO}
            inputProps={{ readOnly: buttonSave }}
            sx={handleStyleFieldError(DadosUsinaEnum.INSTALACAO, fieldErros, {
              width: "50%",
            })}
            onChange={handleInputChange}
            label="Instalação"
            type="number"
            required
          />
          {
            tipoFormulario === "pessoaJuridica"?
            <TextField
            inputProps={{ readOnly: true}}
            value={dadosUsina?.sigla ?? ""}
            name={DadosUsinaEnum.SIGLA}
            sx={handleStyleFieldError(DadosUsinaEnum.SIGLA, fieldErros, {
              width: "30%",
            })}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
            label="Sigla"
            required
          />
          :
          <TextField
            inputProps={{ readOnly: true}}
            value={dadosUsina?.sigla ?? ""}
            name={DadosUsinaEnum.SIGLA}
            sx={handleStyleFieldError(DadosUsinaEnum.SIGLA, fieldErros, {
              width: "30%",
            })}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
            label="Sigla"
            required
          />
          }

          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={dadosUsina?.cep || ""}
              name={DadosUsinaEnum.CEP}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.CEP, fieldErros, {
                width: "70%",
              })}
              onChange={handleInputChange}
              label="CEP"
              type="number"
              required
              fullWidth
            />
            {/* <MaskedTextInput
            mask="99999-9999"
            onChange={handleInputChange}
            /> */}
            <TextField
              value={dadosUsina?.uf || ""}
              name={DadosUsinaEnum.UF}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.UF, fieldErros, {
                width: "40%",
              })}
              onChange={handleInputChange}
              label="UF"
              required
            />
            <TextField
              value={dadosUsina?.cidade ?? ""}
              name={DadosUsinaEnum.CIDADE}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.CIDADE, fieldErros)}
              onChange={handleInputChange}
              label="Cidade"
              required
              fullWidth
            />
            <TextField
              value={dadosUsina?.bairro || ""}
              name={DadosUsinaEnum.BAIRRO}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.BAIRRO, fieldErros)}
              onChange={handleInputChange}
              label="Bairro"
              required
              fullWidth
            />
            <TextField
              value={dadosUsina?.logradouro || ""}
              name={DadosUsinaEnum.LOGRADOURO}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.LOGRADOURO, fieldErros)}
              onChange={handleInputChange}
              label="Logradouro"
              required
              fullWidth
            />
            <TextField
              value={dadosUsina?.numero || ""}
              name={DadosUsinaEnum.NUMERO}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.NUMERO, fieldErros, {
                width: "40%",
              })}
              onChange={handleInputChange}
              label="Número"
              type="number"
              required
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={dadosUsina?.complemento || ""}
              name={DadosUsinaEnum.COMPLEMENTO}
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(DadosUsinaEnum.COMPLEMENTO, fieldErros, {
                width: "50%",
              })}
              onChange={handleInputChange}
              label="Complemento"
              required
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
            <D.ContainedButton
              disabled={!buttonSave}
              onClick={sendOnUpdate}
              $color="yellow"
            >
              Editar
            </D.ContainedButton>
            <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
              Salvar
            </D.ContainedButton>
          </C.FWStack>
          </>
        }
      </RegisterCard>
  );
};
