import { FC, useReducer } from "react";
import RegisterCard from "../../../../../../components/RegisterCard";
import { Stack, TextField } from "@mui/material";
import CircleCheckbox from "../../../../../../components/CircleCheckBox";
import * as C from "../style";
import * as D from "../../../../../../styles/appComponents";
import { ColorType } from "../../../../../../types/ColorType";
import DownloadIcon from "@mui/icons-material/Add";

enum FormStatusContrato {
  STATUSCONTRATO = "STATUSCONTRATO",
  ULTIMAALTERACAOSTATUS = "ULTIMAALTERACAOSTATUS",
  DATAASSINATURACONTRATO = "DATAASSINATURACONTRATO",
  UUIDCONTRATO = "UUIDCONTRATO",
}

export interface IFormStatusContrato {
  statuscontrato: number | undefined;
  ultimaalteracaostatus: string | undefined;
  dataassinaturacontrato: string | undefined;
  uuidcontrato: string | undefined;
}

type PropsStatusContrato = {
  onSave: (data: IFormStatusContrato) => void;
  onUpdate: (data: IFormStatusContrato) => void;
  onChange: (data: IFormStatusContrato) => void;
  data?: object | null;
};

export const StatusContrato: FC<PropsStatusContrato> = ({
  onSave,
  onUpdate,
  onChange,
}) => {
  const sendOnSave = (): void => onSave(state);
  const sendOnUpdate = (): void => onUpdate(state);
  const sendOnChange = (update: IFormStatusContrato): void => onChange(update);

  const setDataForm = (
    state: IFormStatusContrato,
    action: {
      type: FormStatusContrato;
      payload: string;
    }
  ): IFormStatusContrato => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    statuscontrato: undefined,
    ultimaalteracaostatus: "",
    dataassinaturacontrato: "",
    uuidcontrato: "",
  });

  const handleInputChange = (
    field: FormStatusContrato,
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    dispatch({ type: field, payload: event.target.value });
  };

  const statusAtualContrato: {
    label: string;
    color: ColorType;
    id: number;
    value: number;
  }[] = [
    {
      label: "Lead",
      color: "yellow",
      value: 0,
      id: 1,
    },
    {
      label: "Aguardando assinatura de contrato",
      color: "blue",
      value: 1,
      id: 0,
    },
    {
      label: "Contrato assinado",
      color: "green",
      value: 2,
      id: 2,
    },
    {
      label: "Aguardando assinatura de distrato",
      color: "red",
      value: 3,
      id: 3,
    },
    {
      label: "Cancelado",
      color: "purple",
      value: 4,
      id: 4,
    },
  ];

  return (
    <RegisterCard title="Status do contrato">
      <C.StatusArea>
        <label>Status atual:</label>
        <Stack direction={"row"} spacing={2}>
          {statusAtualContrato.map((status) => (
            <CircleCheckbox
              label={status.label}
              color={status.color}
              id={status.id}
              selected={state?.statuscontrato === status.value}
              onSelect={() =>
                handleInputChange(FormStatusContrato.STATUSCONTRATO, {
                  target: { value: status.value },
                })
              }
            />
          ))}
        </Stack>
      </C.StatusArea>
      <C.FWStack direction={"row"} spacing={2}>
        <D.DateTextfield
          label="Última alteração de status"
          type="date"
          fullWidth
          value={state?.ultimaalteracaostatus}
          onChange={(event) =>
            handleInputChange(FormStatusContrato.ULTIMAALTERACAOSTATUS, event)
          }
        />
        <D.DateTextfield
          label="Data de assinatura do contrato"
          type="date"
          fullWidth
          value={state?.dataassinaturacontrato}
          onChange={(event) =>
            handleInputChange(FormStatusContrato.DATAASSINATURACONTRATO, event)
          }
        />
        <TextField
          label="UUID do contrato"
          fullWidth
          value={state?.uuidcontrato}
          onChange={(event) =>
            handleInputChange(FormStatusContrato.UUIDCONTRATO, event)
          }
        />
      </C.FWStack>
      <C.FWStack direction={"row"} justifyContent={"space-between"}>
        <C.FWStack direction={"row"} spacing={2}>
          <D.OutlineButton $color="blue" startIcon={<DownloadIcon />}>
            Baixar contrato
          </D.OutlineButton>
          <D.ContainedButton $color={"lightpink"}>
            Gerar contrato
          </D.ContainedButton>
          <D.ContainedButton $color={"lightpink"}>
            Gerar distrato
          </D.ContainedButton>
        </C.FWStack>
      </C.FWStack>
    </RegisterCard>
  );
};
