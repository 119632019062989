import * as C from "../style";
import { Button, TextField } from "@mui/material";
import { FC, useReducer } from "react";
import { SectionAction } from "../../../Components/SectionAction";

enum FormResponsavelComunicacao{
    NOME="NOME",
    EMAIL="EMAIL",
    CELULAR="CELULAR"
}

export interface IFormResponsavelComunicacao{
    nome:string | undefined;
    email:string | undefined;
    celular:string | undefined;
}

type PropsReponsavelComunicacao = {
    onSave: (data:IFormResponsavelComunicacao) => void;
    onUpdate: (data:IFormResponsavelComunicacao) => void;
    onChange: (data:IFormResponsavelComunicacao) => void;
    data?: object | null;
}

export const ResponsavelComunicacao:FC<PropsReponsavelComunicacao> = ({onSave,onUpdate,onChange}) => {
    const sendOnSave = (): void => onSave(state);
    const sendOnUpdate = (): void => onUpdate(state);
    const sendOnChange = (update: IFormResponsavelComunicacao): void => onChange(update);

    const setDataForm = (state: IFormResponsavelComunicacao, action: {
        type: FormResponsavelComunicacao;
        payload: string;
      }): IFormResponsavelComunicacao => {
        const change = { ...state, [action.type.toLowerCase()]: action.payload };
        sendOnChange(change);
        return change;
      };

    const [state, dispatch] = useReducer(setDataForm, {
        nome: "",
        email: "",
        celular: ""
    });

    const handleInputChange = (field: FormResponsavelComunicacao) => (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: field, payload: event.target.value });
    };

    return(
        <>
        <C.TitleArea>
        <h2>Responsável pela comunicação</h2>
        </C.TitleArea>
        <C.FWStack direction={"row"} spacing={2}>
            <TextField
            label="Nome"
            fullWidth
            onChange={handleInputChange(FormResponsavelComunicacao.NOME)}
            />
            <TextField
            label="Email"
            fullWidth
            onChange={handleInputChange(FormResponsavelComunicacao.EMAIL)}
            />
            <TextField
            label="Celular"
            fullWidth
            onChange={handleInputChange(FormResponsavelComunicacao.CELULAR)}
            />
        </C.FWStack>
      </>
    )
}